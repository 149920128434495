import { toast } from "react-toastify";
import { all, delay, put, select, takeLatest } from "redux-saga/effects";

// Apis
import {
  createCampaignMedia,
  deleteCampaignMedia,
  getCampaignMediaInfo,
  uploadSiteImages,
} from "../../../apis/CampaignMediaAPI";
import { confirmCampaignPlan } from "../../apis/CampaignConfirmationAPI";

// Constants and Utils
import { ActionTypes } from "../../../constants/ActionConstants";
import { getErrorMessage } from "../../../utils/util";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { getDifferenceInDaysOfDateObject } from "../../../common-utils/date-utils/DateUtils";
import { CPM } from "../../../constants/PriceMode";
import { DATE_FORMATS } from "../../../constants/GeneralConstants";

function getSubmissionImpactAction(costType, remove = false) {
  if (!remove) {
    return costType === CPM
      ? ActionTypes.SubmissionImpact.ADD_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT
      : ActionTypes.SubmissionImpact
          .ADD_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT;
  }

  return costType === CPM
    ? ActionTypes.SubmissionImpact.REMOVE_CPM_PRICE_MEDIA_FOR_SUBMISSION_IMPACT
    : ActionTypes.SubmissionImpact
        .REMOVE_FIXED_PRICE_MEDIA_FOR_SUBMISSION_IMPACT;
}

function getSubmissionImpactPayload(
  priceMode,
  mediaId,
  otsSplit,
  cost,
  duration
) {
  if (priceMode === CPM) {
    return { mediaId, otsSplit, duration };
  }
  return { mediaId, otsSplit, cost, duration };
}

export function* selectMedia(action) {
  const { campaignId, campaignMediaBean } = action.payload;
  const { mediaId } = campaignMediaBean;
  try {
    const campaignMedia = yield createCampaignMedia(campaignId, "", [
      campaignMediaBean,
    ]);

    yield put({
      type: ActionTypes.MediaSelection.SELECT_MEDIA_SUCCESS,
      payload: {
        mediaId: campaignMedia.mediaId,
        campaignMedia: campaignMedia,
      },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.MediaSelection.SELECT_MEDIA_FAILURE,
      payload: { error, mediaId },
    });
    toast.error(errorMessage);
  }
}

export function* unSelectMedia(action) {
  const { campaignId, campaignMedia, mediaId } = action.payload;
  try {
    yield deleteCampaignMedia(campaignId, campaignMedia.id);

    yield put({
      type: ActionTypes.MediaSelection.UN_SELECT_MEDIA_SUCCESS,
      payload: { mediaId, roadStretchId: campaignMedia.roadStretchId },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.MediaSelection.UN_SELECT_MEDIA_FAILURE,
      payload: { error, mediaId },
    });
    toast.error(errorMessage);
  }
}

function getDuration(media) {
  const { startTimestamp, endTimestamp } = media;
  const dateObj = {
    startDate: new Date(startTimestamp),
    endDate: new Date(endTimestamp),
  };
  return getDifferenceInDaysOfDateObject(dateObj);
}

export function* getCampaignMedia(action) {
  const { campaignId, pageNo, pageSize } = action.payload;
  try {
    // const response = yield getCampaignMediaInfo(campaignId, pageNo, pageSize);
    const response = yield getCampaignMediaInfo(campaignId, pageNo, pageSize);

    // Wait till campaignPlan is loaded
    let campaignPlan = yield select((state) => state.campaignPlan.campaignPlan);
    while (!campaignPlan) {
      yield delay(1000);
      campaignPlan = yield select((state) => state.campaignPlan.campaignPlan);
    }

    const roadStretchOtsMap = campaignPlan.roadStretchOtsMap;

    for (const media of response.items) {
      const stretchOtsDetails = roadStretchOtsMap[media.roadStretchId] || {};
      const { genericOts, genericOtsLit, targetOts, targetOtsLit } =
        stretchOtsDetails;

      yield put({
        type: getSubmissionImpactAction(media.priceMode),
        payload: getSubmissionImpactPayload(
          media.priceMode,
          media.mediaId,
          {
            genericOts,
            genericOtsLit,
            targetOts,
            targetOtsLit,
          },
          media.sellerPrice,
          getDuration(media)
        ),
      });
    }

    yield put({
      type: ActionTypes.MediaSelection.GET_CAMPAIGN_MEDIA_SUCCESS,
      payload: { campaignMedia: response.items },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.MediaSelection.GET_CAMPAIGN_MEDIA_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* constructCampaignConfirmPayload() {
  const campaign = yield select((state) => state.c_campaignBasicInfo.campaign);
  const campaignPlan = yield select((state) => state.campaignPlan.campaignPlan);

  // DD-MM-YYYY
  const dateFormat = DATE_FORMATS.date_month_year;

  return {
    startDate: campaign.startDate.format(dateFormat),
    endDate: campaign.endDate.format(dateFormat),
    cityId: campaignPlan.cityId,
  };
}

export function* confirmCampaignPlanning(action) {
  const { campaignId, campaignName, history } = action.payload;
  try {
    const requestPayload = yield constructCampaignConfirmPayload();
    yield confirmCampaignPlan(campaignId, requestPayload);
    history.push(constructRedirectPath(`/campaign/${campaignId}/schedule`));
    toast.success(`${campaignName} is successfully Scheduled`, {
      position: toast.POSITION.TOP_CENTER,
    });
    yield put({
      type: ActionTypes.MediaSelection.CONFIRM_CAMPAIGN_PLAN_SUCCESS,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.MediaSelection.CONFIRM_CAMPAIGN_PLAN_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

/**
 * Create Media
 */

export function* createMedia(action) {
  let createdCampaignMediaID = "";

  // Fetch campaignId and mediaId from payload
  const { createMediaBean, imageFiles, campaignId, cityId } = action.payload;

  // Make POST call to create campaign Media
  try {
    createdCampaignMediaID = yield createCampaignMedia(
      campaignId,
      cityId,
      createMediaBean
    );
    yield put({
      type: ActionTypes.MediaSelection.CREATE_CAMPAIGN_MEDIA_SUCCESS,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: ActionTypes.MediaSelection.CREATE_CAMPAIGN_MEDIA_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
    return;
  }

  // Make call to upload images to created campaign Media
  if (imageFiles.length === 0) {
    // close the create media pop-up form here..
    yield put({
      type: ActionTypes.CreateMediaFormModal.CLOSE_MEDIA_CREATE_MODAL,
    });

    // TODO :: refresh the media list once the mocked data is removed in backend
    return;
  }

  try {
    //upload site image
    yield put({
      type: ActionTypes.MediaSelection.MEDIA_IMAGE_UPLOAD,
    });

    yield uploadSiteImages(campaignId, createdCampaignMediaID, imageFiles);

    //upload site image success
    yield put({
      type: ActionTypes.MediaSelection.MEDIA_IMAGE_UPLOAD_SUCCESS,
    });

    // close the create media pop-up form here..
    yield put({
      type: ActionTypes.CreateMediaFormModal.CLOSE_MEDIA_CREATE_MODAL,
    });

    // TODO :: refresh the media list once the mocked data is removed in backend
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    //upload site image success
    yield put({
      type: ActionTypes.MediaSelection.MEDIA_IMAGE_UPLOAD_FAILURE,
      payload: err,
    });

    // close the create media pop-up form here..
    yield put({
      type: ActionTypes.CreateMediaFormModal.CLOSE_MEDIA_CREATE_MODAL,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.MediaSelection.SELECT_MEDIA, selectMedia),
    takeLatest(ActionTypes.MediaSelection.UN_SELECT_MEDIA, unSelectMedia),
    takeLatest(ActionTypes.MediaSelection.GET_CAMPAIGN_MEDIA, getCampaignMedia),
    takeLatest(
      ActionTypes.MediaSelection.CONFIRM_CAMPAIGN_PLAN,
      confirmCampaignPlanning
    ),
    takeLatest(ActionTypes.MediaSelection.CREATE_CAMPAIGN_MEDIA, createMedia),
  ]);
}
