/**
 * @param {*} responseObject
 * @returns the required POI points array from KYP-Api-Response
 */
function convertKYPResponse(responseObject) {
  if (!responseObject.result) {
    return [];
  }

  const { result } = responseObject;
  const pointArr = result.points;
  return pointArr;
}

/**
 * TODO:: for this API has to be integrated in APIFunction, which returns the ATLAS response
 * @returns the required POI points array from ATLAS-Api-Response
 */
function convertATLASResponse(responseObject) {
  if (!responseObject.points) {
    return [];
  }
  const { points = [] } = responseObject;
  return points;
}

/**
 * @param {*} responseObject --> the API response
 * @returns the "poi-points" response which is needed to show HeatMap
 */
export default function poiTypeLayerResponseConverter(responseObject) {
  // const geoService = process.env.REACT_APP_GEO_SERVICE;

  // if (geoService === "KYP") {
  //   return convertKYPResponse(responseObject);
  // }

  return convertATLASResponse(responseObject);
}
