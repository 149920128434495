// Constants and Utils
import { Status } from "../../constants/GeneralConstants";
import {
  filterDataList,
  getSelectedIndex,
  getTitle,
  isSubSectionOpen,
} from "../nested-checklist/nestedCheckListUtil";

// Components
import Spinner from "../spinner/Spinner";

// Page Component
function ButtonAdditionalState({
  checked,
  id,
  onStatusChange,
  // eachBoundaryLoading,
  regionInfoLoading,
}) {
  const buttonLabel = checked ? (
    <span>
      <i className="fa fa-check mx-1"></i>
      {"Selected"}
    </span>
  ) : (
    "Select"
  );

  return (
    <div className="d-flex">
      {checked && regionInfoLoading && (
        <Spinner className="spinner-border-sm m-1" />
      )}
      <button
        className={`btn add-button shadow-none ${
          checked ? "btn-outline-success" : "btn-outline-primary"
        }`}
        onClick={(e) => {
          onStatusChange(id, checked ? Status.UNCHECKED : Status.CHECKED);
          e.stopPropagation();
        }}
        disabled={checked}
      >
        {buttonLabel}
      </button>
    </div>
  );
}

function RadioBox({
  data,
  index,
  onLabelClick,
  onStatusChange,
  // eachBoundaryLoading,
  regionInfoLoading,
}) {
  const key = `${data.label}_${index}`;
  const { label, status, isSelected, id } = data;
  const hasChildren = data.children.length > 0;
  const checked = Status.CHECKED === status ? true : false;

  return (
    <div
      key={key}
      className={`navbar p-2 ${isSelected ? "bg-alt" : ""}`}
      onClick={() => {
        onLabelClick && onLabelClick(data.label);
      }}
    >
      <label htmlFor={label}>{label}</label>

      {/* Select Button States */}
      {!hasChildren && (
        <ButtonAdditionalState
          checked={checked}
          id={id}
          onStatusChange={onStatusChange}
          // eachBoundaryLoading={eachBoundaryLoading}
          regionInfoLoading={regionInfoLoading}
        />
      )}
      {hasChildren && (
        <i
          className={`mt-1 ${
            isSelected ? "fa fa-angle-down" : "fa fa-angle-right"
          }`}
        ></i>
      )}
    </div>
  );
}

/**
 * @param {{
 *    dataList: {label: string, isSelected: boolean, status: string}[],
 *    onLabelClick: (label: string) => void,
 *    onStatusChange: (label: string, isChecked: boolean) => void,
 * }} props
 */
function RadioList({
  dataList,
  onLabelClick,
  onStatusChange,
  // boundariesLoadingMap,
  regionInfoLoading,
}) {
  if (!dataList || !dataList.length) {
    return null;
  }

  return dataList.map((data, index) => {
    return (
      <RadioBox
        key={index}
        data={data}
        index={index}
        onLabelClick={onLabelClick}
        onStatusChange={onStatusChange}
        // eachBoundaryLoading={boundariesLoadingMap[data.parent]}
        regionInfoLoading={regionInfoLoading}
      />
    );
  });
}

/**
 * Nested RadioList Component
 */
function NestedRadioList({
  data,
  title,
  path,
  setCheckboxStatus,
  setSelected,
  // boundariesLoadingMap,
  regionInfoLoading,
}) {
  const dataList = filterDataList(data, path);
  const selectedIndex = getSelectedIndex(dataList);
  const subSectionOpen = isSubSectionOpen(selectedIndex, dataList);
  const titleToBeDisplayed = getTitle(selectedIndex, dataList);
  return (
    <div className={`nested-radiolist ${title ? "col-6" : ""}`}>
      <div className="d-flex">
        <div className={`${title ? "col-12 px-0" : "col-6"}`}>
          {/* Title and Add All button */}
          {title && (
            <div className="navbar p-0 border-bottom">
              <h3 className="py-2 mb-0 font-weight-bold">{title}</h3>
              <button className="btn add-button shadow-none btn-outline-primary d-none">
                {"Add all"}
              </button>
            </div>
          )}

          {/* Radio List */}
          <RadioList
            dataList={dataList}
            onLabelClick={(label) => setSelected(label)}
            onStatusChange={(id, status) => {
              setCheckboxStatus(id, status);
            }}
            path={path}
            // boundariesLoadingMap={boundariesLoadingMap}
            regionInfoLoading={regionInfoLoading}
          />
        </div>

        {/* nested listing of attributes */}
        {subSectionOpen && (
          <>
            <div className="border-left"></div>
            <NestedRadioList
              data={dataList}
              title={titleToBeDisplayed}
              path={selectedIndex}
              setCheckboxStatus={setCheckboxStatus}
              setSelected={setSelected}
              // boundariesLoadingMap={boundariesLoadingMap}
              regionInfoLoading={regionInfoLoading}
            />
          </>
        )}
      </div>
    </div>
  );
}

NestedRadioList.defaultProps = {
  path: {},
};

export default NestedRadioList;
