import { toast } from "react-toastify";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// API
import {
  getDataLayerDetailsById,
  getDataLayers,
  toggleDataLayerStatus,
} from "../../apis/OrgDataLayerAPI";

// Contants and Utils
import { getErrorMessage } from "../../utils/util";
import { DataLayer } from "../../constants/action-constants/DataLayerActionConstants";

function* getCustomDataLayers(action) {
  const { isArchived, keyWord, pageNumber, pageSize } = action.payload;
  try {
    const { items: dataLayers, pagination } = yield getDataLayers(
      isArchived,
      keyWord,
      pageNumber,
      pageSize
    );
    yield put({
      type: DataLayer.GET_CUSTOM_DATA_LAYERS_SUCCESS,
      payload: { dataLayers, pagination },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: DataLayer.GET_CUSTOM_DATA_LAYERS_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* toggleCustomDataLayerStatus(action) {
  const { id, isArchived } = action.payload;
  try {
    yield toggleDataLayerStatus(id, isArchived);
    yield put({
      type: DataLayer.CHANGE_DATA_LAYER_STATUS_DONE,
      payload: {
        id,
        isArchived: !isArchived,
      },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: DataLayer.CHANGE_DATA_LAYER_STATUS_DONE,
      payload: {
        error: errorMessage,
      },
    });
    toast.error(errorMessage);
  }
}

function* getDataLayerDetails(action) {
  const { id } = action.payload;
  try {
    // to SHOW/START loading on "selected data-layer" on "map-view"
    yield put({
      type: DataLayer.DATA_LAYER_SELECTION_LOADING,
      payload: { id },
    });

    // api response for data-layer info
    const { regions, dataLayer } = yield getDataLayerDetailsById(id);
    yield all([
      put({
        type: DataLayer.GET_DATA_LAYER_DETAILS_SUCCESS,
        payload: {
          dataLayer: {
            id,
            name: dataLayer.name,
            regions: regions,
            dataPointMap: dataLayer.dataPointMap,
            cities: Object.keys(regions).map((id) => ({
              id,
              name: regions[id].name,
            })),
          },
        },
      }),

      // to STOP loading on "selected data-layer" on "map-view"
      put({
        type: DataLayer.DATA_LAYER_SELECTION_LOADED,
        payload: { id },
      }),
    ]);
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: DataLayer.GET_DATA_LAYER_DETAILS_FAILURE,
      payload: {
        error: errorMessage,
      },
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(DataLayer.GET_CUSTOM_DATA_LAYERS, getCustomDataLayers),
    takeEvery(DataLayer.CHANGE_DATA_LAYER_STATUS, toggleCustomDataLayerStatus),
    takeEvery(DataLayer.GET_DATA_LAYER_DETAILS, getDataLayerDetails),
  ]);
}
