import { Monitoring } from "../../constants/action-constants/MonitoringActionConstants";

function getMonitoringEnabledIds(citySummaryItems) {
  // Check if isMonitoringEnabled is true and settings.isActive is true
  return citySummaryItems
    .filter((item) => item.media.isMonitoringEnabled && item.settings?.isActive)
    .map((item) => item.settings.id);
}

const initialState = {
  // Monitoring Campaign Summary -----------------------------------------------
  campaignSummaryLoading: true,
  campaignSummaryError: "",
  campaignSummary: {},

  // Monitoring City Summary ------------------------------------------------
  monitoringCitySummaryLoading: true,
  monitoringCitySummaryError: "",
  monitoringCitySummary: {},
  monitoringEnabledIds: [],

  // Monitoring Media Sites Selection -----------------------------------------------
  selectedSites: {},

  // Monitoring Media Settings ---------------------------------------------------
  mediaSettingsLoading: true,
  mediaSettingsError: "",
  mediaSettings: {},

  // Create Setup-Monitoring Form -----------------------------------------------
  setupMonitoringLoading: false,
  setupMonitoringSuccess: false,
  setupMonitoringError: "",

  // Media SetupMonitoring Form Model ----------------------------------------------
  openModal: false,
  monitoringMediaId: "",
  monitoringMediaDateObj: {},

  // Cancel Site Monitoring -----------------------------------------------------------
  cancelSiteMonitoringLoading: false,
  cancelSiteMonitoringError: "",

  // Edit Site Monitoring Settings
  monitorMediaSettingId: "",
};

/**
 * @param {*} ids
 * @param {*} select
 * @returns multiple selected media sites
 */
function multipleSitesAction(ids, select = true) {
  const selectedSites = {};
  for (const id of ids) {
    selectedSites[id] = select;
  }
  return selectedSites;
}

export default (state = initialState, action) => {
  switch (action.type) {
    // Monitoring Campaign Summary
    // -----------------------------------------------------------------------------------------
    case Monitoring.GET_MONITORING_CAMPAIGN_SUMMARY:
      return {
        ...state,
        campaignSummaryLoading: true,
      };

    case Monitoring.GET_MONITORING_CAMPAIGN_SUMMARY_SUCCESS:
      return {
        ...state,
        campaignSummary: action.payload.summaryResponse,
        campaignSummaryLoading: false,
      };

    case Monitoring.GET_MONITORING_CAMPAIGN_SUMMARY_FAILURE:
      return {
        ...state,
        campaignSummaryLoading: false,
        campaignSummaryError: action.payload,
      };

    // Monitoring City Summary
    // ---------------------------------------------------------------------------------------
    case Monitoring.GET_MONITORING_CITY_SUMMARY:
      return {
        ...state,
        monitoringCitySummaryLoading: true,
      };

    case Monitoring.GET_MONITORING_CITY_SUMMARY_SUCCESS:
      return {
        ...state,
        monitoringCitySummary: action.payload.responseCitySummary,
        monitoringEnabledIds: getMonitoringEnabledIds(
          action.payload.responseCitySummary.items
        ),
        monitoringCitySummaryLoading: false,
      };

    case Monitoring.GET_MONITORING_CITY_SUMMARY_FAILURE:
      return {
        ...state,
        monitoringCitySummaryLoading: false,
        monitoringCitySummaryError: action.payload,
      };

    // Monitoring Media Sites Selection
    // -----------------------------------------------------------------------------------
    case Monitoring.SELECT:
      return {
        ...state,
        selectedSites: {
          ...state.selectedSites,
          [action.payload.id]: true,
        },
      };

    case Monitoring.SELECT_MULTIPLE:
      return {
        ...state,
        selectedSites: multipleSitesAction(action.payload.ids, true),
      };

    case Monitoring.UNSELECT:
      return {
        ...state,
        selectedSites: {
          ...state.selectedSites,
          [action.payload.id]: false,
        },
      };

    case Monitoring.UNSELECT_MULTIPLE:
      return {
        ...state,
        selectedSites: multipleSitesAction(action.payload.ids, false),
      };

    case Monitoring.RESET_SELECTION:
      return {
        ...state,
        selectedSites: {},
      };

    // Monitoring Media Settings
    // --------------------------------------------------------------------------------------
    case Monitoring.GET_MONITORING_MEDIA_SETTING:
      return {
        ...state,
        mediaSettingsLoading: true,
      };

    case Monitoring.GET_MONITORING_MEDIA_SETTING_SUCCESS:
      return {
        ...state,
        mediaSettings: action.payload.responseSettings,
        mediaSettingsLoading: false,
      };

    case Monitoring.GET_MONITORING_MEDIA_SETTING_FAILURE:
      return {
        ...state,
        mediaSettingsLoading: false,
        mediaSettingsError: action.payload,
      };

    // Create Setup-Monitoring Form
    // -------------------------------------------------------------------
    case Monitoring.CREATE_SETUP_MONITORING:
      return {
        ...state,
        setupMonitoringLoading: true,
      };

    case Monitoring.CREATE_SETUP_MONITORING_SUCCESS:
      return {
        ...state,
        setupMonitoringSuccess: true,
        setupMonitoringLoading: false,
      };

    case Monitoring.CREATE_SETUP_MONITORING_FAILURE:
      return {
        ...state,
        setupMonitoringError: action.payload,
        setupMonitoringLoading: false,
      };

    // Open-Close Setup-Monitoring Form Model
    // -------------------------------------------------------------------
    case Monitoring.OPEN_SETUP_MONITORING_MODAL:
      return {
        ...state,
        openModal: true,
        monitoringMediaId: action.payload.id,
        monitoringMediaDateObj: action.payload.mediaDateObj,
        monitorMediaSettingId: action.payload.settingId,
      };
    case Monitoring.CLOSE_SETUP_MONITORING_MODAL:
      return {
        ...state,
        openModal: false,
      };

    // Cancel Site Monitoring
    // --------------------------------------------------------------------
    case Monitoring.CANCEL_SITE_MONITORING:
      return {
        ...state,
        cancelSiteMonitoringLoading: true,
      };

    case Monitoring.CANCEL_SITE_MONITORING_SUCCESS:
      return {
        ...state,
        cancelSiteMonitoringLoading: false,
      };

    case Monitoring.CANCEL_SITE_MONITORING_FAILURE:
      return {
        ...state,
        cancelSiteMonitoringLoading: false,
        cancelSiteMonitoringError: action.payload,
      };

    default:
      return state;
  }
};
