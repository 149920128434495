import moment from "moment";
import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Api
import {
  addCitiesToCampaignFn,
  createCampaignData,
  getCampaignList,
  getSharedCampaigsFn,
  removeCityFromCampaignFn,
  updateCampaignTitle,
  updateCityInfo,
} from "../../apis/CampaignAPI";
import { findMavinMetrics } from "../../apis/CampaignMediaAPI";
import { getCampaignSummaryData } from "../../apis/CampaignReportAPI";
import { getMonitoringCampSummaryData } from "../../apis/MonitoringSettingsAPI";

// Constants and Utils
import { Monitoring } from "../../constants/action-constants/MonitoringActionConstants";
import { CampaignReport } from "../../constants/action-constants/CampaignReportActionConstants";
import { Campaign } from "../../constants/action-constants/CampaignActionConstants";
import { DATE_FORMATS, DispatchFrom } from "../../constants/GeneralConstants";
import { getErrorMessage } from "../../utils/util";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

/**
 * @param {*} infoByCity
 * @param {*} startDate
 * @param {*} endDate
 * @returns each city with Start and End date
 */
function getDateUpdatedInfoByCity(infoByCity, startDate, endDate) {
  // DD-MM-YYYY
  const dateFormat = DATE_FORMATS.date_month_year;

  const convertedStartDate = startDate
    ? moment(startDate).format(dateFormat)
    : "";
  const convertedEndDate = endDate ? moment(endDate).format(dateFormat) : "";

  const updatedArrayOfCities = infoByCity.map((city) => ({
    cityId: city.id,
    startDate: convertedStartDate,
    endDate: convertedEndDate,
  }));

  return updatedArrayOfCities;
}

function* createCampaign(action) {
  try {
    const { newCampaign, history } = action.payload;
    const {
      startDate,
      endDate,
      title,
      infoByCity,
      tgId,
      budget = 0,
    } = newCampaign;

    // Adding StartDate and EndDate of Campaign to Each City
    const dateUpdatedInfoByCity = getDateUpdatedInfoByCity(
      infoByCity,
      startDate,
      endDate
    );

    // DD-MM-YYYY
    const dateFormat = DATE_FORMATS.date_month_year;
    const body = {
      infoByCity: dateUpdatedInfoByCity,
      title,
      startDate: startDate ? moment(startDate).format(dateFormat) : "",
      endDate: endDate ? moment(endDate).format(dateFormat) : "",
      targetGroupId: tgId,
      budget,
    };

    const { id } = yield createCampaignData(body);
    yield put({
      type: Campaign.CREATE_CAMPAIGN_SUCCESS,
    });

    history.push(constructRedirectPath(`/campaign/${id}/plan/overview`));
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: Campaign.CREATE_CAMPAIGN_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

export function* getAllCampaignsSummary(action) {
  try {
    const { searchText, pageNumber, pageSizeCount } = action.payload;

    const campaignList = yield getCampaignList(
      searchText,
      pageNumber,
      pageSizeCount
    );

    //dispatching action
    yield put({
      type: Campaign.GET_CAMPAIGNS_SUCCESS,
      payload: campaignList,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    /* istanbul ignore next */
    yield put({
      type: Campaign.GET_CAMPAIGNS_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

export function* updateCampaignTitleFn(action) {
  try {
    const { campaignId, titleStr, dispatchFrom } = action.payload;
    // update campaign title
    yield updateCampaignTitle(campaignId, titleStr);

    // calling API for "monitoring-campaign-summary" (to prevent page-reload)
    if (dispatchFrom === DispatchFrom.campaignMonitoring) {
      const summaryResponse = yield getMonitoringCampSummaryData(campaignId);
      yield put({
        type: Monitoring.GET_MONITORING_CAMPAIGN_SUMMARY_SUCCESS,
        payload: { summaryResponse },
      });
    } else {
      // calling API for "report-campaign-summary" (to prevent page-reload)
      const data = yield getCampaignSummaryData(campaignId);
      yield put({
        type: CampaignReport.CAMPAIGN_OVERVIEW_SUCCESS,
        data: data,
      });
    }

    yield put({
      type: Campaign.UPDATE_CAMPAIGN_TITLE_SUCCESS,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Campaign.UPDATE_CAMPAIGN_TITLE_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

// THIS WOULD BE MODIFIED TO CHANGE CITY INFO. FOR NOW WE HANDLE ONLY CHANGING DURATION
export function* updateCampaignDuration(action) {
  try {
    const { dateObj, cityInfo } = action.payload;

    // DD-MM-YYYY
    const dateFormat = DATE_FORMATS.date_month_year;

    const body = {
      budget: cityInfo.budget,
      startDate: moment(dateObj.startDate).format(dateFormat),
      endDate: moment(dateObj.endDate).format(dateFormat),
    };

    yield updateCityInfo(cityInfo.campaignId, cityInfo.cityId, body);
    yield findMavinMetrics(cityInfo.campaignId, cityInfo.cityId);

    const data = yield getCampaignSummaryData(cityInfo.campaignId);
    yield put({
      type: CampaignReport.CAMPAIGN_OVERVIEW_SUCCESS,
      data: data,
    });

    yield put({
      type: Campaign.UPDATE_CAMPAIGN_DURATION_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: Campaign.UPDATE_CAMPAIGN_DURATION_FAILURE,
      payload: err,
    });
  }
}

function* getSharedCampaigns() {
  try {
    const sharedCampaignList = yield getSharedCampaigsFn();
    yield put({
      type: Campaign.GET_SHARED_CAMPAIGN_SUCCESS,
      payload: { sharedCampaignList },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Campaign.GET_SHARED_CAMPAIGN_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* addCitiesToCampaign(action) {
  const { campaignId, selectedCities } = action.payload;
  try {
    // adding cities to existing campaign
    yield addCitiesToCampaignFn(campaignId, selectedCities);

    // Calling overview api to get updated campaign
    yield put({
      type: CampaignReport.CAMPAIGN_OVERVIEW,
      payload: { id: campaignId },
    });

    yield put({
      type: Campaign.ADD_CITIES_TO_EXISTING_CAMPAIGN_SUCCESS,
    });
    toast.success("Successfully Added Cities to Campaign");
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Campaign.ADD_CITIES_TO_EXISTING_CAMPAIGN_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

function* removeCityFromCampaign(action) {
  const { campaignId, cityId, dispatchFrom } = action.payload;

  try {
    yield removeCityFromCampaignFn(campaignId, cityId);

    // once city is removed, calling again the "CampaignOverview" to get updated campaignSummary
    if (dispatchFrom === DispatchFrom.campaignMonitoring) {
      yield put({
        type: Monitoring.GET_MONITORING_CAMPAIGN_SUMMARY,
        payload: { id: campaignId },
      });
    } else {
      // for Campaign-Report
      yield put({
        type: CampaignReport.CAMPAIGN_OVERVIEW,
        payload: { id: campaignId },
      });
    }

    yield put({
      type: Campaign.REMOVE_CITY_FROM_CAMPAIGN_SUCCESS,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: Campaign.REMOVE_CITY_FROM_CAMPAIGN_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(Campaign.UPDATE_CAMPAIGN_TITLE, updateCampaignTitleFn),
    takeLatest(Campaign.GET_CAMPAIGNS, getAllCampaignsSummary),
    takeLatest(Campaign.CREATE_CAMPAIGN, createCampaign),
    takeLatest(Campaign.UPDATE_CAMPAIGN_DURATION, updateCampaignDuration),
    takeLatest(Campaign.GET_SHARED_CAMPAIGN, getSharedCampaigns),
    takeLatest(Campaign.ADD_CITIES_TO_EXISTING_CAMPAIGN, addCitiesToCampaign),
    takeLatest(Campaign.REMOVE_CITY_FROM_CAMPAIGN, removeCityFromCampaign),
  ]);
}
