import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Action
import { getOrgAllUsers } from "../../../actions/org/OrgUserManageActions";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Utils
import { LoadingData } from "../OrgUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Components
import OrgSettingsSidebar from "../OrgSettingsSidebar";
import TableHeaders from "../../../components/table/TableHeaders";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import Pagination from "../../../components/pagination/Pagination";

// Css
import "../OrgSettings.scss";

const tableTitle = [
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Email Id",
    },
  },
  {
    title: {
      displayName: "Phone No.",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function TableActionsSection() {
  return (
    <div className="d-flex mb-2">
      <Link
        className="ml-auto"
        to={constructRedirectPath(RedirectTo.orgUserCreateUrl)}
      >
        + Add new user
      </Link>
    </div>
  );
}

function UsersListTable({
  orgUsersList,
  orgUsersPagination,
  onClickPagination,
}) {
  const { totalCount, pageNo, pageSize } = orgUsersPagination;

  // Selector
  const isLoading = useSelector((state) => state.orgUserManage.loading);

  // Check Page loading
  if (isLoading) {
    return <LoadingData dataType="User List" />;
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <TableHeaders tableHeaders={tableTitle} headerClass={"thead"} />
        <tbody>
          {orgUsersList.map((user) => (
            <tr key={user.id}>
              <td>{user.firstName}</td>
              <td>{user.emailId}</td>
              <td>{user.phoneNo || "--"}</td>
              <td>
                <Link
                  to={constructRedirectPath(
                    RedirectTo.orgUserManageUrl.replace(":userId", user.id)
                  )}
                >
                  Manage
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Table-pagination */}
      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={onClickPagination}
      />
    </div>
  );
}

/**
 * Page
 */
export default function OrgUsersPage() {
  const dispatch = useDispatch();

  // Selector
  const orgUsersList = useSelector((state) => state.orgUserManage.orgUserList);
  const orgUsersPagination = useSelector(
    (state) => state.orgUserManage.orgUserPagination
  );

  const pageTitle = "Org :: Users & Roles";

  useEffect(() => {
    dispatch(getOrgAllUsers());
  }, [dispatch]);

  // Function
  function onClickPagination(pageNumber, pageSize) {
    dispatch(getOrgAllUsers(pageNumber, pageSize));
  }

  return (
    <>
      <OrgSettingsSidebar activeIdx={2} />

      <div className="content-wrapper">
        {/** Page Header */}
        <PageHeader title={pageTitle} shadow={true} />

        {/** Page Content */}
        <div className="page-content">
          {/** Table Header */}
          <TableActionsSection />

          {/** Table */}
          <UsersListTable
            orgUsersList={orgUsersList}
            orgUsersPagination={orgUsersPagination}
            onClickPagination={onClickPagination}
          />
        </div>
        {/** */}
      </div>
    </>
  );
}
