import { Monitoring } from "../../constants/action-constants/MonitoringActionConstants";

const initialState = {
  monitoringMediaLogLoading: true,
  monitoringMediaLogError: "",
  monitoringMediaLog: {},

  // Monitoring Log Pdf Report..
  downloadingMonitorPdfReport: false,
  monitorPdfReportErr: "",

  // monitoring log image modal
  openModal: false,
  processDataObject: {},

  // downloading monitoring log image
  downloadMonitoringMediaLogImage: false,
  downloadMonitoringMediaLogImageErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Monitoring.GET_MONITORING_MEDIA_LOG:
      return {
        ...state,
        monitoringMediaLogLoading: true,
      };

    case Monitoring.GET_MONITORING_MEDIA_LOG_SUCCESS:
      return {
        ...state,
        monitoringMediaLog: action.payload.responseMediaLog,
        monitoringMediaLogLoading: false,
      };

    case Monitoring.GET_MONITORING_MEDIA_LOG_FAILURE:
      return {
        ...state,
        monitoringMediaLogLoading: false,
        monitoringMediaLogError: action.payload,
      };

    // Download Report Pdf
    //-------------------------------------------------------------------------------------
    case Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT:
      return {
        ...state,
        downloadingMonitorPdfReport: true,
      };

    case Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT_SUCCESS:
      return {
        ...state,
        downloadingMonitorPdfReport: false,
      };

    case Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_PDF_REPORT_FAILURE:
      return {
        ...state,
        downloadingMonitorPdfReport: false,
        monitorPdfReportErr: action.payload,
      };

    // Monitoring log Image Modal
    case Monitoring.OPEN_MONITORING_MEDIA_LOG_IMAGE_MODAL: {
      return {
        ...state,
        openModal: true,
        processDataObject: action.payload.processDataObject,
      };
    }

    case Monitoring.CLOSE_MONITORING_MEDIA_LOG_IMAGE_MODAL: {
      return {
        ...state,
        openModal: false,
        processDataObject: {},
      };
    }

    // Download monitoring log image
    case Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE: {
      return {
        ...state,
        downloadMonitoringMediaLogImage: true,
      };
    }

    case Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE_SUCCESS: {
      return {
        ...state,
        downloadMonitoringMediaLogImage: false,
      };
    }

    case Monitoring.DOWNLOAD_MONITORING_MEDIA_LOG_IMAGE_FAILURE: {
      return {
        ...state,
        downloadMonitoringMediaLogImage: false,
        downloadMonitoringMediaLogImageErr: action.payload,
      };
    }

    default:
      return state;
  }
};
