import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import { getRoadStretchesData } from "../../../apis/OrgRoadStretchAPI";

// Constants and utils
import { RoadStretch } from "../../../constants/action-constants/RoadStretchActionConstants";
import { getErrorMessage } from "../../../utils/util";

export function* getCityRoadStretches(action) {
  const { bbox, isArchived } = action.payload;
  try {
    // api response for "road-stretch-details"
    const response = yield getRoadStretchesData(bbox, isArchived);

    // TODO: Need this below commented code for tsesting..
    // const stretchs = [
    //   "62fc82ffdd404746f50295be",
    //   "62fca30add404746f50337a0",
    //   "62fc8e77dd404746f502e1f6",
    //   "62fc8eb6dd404746f502e90a",
    //   "62fb55dd2429624a5e558284",
    // ];
    // response.items = response.items.filter((each) =>
    //   stretchs.includes(each.id)
    // );

    yield put({
      type: RoadStretch.GET_CITY_ROAD_STRETCHES_SUCCESS,
      payload: response.items,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: RoadStretch.GET_CITY_ROAD_STRETCHES_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(RoadStretch.GET_CITY_ROAD_STRETCHES, getCityRoadStretches),
  ]);
}
