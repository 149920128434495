import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

//Actions
import {
  downloadMediaPdfReport,
  getMediaDetails,
  uploadFileItems,
} from "../../../actions/campaign-media/CampaignMediaActions";

// Constants and Utils
import { selectFile } from "../../../common-utils/file-utils/FileUtils";
import { ImageFileTypes } from "../../../constants/GeneralConstants";

//Components
import Spinner from "../../../components/spinner/Spinner";
import MediaInfo from "../../../mavin/components/media-info/MediaInfo";
import PageHeader from "../../../mavin/components/page-header/PageHeader";
import MediaSitePerformance from "../../../mavin/components/media-site-performance/MediaSitePerformance";
import LocationSummary from "../../../mavin/components/location-summary/LocationSummary";
import HourlyTrafficDataSection from "../../../mavin/components/hourly-traffic-data/HourlyTrafficDataSection";
import MediaImageWithMap from "../../../mavin/components/media-image-with-map/MediaImageWithMap";

//Css
import "./CampaignMediaViewPage.scss";

// Page Components
function DownloadReport({ campaignId, mediaId, radius, mediaTitle }) {
  const dispatch = useDispatch();

  const downloadingReportLoading = useSelector(
    (state) => state.campaignMedia.downloadingReport
  );

  // loading
  if (downloadingReportLoading) {
    return <Spinner />;
  }

  return (
    <p
      className="text-primary cursor-pointer my-2"
      onClick={() =>
        dispatch(
          downloadMediaPdfReport(campaignId, mediaId, radius, mediaTitle)
        )
      }
      data-html2canvas-ignore="true"
    >
      Download Media PDF Report
    </p>
  );
}

/**
 * Page
 */

function CampaignMediaViewPage() {
  const dispatch = useDispatch();
  const { campaignId, mediaId } = useParams();

  // State
  const [radius, setRadius] = useState(1000);

  //Selector
  const fileItems = useSelector((state) => state.campaignMedia.fileItems);

  const loading = useSelector(
    (state) => state.campaignMedia.mediaDetailsLoading
  );

  const fileItemsLoading = useSelector(
    (state) => state.campaignMedia.fileItemsLoading
  );

  const media = useSelector((state) => state.campaignMedia.media.mediaDetails);

  const roadSegmentTrafficInfo =
    useSelector((state) => state.campaignMedia.media.roadSegmentTrafficInfo) ||
    {};

  //Dispatch
  useEffect(() => {
    dispatch(getMediaDetails(campaignId, mediaId));
  }, [dispatch, campaignId, mediaId]);

  // upload function
  async function selectAndUpload() {
    const files = await selectFile(true, ImageFileTypes);
    dispatch(uploadFileItems(campaignId, mediaId, files));
  }

  //Checks for page loading
  if (loading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  //Checks for media
  if (!media) {
    return null;
  }

  const { latitude, longitude, title } = media;

  const pageActions = (
    <DownloadReport
      campaignId={campaignId}
      mediaId={mediaId}
      radius={radius}
      mediaTitle={title}
    />
  );

  return (
    <div className="content-wrapper media-page" id="cont-media-details">
      {/* Page Header */}
      <PageHeader title="Media Report" actions={pageActions} shadow={true} />

      {/* Page content */}
      <div className="page-content">
        {/* Media info  */}
        <MediaInfo media={media} titleClassName={"h2"} />
        <hr className="divider"></hr>

        {/* Site performance */}
        <MediaSitePerformance
          media={media}
          title={"Site Performance"}
          className="py-3"
        />
        <hr className="divider"></hr>

        {/* Media image and map view*/}
        <MediaImageWithMap
          media={media}
          fileItems={fileItems}
          loading={loading || fileItemsLoading}
          onClick={selectAndUpload}
        />

        {/* Hourly Traffic chart and Table */}
        <HourlyTrafficDataSection
          media={media}
          roadSegmentTrafficInfo={roadSegmentTrafficInfo}
        />

        {/* Location summary */}
        {latitude && longitude && (
          <LocationSummary
            latitude={latitude}
            longitude={longitude}
            radius={radius}
            setRadius={setRadius}
          />
        )}
      </div>
    </div>
  );
}

export default CampaignMediaViewPage;
