// Utils
import { DivIcon } from "leaflet";

// Utils and Constants
import { toLocaleString } from "../common-utils/string-utils/StringUtils";
import { getMediaOts } from "./mavinMetrics";
import {
  MediaLitStatus,
  MediaTagTypes,
  MediaTypes,
  MetricScores,
} from "../constants/GeneralConstants";

// Default values
const MIN_VALUE = 0;
const MAX_VALUE = Number.MAX_VALUE;

// TODO :: move this function to util file
function isWithinRange(value, minValue, maxValue) {
  const minimumValue = minValue || MIN_VALUE;
  const maximumValue = maxValue || MAX_VALUE;

  return value && value >= minimumValue && value <= maximumValue;
}

export function filterMedia(mediaList = [], filters = {}, isOrgMedia) {
  const {
    litStatus = [],
    orientation = [],
    mediaTypes = [],
    title,
    maxHeight,
    minHeight,
    maxWidth,
    minWidth,
    maxCost,
    minCost,
    minArea,
    maxArea,
  } = filters;

  const minImpression = filters.impressions || MIN_VALUE;
  // checks conditions
  const conditions = {
    height: (media) => isWithinRange(media.height, minHeight, maxHeight),

    width: (media) => isWithinRange(media.width, minWidth, maxWidth),

    minImpression: (media) => {
      const { ots = 0 } = getMediaOts(media) || {};
      return ots >= minImpression;
    },

    type: (media) =>
      mediaTypes.length < 1 || mediaTypes.includes(media.mediaGroup),

    litStatusCheck: (media) =>
      litStatus.length < 1 || litStatus.includes(media.litStatus),

    title: (media) =>
      !title ||
      media.title
        .replace(/\s+/g, " ") // Replace any multiple spaces with a single space in the media title
        .toLowerCase()
        .includes(title.toLowerCase()),

    orientation: (media) =>
      orientation.length < 1 || orientation.includes(media.orientation),
  };

  // if org media adding these two conditions
  if (isOrgMedia) {
    conditions.costPM = (media) => {
      const { rentalCost = {} } = media;
      const { price = 0 } = rentalCost;
      return isWithinRange(price, minCost, maxCost);
    };
    conditions.area = (media) =>
      isWithinRange(media.totalArea, minArea, maxArea);
  }

  // filter method
  const filteredMediaList = mediaList.filter((media) =>
    Object.values(conditions).every((condition) => condition(media))
  );

  return filteredMediaList;
}

function getMediaLtsFn(media) {
  const { ltsSummary = {} } = media;
  return ltsSummary.lts;
}

function isMediaSelected(media) {
  return media.isSelected;
}

/**
 * sort media by ots and lts
 *
 * @param {*} mediaList ==> [{},{}]
 * @param {*} sortKey ==> "ots","lts","none"
 * @returns  sorted media list
 */
export function sortMediaByImpression(mediaList = [], sortKey) {
  const mediaListToSort = [...mediaList];
  // if sort key is "none" return "mediaList"
  if (sortKey === "none") {
    return mediaList;
  }

  if (sortKey === "ots") {
    return mediaListToSort.sort(
      (mediaOne, mediaTwo) =>
        getMediaOts(mediaTwo)?.ots - getMediaOts(mediaOne)?.ots
    );
  }

  if (sortKey === "lts") {
    return mediaListToSort.sort(
      (mediaOne, mediaTwo) => getMediaLtsFn(mediaTwo) - getMediaLtsFn(mediaOne)
    );
  }
  if (sortKey === "selected") {
    return mediaListToSort.sort(
      (mediaOne, mediaTwo) =>
        isMediaSelected(mediaTwo) - isMediaSelected(mediaOne)
    );
  }
}

/**
 *
 * @param {*} mediaCountMap
 * @returns "totalMediaCount" and "totalCount of different-different mediaTypes"
 */
export function prepareMediaTypesCount(mediaCountMap) {
  // Calculating totalMediaCount
  const totalMediaCount = Object.values(mediaCountMap).reduce(
    (acc, eachType) => acc + eachType,
    0
  );

  const mediaTypesCount = {
    BILLBOARDS: {
      displayName: "Billboards",
      count: toLocaleString(mediaCountMap["BILLBOARDS"]) || 0,
    },
    GANTRIES: {
      displayName: "Gantries",
      count: toLocaleString(mediaCountMap["GANTRIES"]) || 0,
    },
    UNIPOLES: {
      displayName: "Unipoles",
      count: toLocaleString(mediaCountMap["UNIPOLES"]) || 0,
    },
    FOB: {
      displayName: "FOB",
      count: toLocaleString(mediaCountMap["FOB"]) || 0,
    },
    TRANSIT_STATIONS: {
      displayName: "BQS",
      count: toLocaleString(mediaCountMap["TRANSIT_STATIONS"]) || 0,
    },
    DOOH: {
      displayName: "DOOH",
      count: toLocaleString(mediaCountMap["DOOH"]) || 0,
    },
    TOTAL: toLocaleString(totalMediaCount) || 0,
  };

  return mediaTypesCount;
}

/**
 * Function which returns the DivIcon for the media..
 */
export function getMediaIcon(color, isOtherOrgMedia = false) {
  // bg color of marker
  const mediaMarkerHtmlStyle = `background-color: ${color}`;

  // org media className
  const orgMediaClassName = !isOtherOrgMedia ? "icon-media" : "icon-media star";

  // icon
  return new DivIcon({
    className: orgMediaClassName,
    html: `<span style="${mediaMarkerHtmlStyle}" />`,
  });
}

/**
 *
 * @param {*} mediaList =>array of media objects
 * @returns sorted media list array based on score generated
 * Score is calculated for each media site based on default score given to parameters (like ots,reach ..)
 */
export function sortMediaListByScore(mediaList = []) {
  mediaList.forEach((media) => {
    const {
      mediaGroup,
      ltsSummary = {},
      totalArea,
      litStatus,
      isDigital,
      mediaTag = "Impact",
      dwellTimeSummary = {},
      reachFrequency = {},
    } = media;

    const { averageTime = 0 } = dwellTimeSummary;
    const { ots = 0, tgOts = 0 } = getMediaOts(media) || {};
    const { reach = 0, tgReach = 0 } = reachFrequency;
    const { ltsRating = 0 } = ltsSummary;

    // metrics parameter Score
    // calculated by multiplying metric with some given scores
    const metricsParameterScore = Math.floor(
      averageTime * MetricScores.dwellTimeScore +
        ots * MetricScores.impressionsScore +
        tgOts * MetricScores.tgImpressionsScore +
        reach * MetricScores.reachScore +
        tgReach * MetricScores.tgReachScore
    );

    let areaScore;
    let ltsScore;
    if (totalArea >= 1600) {
      areaScore = 7;
    } else if (totalArea >= 400) {
      areaScore = 10;
    } else if (totalArea >= 200) {
      areaScore = 9;
    } else {
      areaScore = 8;
    }

    if (ltsRating < 3.5) {
      ltsScore = 6;
    } else if (ltsRating < 4) {
      ltsScore = 7;
    } else if (ltsRating < 4.5) {
      ltsScore = 8;
    } else {
      ltsScore = 9;
    }
    const litScore = litStatus === MediaLitStatus.NOT_LIT ? 7 : 9;
    // if digital site score is 9,otherwise score in 7,(even when field is not present)
    const doohScore = isDigital != null ? (isDigital ? 9 : 7) : 7;

    // site Classification Score
    const { score: siteClassificationScore } = MediaTagTypes.find(
      (tag) => tag.type === mediaTag
    );

    const matchedMediaObj = MediaTypes.find(
      (eachType) => eachType.type === mediaGroup
    );
    const { score: mediaTypeScore } = matchedMediaObj;

    // physical Parameters Score
    const physicalParametersScore =
      (mediaTypeScore + areaScore + ltsScore) *
      (litScore + doohScore + siteClassificationScore);

    const total = metricsParameterScore * physicalParametersScore;

    media.mediaScore = total;
  });
  return mediaList.sort((a, b) => b.mediaScore - a.mediaScore);
}

// media Cost for campaign
export function getMediaCostForCampaign(
  pricing = {},
  mediaActiveDuration,
  mediaPriceBaseDuration = 30 // 30 days
) {
  const { price } = pricing || {};

  if (!price) {
    return 0;
  }

  // cost for campaign
  const mediaCostForCampaign =
    (mediaActiveDuration * (price ? price : 0)) / mediaPriceBaseDuration;

  return mediaCostForCampaign;
}

// get Total Cfc For Selected Medias
export function getTotalCfcForSelectedMedias(selectedMediaSites = []) {
  const totalCfcForSelectedMedias = selectedMediaSites.reduce(
    (acc, media) => acc + media?.sellerPrice,
    0
  );

  return totalCfcForSelectedMedias;
}
