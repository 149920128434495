import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";

function zeroFixedValue(value) {
  if (value === 0) {
    return value;
  }
  return toLocaleString(value);
}

function getAvgRealtyPrice(realtySale) {
  if (realtySale.count === 0) {
    return "--";
  }

  return (
    <>
      {`Rs. ${zeroFixedValue(realtySale.mean)} per sq. ft.
        (Rs. ${zeroFixedValue(realtySale.min)} - Rs.
        ${zeroFixedValue(realtySale.max)} per sq. ft.)`}
    </>
  );
}

function getAvgCost(sustenance) {
  if (sustenance.count === 0) {
    return "--";
  }

  return (
    <>
      {`Rs. ${zeroFixedValue(sustenance.mean)} (Rs. ${zeroFixedValue(
        sustenance.min
      )} - Rs. ${zeroFixedValue(sustenance.max)})`}
    </>
  );
}

/**
 * The names of restaurants and realtySociety are changed to sustenance and realtySale in atlas apis
 * @param {*} sustenance =>object containing information about restaurants data
 * @param {*} realtySale =>object containing information about realtySociety data
 * @returns
 */

export default function SocioEconomicIndicators({
  className = "",
  sustenance = {},
  realtySale = {},
}) {
  const avgRealtyPrice = getAvgRealtyPrice(realtySale);
  const avgCostOfTwoMeal = getAvgCost(sustenance);

  return (
    <div className={`socia-economic-indicators ${className}`}>
      <p>
        <strong>Socio-economic indicators</strong>
      </p>
      <p>Apartment complexes: {zeroFixedValue(realtySale.count)} </p>
      <p>Average Realty Price: {avgRealtyPrice}</p>

      <p>Restaurants: {zeroFixedValue(sustenance.count)} </p>
      <p>Average Cost (For two persons): {avgCostOfTwoMeal}</p>
    </div>
  );
}
