import { useState } from "react";
import { Link } from "react-router-dom";

// Utils
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

// Page Components
// ----------------------------------------------------------------------------

/**
 * Tab View :: Selected Content
 */
function TabSelectedSection({
  children,
  content = {},
  setSelectedView,
  setIsOrgMedia,
}) {
  // Content
  const { header: headerContent, body: bodyContent } = content;
  const { title, changeViewBtnText, changeViewBtnText2 } = headerContent || {};
  const { emptyIconCls = "", emptyText = "" } = bodyContent || {};

  return (
    <div className={"map-tab-selected"}>
      {/* Header Section */}
      <div className="navbar p-0">
        <h2>{title}</h2>
        <div className="d-flex align-items-center">
          <button
            className="btn shadow-none text-primary p-0"
            onClick={() => {
              setSelectedView(false);
              setIsOrgMedia(false);
            }}
          >
            {changeViewBtnText}
          </button>

          {changeViewBtnText2 && (
            <button
              className="btn shadow-none text-primary p-0 ml-3 pl-3 border-left"
              onClick={() => {
                setSelectedView(false);
                setIsOrgMedia(true);
              }}
            >
              {changeViewBtnText2}
            </button>
          )}
        </div>
      </div>
      <hr className="divider my-2"></hr>

      {/* Empty Body - No Children */}
      {!children && (
        <div className="d-flex flex-column align-items-center mt-5 pt-5">
          <i className={`fa-7x opacity-25 text-primary ${emptyIconCls}`}></i>
          <button
            className="btn btn-primary rounded-pill mt-2"
            onClick={() => setSelectedView(false)}
          >
            <b>{emptyText}</b>
          </button>
        </div>
      )}

      {/** Render data */}
      {children}
    </div>
  );
}

/**
 * Tab View :: Listing Content
 */
function TabListingSection({ children, content = {}, setSelectedView }) {
  // Content
  const { header: headerContent } = content;

  return (
    <div className={"map-tab-listing"}>
      {/* Header Section */}
      <div className="navbar p-0">
        <div className="d-flex">
          <i
            className="fa fa-arrow-left text-primary cursor-pointer mt-2"
            onClick={() => setSelectedView(true)}
          ></i>
          <h2 className="ml-3">{headerContent.title}</h2>
        </div>
        <Link
          to={constructRedirectPath(headerContent.createBtnUrl)}
          target="_blank"
        >
          {headerContent.createBtnText}
        </Link>
      </div>
      <hr className="divider my-2"></hr>

      {/** Render data */}
      {children}
    </div>
  );
}

/**
 * Campaign Main Map-View TAB Component
 */
function MapTab({
  selectedViewLabels,
  selectedViewChildren,
  listingViewLabels,
  listingViewChildren,
  setIsOrgMedia,
}) {
  // State
  const [selectedView, setSelectedView] = useState(true);

  return (
    <div className="map-tab">
      {selectedView ? (
        <TabSelectedSection
          content={selectedViewLabels}
          setSelectedView={setSelectedView}
          setIsOrgMedia={setIsOrgMedia}
        >
          {selectedViewChildren}
        </TabSelectedSection>
      ) : (
        <TabListingSection
          content={listingViewLabels}
          setSelectedView={setSelectedView}
        >
          {listingViewChildren}
        </TabListingSection>
      )}
    </div>
  );
}

export default MapTab;
