import { toast } from "react-toastify";
import { all, put, takeLatest, select } from "redux-saga/effects";

// APIs
import {
  getPinCodeInfo,
  getWardInfo,
  getBoundaries,
  regionsWithinBBox,
} from "../../apis/map-view/BoundariesAPI";

// Constants and Utils
import { getErrorMessage } from "../../utils/util";
import { Boundaries } from "../../constants/action-constants/map-view/BoundariesActionConstants";
import { RegionTypes } from "../../constants/GeneralConstants";

function* getBoundariesData(action) {
  try {
    const { cityId } = action.payload;
    const boundaries = yield getBoundaries(cityId);

    // TODO:: For now Remove "Ward" option from boundaries
    // Because of lesser data for "Ward" in cities
    delete boundaries[RegionTypes.Ward];

    yield put({
      type: Boundaries.GET_BOUNDARIES_SUCCESS,
      payload: boundaries,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: Boundaries.GET_BOUNDARIES_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* getWardData(action) {
  try {
    const { cityId } = action.payload;
    const existingPolygonData = yield select(
      (state) => state.boundaries.polygonData
    );
    if (existingPolygonData.ward) {
      yield put({
        type: Boundaries.GET_WARD_INFO_SUCCESS,
        payload: existingPolygonData.ward,
      });
      return;
    }

    const { polygonData } = yield getWardInfo(cityId);
    yield put({
      type: Boundaries.GET_WARD_INFO_SUCCESS,
      payload: polygonData,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: Boundaries.GET_WARD_INFO_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* getPinCodeData(action) {
  try {
    const { cityId } = action.payload;
    const existingPolygonData = yield select(
      (state) => state.boundaries.polygonData
    );
    if (existingPolygonData.pincode) {
      yield put({
        type: Boundaries.GET_PINCODE_INFO_SUCCESS,
        payload: existingPolygonData.pincode,
      });
      return;
    }
    const { polygonData } = yield getPinCodeInfo(cityId);
    yield put({
      type: Boundaries.GET_PINCODE_INFO_SUCCESS,
      payload: polygonData,
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: Boundaries.GET_PINCODE_INFO_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}

function* getRegionInfo(action) {
  try {
    const { bbox, type } = action.payload;
    const existingPolygonData = yield select(
      (state) => state.boundaries.polygonData
    );

    if (type === RegionTypes.Pincode) {
      // if we have pincode data we are not making an api call
      if (existingPolygonData.pincode) {
        yield put({
          type: Boundaries.GET_REGION_INFO_SUCCESS,
          payload: {
            polygonData: existingPolygonData.pincode,
            regionType: type,
          },
        });
        return;
      }
      const { polygonData } = yield regionsWithinBBox(bbox, type);

      yield put({
        type: Boundaries.GET_REGION_INFO_SUCCESS,
        payload: { polygonData, regionType: type },
      });
      return;
    }
    // if we have ward data we are not making an api call
    if (existingPolygonData.ward) {
      yield put({
        type: Boundaries.GET_REGION_INFO_SUCCESS,
        payload: { polygonData: existingPolygonData.ward, regionType: type },
      });
      return;
    }

    const { polygonData } = yield regionsWithinBBox(bbox, type);

    yield put({
      type: Boundaries.GET_REGION_INFO_SUCCESS,
      payload: { polygonData, regionType: type },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);

    yield put({
      type: Boundaries.GET_REGION_INFO_FAILURE,
      payload: errorMessage,
    });
    toast.error(errorMessage);
  }
}
export default function* root() {
  yield all([
    takeLatest(Boundaries.GET_BOUNDARIES, getBoundariesData),
    takeLatest(Boundaries.GET_WARD_INFO, getWardData),
    takeLatest(Boundaries.GET_PINCODE_INFO, getPinCodeData),
    takeLatest(Boundaries.GET_REGION_INFO, getRegionInfo),
  ]);
}
