import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Action
import { clearLoginInfo } from "../../actions/auth/AuthActions";
import { openPlatformUpdatesForm } from "../../actions/PlatformUpdatesFromActions";

// Constants and Utils
import {
  FormDataTargets,
  LocalStorageKeys,
  UrlKeys,
} from "../../constants/GeneralConstants";
import { getItem } from "../../utils/localstorage";
import { checkIsSellerUser } from "../../utils/SellerUtils";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";
import { useUrlKeyCheck } from "../../mavin/utils/hooks/HooksUtil";

// Page Sections
import NavbarBranding from "./NavbarBranding";
import PlatformUpdatesForm from "./PlatformUpdatesForm";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// CSS
import "./TopNav.scss";

// Page Constants
const {
  orgProfileUrl,
  orgUserListUrl,
  orgSubscriptionUrl,
  orgTargetGroupListUrl,
  orgRoadStretchListUrl,
  orgDataLayerListUrl,
  helpDefinitionPageUrl,
  orgMediaPricePageUrl,
} = RedirectTo;

/**
 * Header :: Left Navbar
 */
const NavbarLeft = (props) => {
  // incoming props
  const { isUserAuthenticated } = props;

  return (
    <>
      {isUserAuthenticated && (
        <ul className="navbar-nav">
          <li className="nav-item"></li>
        </ul>
      )}
    </>
  );
};

function Profile() {
  return (
    <Link className="dropdown-item" to={constructRedirectPath(orgProfileUrl)}>
      <i className="fas fa-address-card"></i> Profile
    </Link>
  );
}

function UserAndRoles() {
  return (
    <Link className="dropdown-item" to={constructRedirectPath(orgUserListUrl)}>
      <i className="fas fa-users"></i> Users &amp; Roles
    </Link>
  );
}

function Subscription() {
  return (
    <Link
      className="dropdown-item"
      to={constructRedirectPath(orgSubscriptionUrl)}
    >
      <i className="fas fa-crown"></i> Subscription
    </Link>
  );
}

function TargetGroup() {
  return (
    <Link
      className="dropdown-item"
      to={constructRedirectPath(orgTargetGroupListUrl)}
    >
      <i className="fas fa-user-tie"></i> Target Groups
    </Link>
  );
}

function RoadStretch() {
  return (
    <Link
      className="dropdown-item"
      to={constructRedirectPath(orgRoadStretchListUrl)}
    >
      <i className="fas fa-road"></i> Road Stretches
    </Link>
  );
}

function DataLayers() {
  return (
    <Link
      className="dropdown-item"
      to={constructRedirectPath(orgDataLayerListUrl)}
    >
      <i className="fas fa-layer-group"></i> Data Layers
    </Link>
  );
}

function MediaPrice() {
  return (
    <Link
      className="dropdown-item"
      to={constructRedirectPath(orgMediaPricePageUrl)}
    >
      <i className="fas fa-map-marker-alt"></i> Media Price
    </Link>
  );
}

function PlatformUpdates({ modalRef }) {
  const dispatch = useDispatch();
  return (
    <li className="nav-item">
      <span
        className="nav-link btn btn-link text-blink"
        data-toggle="modal"
        data-target={`#${FormDataTargets.platformUpdatesForm}`}
        onClick={() => dispatch(openPlatformUpdatesForm())}
        ref={modalRef}
      >
        <i className="fas fa-sms"></i>
        {"What's New!!!"}
      </span>
    </li>
  );
}

function PlatformHelpContent() {
  return (
    <li className="nav-item">
      <Link
        className="nav-link btn btn-link shadow-none"
        to={constructRedirectPath(helpDefinitionPageUrl)}
      >
        <i className="fas fa-question-circle" aria-hidden="true"></i>
        <span>{"Help"}</span>
      </Link>
    </li>
  );
}

function OrgSettings({ isSellerUser }) {
  return (
    <li className="nav-item dropdown">
      <button
        id="dropdown-settings"
        className="nav-link btn btn-link shadow-none dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <i className="fas fa-cogs" aria-hidden="true"></i>
        <span>{"Settings"}</span>
      </button>
      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdown-settings"
      >
        <Profile />
        <UserAndRoles />
        <Subscription />

        {/* Hiding in case of "Seller-Login" */}
        {!isSellerUser && (
          <>
            <hr className="my-2" />
            <TargetGroup />
            <RoadStretch />
            <DataLayers />

            {/* TODO:: uncomment when it is functional */}
            {/* <hr className="my-2" /> */}
            {/* <MediaPrice /> */}
          </>
        )}
      </div>
    </li>
  );
}

function UserProfile({ firstName }) {
  const dispatch = useDispatch();

  // Private Methods
  // --------------------------------------------------------------------------
  const _logout = () => {
    localStorage.clear();
    dispatch(clearLoginInfo());
  };
  return (
    <li className="nav-item dropdown">
      <button
        id="dropdown-profile"
        className="nav-link btn btn-link shadow-none dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <i className="fas fa-user-circle" aria-hidden="true"></i>
        <span>{firstName}</span>
      </button>
      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdown-profile"
      >
        <button className="dropdown-item" onClick={() => _logout()}>
          {"Sign Out"}
        </button>
      </div>
    </li>
  );
}

/**
 * Header :: Right Navbar
 */
const NavbarRight = (props) => {
  // incoming props
  const { user, isUserAuthenticated, modalRef } = props;
  const { firstName } = user;
  // const OrgTypeUserAndRoles = withOrgType(UserAndRoles);

  // since we have to hide "platform-updates-modal" on "Seller-Login"
  // Checking the USER is Seller or not
  const isSellerUser = checkIsSellerUser();

  // checking is Mantaray/Toohl Org
  const { toohlSpecific } = UrlKeys;
  const isToohlSpecific = useUrlKeyCheck(toohlSpecific);

  const isShowPlatformUpdatesModal = !isToohlSpecific;

  if (!isUserAuthenticated) {
    return null;
  }

  return (
    <ul className="navbar-nav ml-auto">
      {/* platform updates form :: Whats New!! */}
      {/* TODO:: Uncomment/comment it when required/not-required  */}
      {isShowPlatformUpdatesModal && <PlatformUpdates modalRef={modalRef} />}

      {/* Help */}
      <PlatformHelpContent />

      {/** Org Settings */}
      <OrgSettings isSellerUser={isSellerUser} />

      {/** Profile */}
      <UserProfile firstName={firstName} />
    </ul>
  );
};

export default function TopNav({ user, isUserAuthenticated }) {
  const modalRef = useRef();
  // Platform Updates Form
  const openPlatformUpdatesModal = useSelector(
    (state) => state.platformUpdatesModal.openModal
  );

  // getting key from "localStorage" (true --> after successful login)
  // JSON.parse(string "true") => boolean true
  const isUserFirstVisit = JSON.parse(
    getItem(LocalStorageKeys.PLATFORM_UPDATES_MODAL)
  );

  useEffect(() => {
    // Triggering the "onClick" event to open "PlatformUpdatesForm" just after "Successful Login"
    if (isUserFirstVisit) {
      modalRef.current?.click();
    }
  }, []);

  return (
    <>
      <nav className="main-header navbar navbar-expand">
        <NavbarBranding />

        <NavbarLeft user={user} isUserAuthenticated={isUserAuthenticated} />
        <NavbarRight
          user={user}
          isUserAuthenticated={isUserAuthenticated}
          modalRef={modalRef}
        />
      </nav>

      {/* MODALS */}
      {/* any of these two is "true", then open Modal */}
      {(isUserFirstVisit || openPlatformUpdatesModal) && (
        <PlatformUpdatesForm />
      )}
    </>
  );
}
