import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getCustomDataLayers,
  getDataLayerDetails,
} from "../../../actions/org/OrgDataLayerActions";
import {
  selectDataLayer,
  unselectDataLayer,
} from "../../../actions/map-view/DataLayerSelectionActions";

// Constants
import { defaultPagination } from "../../../constants/UrlConstants";

// Components
import Spinner from "../../../components/spinner/Spinner";
import SearchInputLocal from "../../../components/search-input-local/SearchInputLocal";

// Section Components
function DataLayersList({ id, name, dataPointCountsByCity, cityId }) {
  const dataPointsCount = dataPointCountsByCity[cityId] || 0;

  // Selector
  const isLayerSelected = useSelector(
    (state) => state.dataLayersSelection.isLayerSelected[id]
  );

  const isSelectionLoading = useSelector(
    (state) => state.dataLayersSelection.isSelectionLoading[id]
  );

  // Dispatch
  const dispatch = useDispatch();

  function selectDataLayerFn(id) {
    if (!isLayerSelected) {
      dispatch(getDataLayerDetails(id));
    }
    dispatch(dispatchFunction(id));
  }

  // Add and Remove button config
  const dispatchFunction = isLayerSelected
    ? unselectDataLayer
    : selectDataLayer;

  const buttonLabel = isLayerSelected ? "Added" : "Add";

  const buttonIconClassName = isLayerSelected ? "fa fa-check" : "";

  const buttonClassName = isLayerSelected
    ? "btn-outline-success"
    : "btn-outline-primary";

  return (
    <div className="navbar px-0 py-2 border-bottom">
      <label className="form-check-label ml-2" htmlFor={name}>
        {name}
      </label>
      <div>
        {isSelectionLoading && <Spinner className="spinner-grow-sm mr-2" />}
        <span className="text-muted font-italic px-2">{`Total Points:${dataPointsCount}`}</span>
        <button
          disabled={isSelectionLoading || dataPointsCount === 0}
          className={`btn ${buttonClassName} add-button shadow-none`}
          onClick={() => selectDataLayerFn(id)}
        >
          <i className={buttonIconClassName}></i> {buttonLabel}{" "}
        </button>
      </div>
    </div>
  );
}

/**
 * Data Layer Listing Section
 */
export default function DataLayersListingSection({
  selectedViewLabels,
  cityId,
}) {
  // State
  const [dataLayers, setDataLayers] = useState([]);

  // Selector- State
  const dataLayersLoading = useSelector(
    (state) => state.orgDataLayers.dataLayersLoading
  );
  // DataLayers List
  const dataLayersList = useSelector((state) => state.orgDataLayers.dataLayers);
  const pageNumber = defaultPagination.pageNumber,
    pageSize = defaultPagination.pageSize;

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    // TODO : we need to fetch CustomDataLayers for the city only
    if (dataLayersList.length < 1) {
      dispatch(getCustomDataLayers(false, "", pageNumber, pageSize));
    }
  }, [dispatch]);

  // Search Labels
  const { search } = selectedViewLabels;

  // Loading
  if (dataLayersLoading) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {/* Search datalayers */}
      <SearchInputLocal
        placeholder={search.placeholder}
        dataList={dataLayersList}
        searchFields={search.fields}
        onSearchComplete={setDataLayers}
      />
      <hr className="my-2" />

      {/* Data-Layers Listing */}
      {dataLayers.map((dataLayer) => (
        <DataLayersList
          key={dataLayer.id}
          id={dataLayer.id}
          name={dataLayer.name}
          dataPointCountsByCity={dataLayer.dataPointCountsByCity}
          cityId={cityId}
        />
      ))}

      {/* If there is no data layer found */}
      {dataLayersList.length < 1 && (
        <p className="text-center mt-3 font-italic">
          {"No Data layers are available"}
        </p>
      )}
    </>
  );
}
