import { ActionTypes } from "../../constants/ActionConstants";
import { CampaignMedia } from "../../constants/action-constants/CampaignMediaActionConstants";

// TODO : Add documentation..
function processCampaignMedia(data) {
  const mediaList = data;
  let mediaCountWithoutLocation = 0;
  mediaList.items.forEach((media) => {
    const otsSummary = media.otsSummary || {};
    const ltsSummary = media.ltsSummary || {};
    const lts = ltsSummary.lts ? (ltsSummary.lts * 5).toFixed(2) + "/5" : "";
    const otsChangePercentage =
      otsSummary.ots && otsSummary.otsChangePercentage
        ? Math.floor(otsSummary.otsChangePercentage) + "%"
        : "";
    const covidImpressions =
      otsSummary.ots && otsSummary.otsChangePercentage
        ? Math.floor(
            (otsSummary.ots *
              (100 - Math.abs(Math.floor(otsSummary.otsChangePercentage)))) /
              100
          ).toLocaleString("en-IN")
        : "";

    media.position = [media.latitude, media.longitude];
    media.markerText = media.position.toString();
    media.lts = lts;
    media.otsChangePercentage = otsChangePercentage;
    media.covidImpressions = covidImpressions;
    media.ots = otsSummary.ots?.toLocaleString("en-IN");
    if (!(media.otsSummary && media.otsSummary.generatedOn)) {
      mediaCountWithoutLocation = mediaCountWithoutLocation + 1;
    }
  });
  return mediaCountWithoutLocation;
}

//intital state
const initialState = {
  // These below variables are used to store the state of current individual

  // CampaignMedia creation
  loading: false,
  campaignMediaErr: "",
  campaignMediaId: "",
  createMediaSuccess: false,

  // Open and close create media model
  openModal: false,

  // Generate mavin metrics
  generateMavinMetricsLoader: false,
  error: "",

  //get media details
  mediaDetailsLoading: false,
  media: {},
  fileItems: [],
  roadSegmentTrafficInfo: {},

  //upload file
  fileItemsLoading: false,
  fileItemsError: "",

  //location summary
  kypDataLoading: false,

  // update media duration
  mediaDurationChanging: false,
  mediaDurationChangeError: "",

  //city summary
  citySummary: {},
  err: "",
  cityLoading: false,

  // state related to mapping SellerMedia to campaign"
  mappedSellerMedia: [],
  mapSellerMediaLoading: false,
  mapSellerMediaErr: "",

  // Remove Media From Campaign States
  removeMediaLoading: false,
  removeMediaFailure: "",
  removeMediaSuccess: false,

  //campaignMedia of campaign
  campaignMediaLoading: false,
  getCampaignMediaErr: "",
  campaignMedia: [],

  // Campaign Media Pdf Report
  downloadingReport: false,
  mediaPdfReportErr: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    // Create campaign media
    //--------------------------------------------------------------------------------
    case CampaignMedia.CAMPAIGN_MEDIA_CREATE:
      return {
        ...state,
        loading: true,
      };
    case CampaignMedia.CAMPAIGN_MEDIA_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignMediaId: action.payload,
        createMediaSuccess: true,
      };
    case CampaignMedia.CAMPAIGN_MEDIA_CREATE_FAILURE:
      return {
        ...state,
        campaignMediaErr: action.payload,
        loading: false,
      };

    // Open and close create media model
    //--------------------------------------------------------------------------------
    case CampaignMedia.OPEN_CREATE_MEDIA_MODAL:
      return {
        ...state,
        openModal: true,
      };
    case CampaignMedia.CLOSE_CREATE_MEDIA_MODAL:
      return {
        ...state,
        openModal: false,
      };

    // Generate mavin metrics
    //--------------------------------------------------------------------------------
    case CampaignMedia.GENERATE_MAVIN_METRICS:
      return {
        ...state,
        generateMavinMetricsLoader: true,
      };
    case CampaignMedia.GENERATE_MAVIN_METRICS_SUCCESS:
      return {
        ...state,
        generateMavinMetricsLoader: false,
        isSuccess: true,
      };
    case CampaignMedia.GENERATE_MAVIN_METRICS_FAILURE:
      return {
        ...state,
        generateMavinMetricsLoader: false,
        error: action.payload,
      };

    //Campaign city view
    //--------------------------------------------------------------------------------
    case CampaignMedia.CAMPAIGN_CITYVIEW:
      return {
        ...state,
        cityLoading: true,
      };
    case CampaignMedia.CAMPAIGN_CITYVIEW_SUCCESS: {
      const mediaCountWithoutLocation = processCampaignMedia(
        action.data.result
      );
      return {
        ...state,
        citySummary: action.data,
        cityLoading: false,
        mediaCountWithoutLocation,
      };
    }

    case CampaignMedia.CAMPAIGN_CITYVIEW_FAILURE:
      return {
        ...state,
        err: action.payload,
        cityLoading: false,
      };

    //Get media details
    //--------------------------------------------------------------------------------

    case CampaignMedia.GET_MEDIA_BY_ID:
      return {
        ...state,
        mediaDetailsLoading: true,
      };

    case CampaignMedia.GET_MEDIA_BY_ID_SUCCESS: {
      const { roadSegmentTrafficInfo, mediaDetails } = action.payload;
      return {
        ...state,
        media: action.payload,
        fileItems: mediaDetails.fileItems,
        roadSegmentTrafficInfo,
        mediaDetailsLoading: false,
      };
    }

    case CampaignMedia.GET_MEDIA_BY_ID_FAILURE:
      return {
        ...state,
        error: action.payload,
        mediaDetailsLoading: false,
      };

    // File upload
    //--------------------------------------------------------------------------------
    case CampaignMedia.SET_FILE_ITEMS_LOADING:
      return {
        ...state,
        fileItemsLoading: true,
      };

    case CampaignMedia.UPLOAD_FILE_ITEMS_SUCCESS: {
      const fileItems = action.payload;
      return {
        ...state,
        fileItems,
        fileItemsLoading: false,
        fileItemsError: "",
      };
    }

    case CampaignMedia.UPLOAD_FILE_ITEMS_FAILURE:
      return {
        ...state,
        fileItemsLoading: false,
        fileItemsError: action.payload,
      };

    // update media duration
    //--------------------------------------------------------------------------------
    case CampaignMedia.UPDATE_CAMPAIGN_MEDIA_DURATION:
      return {
        ...state,
        mediaDurationChanging: true,
      };

    case CampaignMedia.UPDATE_CAMPAIGN_MEDIA_DURATION_SUCCESS:
      return {
        ...state,
        mediaDurationChanging: false,
      };

    case CampaignMedia.UPDATE_CAMPAIGN_MEDIA_DURATION_FAILURE:
      return {
        ...state,
        mediaDurationChanging: false,
        mediaDurationChangeError: action.payload,
      };

    case CampaignMedia.ADD_CAMPAIGN_MEDIA:
      return {
        ...state,
        mapSellerMediaLoading: true,
      };
    case CampaignMedia.ADD_CAMPAIGN_MEDIA_SUCCESS:
      return {
        ...state,
        mapSellerMediaLoading: false,
        mappedSellerMedia: action.payload.result,
      };

    case CampaignMedia.ADD_CAMPAIGN_MEDIA_FAILURE:
      return {
        ...state,
        mapSellerMediaErr: action.payload,
        mapSellerMediaLoading: false,
      };

    case CampaignMedia.REMOVE_CAMPAIGN_MEDIA:
      return {
        ...state,
        removeMediaLoading: true,
      };
    case CampaignMedia.REMOVE_CAMPAIGN_MEDIA_SUCCESS:
      return {
        ...state,
        removeMediaLoading: false,
      };

    case CampaignMedia.REMOVE_CAMPAIGN_MEDIA_FAILURE:
      return {
        ...state,
        removeMediaFailure: action.payload,
        removeMediaLoading: false,
        removeMediaSuccess: true,
      };

    case ActionTypes.Media.GET_CAMPAIGN_MEDIA:
      return {
        ...state,
        campaignMediaLoading: true,
      };

    case ActionTypes.Media.GET_CAMPAIGN_MEDIA_SUCCESS:
      return {
        ...state,
        campaignMediaLoading: false,
        campaignMedia: action.payload.campaignMediaInfo.items,
      };

    case ActionTypes.Media.GET_CAMPAIGN_MEDIA_FAILURE:
      return {
        ...state,
        campaignMediaLoading: false,
        getCampaignMediaErr: action.payload,
      };

    // Download Report PDF
    //-------------------------------------------------------------------------------------
    case CampaignMedia.DOWNLOAD_MEDIA_PDF_REPORT:
      return {
        ...state,
        downloadingReport: true,
      };

    case CampaignMedia.DOWNLOAD_MEDIA_PDF_REPORT_SUCCESS:
      return {
        ...state,
        downloadingReport: false,
      };

    case CampaignMedia.DOWNLOAD_MEDIA_PDF_REPORT_FAILURE:
      return {
        ...state,
        downloadingReport: false,
        mediaPdfReportErr: action.payload,
      };

    default:
      return state;
  }
};
