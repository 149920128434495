import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// Actions
import {
  downloadTemplate,
  resetSiteUploadState,
  uploadTemplate,
} from "../../../actions/SiteUploadActions";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Constants and Utils
import { selectFile } from "../../../common-utils/file-utils/FileUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Components
import DownloadTemplateCard from "../../../components/site-upload-utils/DownloadTemplateCard";
import UploadTemplateCard from "../../../components/site-upload-utils/UploadTemplateCard";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Page Constants
const planningSiteUpload = {
  DownloadTemplateData: {
    step: "STEP 1",
    title: "Download and fill site template",
    message: "If you already have a filled site template jump to step 2.",
    buttonLabel: "Download Template",
  },
  UploadSiteListData: {
    step: "STEP 2",
    title: "Upload site list",
    message:
      "Please upload using the template format recommended to avoid errors.",
    buttonLabel: "Upload Site List",
  },
};

// Page Components
function FooterSection({ mediaCount, linkTo }) {
  const history = useHistory();

  // if user upload single site then "site" else "sites"
  const isSingleSiteText = mediaCount === 1 ? "site" : "sites";

  // Functions
  function onContinuePlanClick() {
    history.push(constructRedirectPath(linkTo));
    toast.success(
      `${mediaCount} media ${isSingleSiteText} has been added to campaign.`
    );
  }

  return (
    <div className="d-flex justify-content-end m-5">
      <Link to={constructRedirectPath(linkTo)}>
        <button className="btn btn-outline-primary mx-4" disabled={mediaCount}>
          {"Cancel"}
        </button>
      </Link>
      <button
        className="btn btn-primary"
        disabled={!mediaCount}
        onClick={onContinuePlanClick}
      >
        {"Continue Planning"}
      </button>
    </div>
  );
}

function CampaignPlanUploadPage() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  // uploaded mediaCount
  const mediaCount = useSelector((state) => state.c_siteUpload.mediaCount);
  const templateDownloading = useSelector(
    (state) => state.c_siteUpload.downloadLoading
  );

  // downloaded template fileName and link to Redirect on Summary-page
  const fileName = "campaign_plan_excel_template.xlsx";
  const campaignPlanOverviewPageUrl =
    RedirectTo.campaignPlanOverviewPageUrl.replace(":campaignId", campaignId);

  // object for reducerKey and selectorValues to pass in "uploadTemplate"
  const reducerKeyValuesObj = {
    reducerKey: "c_siteUpload",
    selectorValue: {
      loading: "uploadLoading",
      error: "uploadError",
      mediaCount: "mediaCount",
    },
  };

  // Dispatch
  useEffect(() => {
    dispatch(resetSiteUploadState());
  }, [dispatch]);

  // this function handles file-uploads
  async function uploadTemplateFunction() {
    const files = await selectFile();
    dispatch(uploadTemplate(campaignId, files));
  }

  return (
    <div className="content-wrapper site-upload bg-alt h-100">
      {/* Page Header Section */}
      <PageHeader title={"Upload media sites via excel"} shadow={false} />

      <div className="page-content">
        {/* for downloading template of excel file */}
        <DownloadTemplateCard
          isDisable={templateDownloading}
          onClick={() => dispatch(downloadTemplate(fileName))}
          downloadTemplateData={planningSiteUpload.DownloadTemplateData}
        />

        {/* for excel-file template upload */}
        <UploadTemplateCard
          uploadSiteListData={planningSiteUpload.UploadSiteListData}
          reducerKeyValuesObj={reducerKeyValuesObj}
          uploadTemplateFunction={uploadTemplateFunction}
        />

        {/* Footer Section */}
        <FooterSection
          mediaCount={mediaCount}
          linkTo={campaignPlanOverviewPageUrl}
        />
      </div>
    </div>
  );
}

export default CampaignPlanUploadPage;
