import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Action
import { openCreateCampaignMediaForm } from "../../../actions/campaign-media/CampaignMediaActions";

// Constants and Utils
import {
  FormDataTargets,
  PageSize,
  UrlKeys,
} from "../../../constants/GeneralConstants";
import { useUrlKeyCheck } from "../../../mavin/utils/hooks/HooksUtil";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Components
import { Dropdown } from "../../../components/dropdown/Dropdown";
import Spinner from "../../../components/spinner/Spinner";
import ApproxMavinMetrics from "./ApproxMavinMetrics";
import GenerateMavinMetrics from "./GenerateMavinMetrics";
import Filter from "../../../components/filter/Filter";
import AlertMessage from "../../../mavin/components/alert-message/AlertMessage";

// Create or Upload Media Dropdown
function CreateOrUploadMediaDropdown({ routeChange }) {
  const dispatch = useDispatch();

  // functions
  function openCreateCampaignMediaFormFunction() {
    dispatch(openCreateCampaignMediaForm());
  }

  return (
    <Dropdown buttonName="Upload more sites" style={"mt-0"}>
      {/* Upload excel */}
      <div className="dropdown-menu dropdown-menu-right">
        <button className="dropdown-item" onClick={routeChange}>
          Excel file
        </button>

        {/* To open create media form  */}
        <button
          className="dropdown-item"
          data-toggle="modal"
          data-target={`#${FormDataTargets.campaignMediaForm}`}
          onClick={openCreateCampaignMediaFormFunction}
        >
          Create new media site
        </button>
      </div>
    </Dropdown>
  );
}

// City View Site Options
function CityViewSiteOptions({ loadCityMedia, targetGroupId }) {
  const history = useHistory();
  const { id, cityId } = useParams();

  //Selector
  // update campaignMediaDuration
  const campaignMediaDurationChanging = useSelector(
    (state) => state.campaignMedia.mediaDurationChanging
  );
  const cmcIsLoading = useSelector((state) => state.campaignMedia.loading);

  // checking for "Mantaray org"
  const isMantaraySpecific = useUrlKeyCheck(UrlKeys.mantaraySpecific);

  // route-change on excel-upload
  const routeChange = () => {
    history.push({
      pathname: constructRedirectPath("/site-upload"),
      state: { campaignId: id, cityId: cityId, targetGroupId },
    });
  };

  // Checks for Campaign Media Duration Changing
  if (campaignMediaDurationChanging) {
    return (
      <div className="d-flex">
        <AlertMessage
          className="py-2 mt-5 mb-0"
          spinnerClassName="spinner-border-sm"
          textMessage="Updating Metrics"
        />
      </div>
    );
  }

  return (
    <div className="mt-5 navbar px-0">
      <div className="d-flex align-items-center">
        <h4 className="site-details">{"Site Details"}</h4>
        {/* Approx Mavin Metrics */}
        <ApproxMavinMetrics campaignId={id} cityId={cityId} />
      </div>

      <div className="d-flex align-items-center">
        {/* checks for create media loading */}
        {cmcIsLoading && <Spinner />}

        <GenerateMavinMetrics campaignId={id} cityId={cityId} />

        {/* Upload Site Dropdown */}
        {!isMantaraySpecific && (
          <CreateOrUploadMediaDropdown routeChange={routeChange} />
        )}

        {/* Media Type Filter */}
        <Filter
          onFiltersApplied={loadCityMedia}
          pageSize={PageSize.CitySummary}
        />
      </div>
    </div>
  );
}

export default CityViewSiteOptions;
