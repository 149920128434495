import { useEffect } from "react";
import { useState } from "react";
import { Marker, Polyline } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  clearSegmentOtsInfo,
  getSegmentOtsInfo,
} from "../../actions/mavin-tools/OtsToolActions";
import { getClosestRoadSegments } from "../../actions/org/OrgRoadStretchActions";
import { openTargetGroupForm } from "../../prooh/actions/campaign-planning/TargetGroupFormActions";

// Utils and Constants
import {
  India,
  MapZoom,
  UrlKeys,
  FormDataTargets,
} from "../../constants/GeneralConstants";
import {
  getCoordinates,
  isValidCoordinate,
} from "../../common-utils/geo-util/coordinatesUtil";
import {
  SelectedRouteStyle,
  UnSelectedRouteStyle,
} from "../../constants/CssConstants";
import { getPoiDistribution } from "../../mavin/components/location-summary/LocationSummaryUtil";

// Hooks
import { useUrlKeyCheck } from "../../mavin/utils/hooks/HooksUtil";

// Components
import { ButtonWithLoader } from "../../mavin/components/button/Button";
import PinDropLocationAndZoom from "../../components/map/pin-drop-select/PinDropLocationAndZoom";
import PlacesAutocomplete from "../../components/places-autocomplete/PlacesAutocomplete";
import LLMap from "../../components/map/leaflet-map/LLMap";
import MapIcon from "../../components/map/map-icon/MapIcon";
import LocationSummaryPieChart from "../../mavin/components/location-summary/LocationSummaryPieChart";
import Population from "../../mavin/components/location-summary/Population";
import SocioEconomicIndicators from "../../mavin/components/location-summary/SocioEconomicIndicators";
import Landmarks from "../../mavin/components/location-summary/Landmarks";
import AlertMessage from "../../mavin/components/alert-message/AlertMessage";
import Spinner from "../../components/spinner/Spinner";

// Section Components
export function RouteIdSection({ id }) {
  return (
    <div className="col-5 px-0">
      <b className="d-block">{"Route ID:"}</b>
      <span>{id}</span>
    </div>
  );
}

function IsOneWaySection({ isOneWaySegment, setIsOneWaySegment }) {
  return (
    <div className="col-2">
      <b className="d-block">{"One Way?"}</b>
      <input
        type="radio"
        id="yes"
        checked={isOneWaySegment}
        onChange={() => setIsOneWaySegment(true)}
      />
      <label htmlFor="yes" className="ml-1 mr-3">
        {"Yes"}
      </label>
      <input
        type="radio"
        id="no"
        checked={!isOneWaySegment}
        onChange={() => setIsOneWaySegment(false)}
      />
      <label htmlFor="no" className="ml-1">
        {"No"}
      </label>
    </div>
  );
}

function LanesCountSection({ lanes, setLanesCount }) {
  return (
    <div className="col-3 input-group-sm">
      <b className="d-block">{"No. of Lanes"}</b>
      <input
        type="text"
        className="form-control shadow-none rounded-lg w-75"
        placeholder={`${lanes} Lanes`}
        onChange={(e) => setLanesCount(e.target.value)}
      ></input>
    </div>
  );
}

/**
 * @param {*} selectedSegment
 * @returns Selected Segements Information {id, isOneWay, lanes}
 */
export function SelectedSegmentInfoSection({
  selectedSegment,
  isTrafficDataAvailable,
}) {
  const dispatch = useDispatch();
  const { id, isOneWay, lanes, type } = selectedSegment;
  const [isOneWaySegment, setIsOneWaySegment] = useState(isOneWay);
  const [lanesCount, setLanesCount] = useState(lanes);

  // Selector State
  const segmentInfoLoading = useSelector(
    (state) => state.otsTool.segmentInfoLoading
  );

  // Functions
  function getOts() {
    dispatch(clearSegmentOtsInfo());
    dispatch(getSegmentOtsInfo(id, isOneWaySegment, lanesCount, type));
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center py-3">
        {/* Route ID */}
        <RouteIdSection id={id} />

        {/* Is One Way */}
        <IsOneWaySection
          isOneWaySegment={isOneWaySegment}
          setIsOneWaySegment={setIsOneWaySegment}
        />

        {/* LanesSection */}
        <LanesCountSection lanes={lanes} setLanesCount={setLanesCount} />

        {/* Submit Request Button */}
        <ButtonWithLoader
          displayContent={"Get OTS"}
          onClickFunction={getOts}
          loader={segmentInfoLoading}
          isDisabled={!isTrafficDataAvailable}
        />
      </div>
      <hr className="divider my-0 row"></hr>
    </>
  );
}

export function NoDataMessageSection({ messageText }) {
  return (
    <div className="text-center p-3">
      <p className="font-italic">{messageText}</p>
    </div>
  );
}

/**
 * @param {*} selectedSegment
 * @returns TrafficDataAvailable : true/false
 */
export function getIsTrafficDataAvailable(selectedSegment) {
  const {
    avgSpeedProfile = {},
    maxSpeedProfile = {},
    minSpeedProfile = {},
  } = selectedSegment;
  const avgSpeedProfileLength = Object.keys(avgSpeedProfile);
  const maxSpeedProfileLength = Object.keys(maxSpeedProfile);
  const minSpeedProfileLength = Object.keys(minSpeedProfile);

  if (
    !avgSpeedProfileLength &&
    !maxSpeedProfileLength &&
    !minSpeedProfileLength
  ) {
    return false;
  }
  return true;
}

export function ApiDataErrorMessage({
  className = "",
  errorMessage,
  customMessage,
}) {
  const finalClassName = className ? className : "p-2 text-center";

  return (
    <div className={`bg-alt border ${finalClassName}`}>
      {errorMessage && <span className="d-block">{errorMessage}</span>}
      {customMessage && (
        <span className="d-block font-weight-bold">{customMessage}</span>
      )}
    </div>
  );
}

// Demographic Summary Component (vertically-aligned) ==> chart + summary
// we are using this component at multiple places
export function DemographicSummaryComponent({
  fitWithinClassName = "col-12", // this is used to fit component in "col-6" or in full-width
  locationTitle = "",
  demographicData,
}) {
  //Checks for data
  if (!demographicData) {
    return (
      <AlertMessage
        className="justify-content-center p-3"
        textMessage="Location Summary is not available for this Site."
        isLoading={false}
      />
    );
  }

  const {
    poiCounts = {},
    population,
    sustenance,
    realtySale,
    nearByPois = {},
  } = demographicData;

  const poiDistribution = getPoiDistribution(poiCounts);

  return (
    <div className={fitWithinClassName}>
      {locationTitle && <h4>{locationTitle}</h4>}

      {/* Pie-chart */}
      <LocationSummaryPieChart poiDistribution={poiDistribution} />
      <hr className="divider"></hr>

      {/* Population details */}
      <Population population={population} />
      <hr className="divider"></hr>

      <div className="row">
        {/* Social Economic details */}
        <SocioEconomicIndicators
          className="col-6"
          sustenance={sustenance}
          realtySale={realtySale}
        />

        {/* Land Mark details */}
        <Landmarks className="col-6" nearByPois={nearByPois} />
      </div>
    </div>
  );
}

// Map Component
export function SiteMapViewSection({
  title,
  setLocationStr,
  locationStr,
  coordinate,
  setCoordinate,
}) {
  // State
  const [mapZoom, setMapZoom] = useState(null);

  const center = coordinate.length > 1 ? coordinate : India.mapCenter; // center
  const zoom = getMapZoomLevel(coordinate, mapZoom, India.mapZoomLevel4); // zoom
  const icon = new MapIcon({ type: "selected-poi" }); // icon

  // This call-back function is used to pick the lat-lng
  function getCoordinatesFromPinDrop({ lat, lng }) {
    const coordinateString = `${lat},${lng}`;
    setLocationStr(coordinateString);
    setCoordinate(getCoordinates(coordinateString));
  }

  function getCoordinatesFromAutoComplete(locationStr) {
    setLocationStr(locationStr);
    if (isValidCoordinate(locationStr)) {
      setCoordinate(getCoordinates(locationStr));
    }
  }
  return (
    <div className="col-6">
      {title && <h4>{title}</h4>}
      {/* Location search */}
      <PlacesAutocomplete
        className={"rounded-lg shadow-none autocomplete-map col-4 m-2"}
        placeholder={"Search location OR enter Lat Lng"}
        locationStr={locationStr}
        setLocationStr={getCoordinatesFromAutoComplete}
      />

      <LLMap center={center} zoom={zoom}>
        {/* Pindrop */}
        <PinDropLocationAndZoom
          onLocationSelect={getCoordinatesFromPinDrop}
          mapZoom={mapZoom}
          setMapZoom={setMapZoom}
        />

        {/* Marker */}
        {coordinate.length > 1 && <Marker position={coordinate} icon={icon} />}
      </LLMap>
    </div>
  );
}

function getRouteStyle(eachSegmentId, selectedSegment) {
  if (eachSegmentId === selectedSegment.id) {
    return SelectedRouteStyle;
  }
  return UnSelectedRouteStyle;
}

/**
 * this function returns the Final MAP-ZOOM level
 * @param {*} coordinate :: from AutoComplete
 * @param {*} mapZoom :: from the PIN-DROP-SELECT
 * @param {*} indiaZoomLevel :: india-zoom-level
 * @returns Zoom level
 */
export function getMapZoomLevel(coordinate, mapZoom, indiaZoomLevel) {
  // if user want different "india-zoom-level" (default : India.mapZoomLevel5)
  const indiaZoom = indiaZoomLevel ? indiaZoomLevel : India.mapZoomLevel5;

  // if there is NO Coordinates then India-ZoomLevel
  if (coordinate.length === 0) {
    return indiaZoom;
  }

  // first time searching condition from AutoComplete : where we have "Coordinates"
  // But NO "Pindrop-Zoom".
  if (coordinate.length && (!mapZoom || mapZoom === indiaZoom)) {
    return MapZoom.zoomLevel12;
  }

  // final "PinDrop-Zoom" level
  return mapZoom;
}

/**
 * Common MapView for "Ots-Tool" and "Reach&FrequencyTool"
 */
export function MapViewWithPindropAndRoadSelect({
  coordinate,
  selectedSegment,
  getCoordinatesFromPinDrop,
  onPolylineClick,
}) {
  // State
  const [mapZoom, setMapZoom] = useState(null);

  // Selector State
  // Fetch the roadSegments from the reducer
  const roadSegments =
    useSelector((state) =>
      Object.values(state.orgRoadStretch.roadSegmentsMap)
    ) || [];

  // Center
  const center = coordinate.length ? coordinate : India.mapCenter;
  const zoom = getMapZoomLevel(coordinate, mapZoom);
  const icon = new MapIcon({ type: "selected-poi" });

  return (
    <div className="map-layout-right bg-alt">
      <LLMap center={center} zoom={zoom}>
        {coordinate.length > 0 && <Marker position={coordinate} icon={icon} />}
        <PinDropLocationAndZoom
          onLocationSelect={getCoordinatesFromPinDrop}
          mapZoom={mapZoom}
          setMapZoom={setMapZoom}
        />
        {roadSegments.length > 0 &&
          roadSegments.map((eachSegment, i) => (
            <Polyline
              key={i}
              pathOptions={getRouteStyle(eachSegment.id, selectedSegment)}
              positions={eachSegment.trace}
              eventHandlers={{
                click: (e) => onPolylineClick(e, eachSegment),
              }}
              clica
            />
          ))}
      </LLMap>
    </div>
  );
}

// AutoComplete LocationSearch Input (OtsTool and ReachAndFrequencyTool and in campaignMapView for mantaray)
export function LocationSearchInput({
  locationStr,
  setLocationStr,
  setCoordinate,
  center,
}) {
  // Dispatch
  const dispatch = useDispatch();
  const { map, cityAnalyser } = UrlKeys;

  // dispatching an action to get closest road segments if it is not from below urls
  const canGetClosestRoadSegments = useUrlKeyCheck(map, cityAnalyser);

  useEffect(() => {
    if (!locationStr) {
      setCoordinate([]);
    }
  }, [locationStr]);

  // functions
  function getCoordinatesFromAutoComplete(locationStr) {
    setLocationStr(locationStr);
    if (isValidCoordinate(locationStr)) {
      setCoordinate(getCoordinates(locationStr));

      if (!canGetClosestRoadSegments) {
        dispatch(getClosestRoadSegments(locationStr, true));
      }
    }
  }

  return (
    <PlacesAutocomplete
      className="shadow-none rounded-lg"
      placeholder="Search location OR enter Lat Lng (autocomplete)"
      setLocationStr={getCoordinatesFromAutoComplete}
      locationStr={locationStr}
      center={center}
    />
  );
}

// select tg button (with api state)
export function SelectTgButton() {
  const dispatch = useDispatch();

  // selected target group
  const selectedTgName = useSelector((state) => state.orgTargetGroup.tgName);
  const tgInfoLoading = useSelector(
    (state) => state.orgTargetGroup.tgInfoLoading
  );

  return (
    <div className="d-flex justify-content-center">
      <button
        type="button"
        className="btn border rounded-lg shadow-none dropdown-toggle"
        data-toggle="modal"
        data-target={`#${FormDataTargets.targetGroupForm}`}
        onClick={() => dispatch(openTargetGroupForm())}
      >
        <span className="pr-1">
          {selectedTgName ? selectedTgName : "Select TG"}
        </span>
      </button>
      {tgInfoLoading && <Spinner className="m-2 spinner-border-sm" />}
    </div>
  );
}

// select tg button (with local state)
export function SelectTargetGroup({ selectedTgId }) {
  const dispatch = useDispatch();

  const tgList = useSelector((state) => state.orgTargetGroup.tgTitles);
  const selectedTg = tgList.find((tg) => tg.id === selectedTgId);
  const selectedTgName = selectedTg ? selectedTg.label : null;

  return (
    <button
      type="button"
      className="btn border rounded-lg shadow-none dropdown-toggle"
      data-toggle="modal"
      data-target={`#${FormDataTargets.targetGroupForm}`}
      onClick={() => dispatch(openTargetGroupForm())}
    >
      <span className="pr-1">
        {selectedTgName ? selectedTgName : "Select TG"}
      </span>
    </button>
  );
}
