/**
 * top 40 cities(hardcoded) for "City-Selection-Forms"
 */
export const topCitiesNames = [
  { id: "city-delhi-ncr", label: "Delhi" },
  { id: "city-greater-mumbai", label: "Mumbai" },
  { id: "city-chennai", label: "Chennai" },
  { id: "city-kolkata", label: "Kolkata" },
  { id: "city-bangalore", label: "Bangalore" },
  { id: "city-hyderabad", label: "Hyderabad" },
  { id: "city-pune", label: "Pune" },
  { id: "city-ahmedabad", label: "Ahmedabad" },
  { id: "city-jaipur", label: "Jaipur" },
  { id: "city-lucknow", label: "Lucknow" },
  { id: "city-chandigarh", label: "Chandigarh" },
  { id: "city-goa", label: "Goa" }, //"Ghaziabad" replacing with "Goa"(we dont have this city in Region api)
  { id: "city-cochin", label: "Cochin" },
  { id: "city-kanpur", label: "Kanpur" },
  { id: "city-surat", label: "Surat" },
  { id: "city-nagpur", label: "Nagpur" },
  { id: "city-bhopal", label: "Bhopal" },
  { id: "city-indore", label: "Indore" },
  { id: "city-visakhapatnam", label: "Visakhapatnam" },
  { id: "city-vijayawada", label: "Vijayawada" },
  { id: "city-trichy", label: "Trichy" },
  { id: "city-coimbatore", label: "Coimbatore" },
  { id: "city-patna", label: "Patna" },
  { id: "city-thiruvananthpuram", label: "Thiruvananthpuram" },
  { id: "city-jamshedpur", label: "Jamshedpur" },
  { id: "city-agra", label: "Agra" },
  { id: "city-madurai", label: "Madurai" },
  { id: "city-ranchi", label: "Ranchi" },
  { id: "city-meerut", label: "Meerut" },
  { id: "city-vadodara", label: "Vadodara" },
  { id: "city-varanasi", label: "Varanasi" },
  { id: "city-raipur", label: "Raipur" },
  { id: "city-nashik", label: "Nashik" },
  { id: "city-rajkot", label: "Rajkot" },
  { id: "city-kozhikode", label: "Kozhikode" },
  { id: "city-jodhpur", label: "Jodhpur" },
  { id: "city-aurangabad", label: "Aurangabad" },
  { id: "city-dhanbad", label: "Dhanbad" },
  { id: "city-jabalpur", label: "Jabalpur" },
  { id: "city-kota", label: "Kota" },
];

export const India = {
  mapCenter: [20.5937, 78.9629],
  mapZoomLevel5: 5,
  mapZoomLevel4: 4,
};

export const FormDataTargets = {
  confirmationForm: "confirmation-form",
  campaignMediaForm: "campaign-media-create-form",
  multiCitySelectionForm: "multi-city-selection-form",
  setupMonitoringForm: "setup-monitoring-form",
  targetGroupForm: "target-group-form",
  singleCitySelectionForm: "single-city-selection-form",
  platformUpdatesForm: "platform-updates-form",
  alertForm: "alert-form",
  locationOfIntrestForm: "location-of-interst-form",
  mediaLogImageForm: "media-log-image-form",
  campaignBudgetExceedModal: "campaign-budget-exceed-modal",
  confirmCampaignMediaSelection: "confirm-campaign-media-selection",
};

export const RadiusConstants = [
  {
    value: 500,
    label: "500 Metres",
  },
  {
    value: 1000,
    label: "1 KM",
  },
  {
    value: 1500,
    label: "1.5 KMs",
  },
];

export const locationOfIntrestRadiusConstants = [
  {
    value: 1000,
    label: "1 KM",
  },
  {
    value: 2000,
    label: "2 KMs",
  },
  {
    value: 3000,
    label: "3 KMs",
  },
  {
    value: 4000,
    label: "4 KMs",
  },
  {
    value: 5000,
    label: "5 KMs",
  },
];

export const MapView = {
  ZOOM_INDEX: 11,

  MetaData: {
    TITLE: "title",
    IMAGE: "image",
    DIMENSIONS: "dimensions",
    LTS: "lts",
    OTS: "ots",
    OTS_CHANGE: "otsChange",
  },

  MetaDataToKeys: {
    title: "TITLE",
    image: "IMAGE",
    dimensions: "DIMENSIONS",
    lts: "LTS",
    ots: "OTS",
    otsChange: "OTS_CHANGE",
  },
};

export const StatusIcon = {
  LIVE: { icon: "fa-video", color: "text-success", statusText: "Live" },
  SCHEDULED: {
    icon: "fa-clock",
    color: "text-dark",
    statusText: "Scheduled",
  },
  COMPLETED: {
    icon: "fa-check-circle",
    color: "text-dark",
    statusText: "Completed",
  },
  REVIEW: {
    icon: "fa-exclamation-triangle",
    color: "text-warning",
    statusText: "Waiting to be confirmation",
  },

  DRAFT: {
    icon: "fa-exclamation-triangle",
    color: "text-warning",
    statusText: "Saved as Draft",
  },
};

export const MediaType = {
  BILLBOARDS: "Billboards",
  GANTRIES: "Gantry",
  MEDIANS: "Medians",
  UNIPOLES: "Unipoles",
  FACADES: "Facades",
  FOB: "Footover Bridges",
  PILLARS: "Metro Pillars",
  TRANSIT_STATIONS: "Transit Stations",
  AMBIENT: "Ambient",
  DOOH: "DOOH",
};

export const KeysToMediaType = {
  Billboards: "BILLBOARDS",
  Gantry: "GANTRIES",
  Medians: "MEDIANS",
  Unipoles: "UNIPOLES",
  Facades: "FACADES",
  "Footover Bridges": "FOB",
  "Metro Pillars": "PILLARS",
  "Transit Stations": "TRANSIT_STATIONS",
  Ambient: "AMBIENT",
  DOOH: "DOOH",
};

export const Status = Object.freeze({
  CHECKED: "checked",
  UNCHECKED: "unchecked",
  PARTIALLY_CHECKED: "partially_checked",
});

export const TargetGroupAttributes = Object.freeze({
  gender: "GENDER",
  ageGroup: "AGE_GROUP",
  incomeGroup: "INCOME_GROUP",
});

export const PageSize = {
  CitySummary: 1000,
  MapView: 1000,
  MonitoringCitySummary: 1000,
  MonitoringMediaLog: 1000,
  PoiNames: 5,
  campaignList: 100,
};

export const PageNumber = {
  Page1: 1,
};

export const DefaultPagination = {
  pageNumber: 1,
  pageSize: 1000,
};

export const ProgressTexts = {
  Uploads: {
    title: "Uploading sites",
    subtitle: "Typically takes 2 min",
  },

  Downloads: {
    title: "Download Template",
    subtitle: "In progress.....",
  },
};

export const LocalStorageKeys = {
  PERMISSIONS: "permissions",
  USER: "user",
  ORG_TYPE: "org_type",
  PLATFORM_UPDATES_MODAL: "platformUpdatesModal",
};

export const LITSTATUS = {
  FRONT_LIT: "front lit",
  BACK_LIT: "back lit",
  NOT_LIT: "not lit",
  AMBIENT_LIT: "ambient lit",
  DIGITAL: "digital screen",
};

export const MediaLitStatus = {
  NOT_LIT: "NOT_LIT",
};

export const DATE_FORMATS = {
  full_month_with_date_year: "MMM DD-YYYY",
  date_month_year: "DD-MM-YYYY",
};

export const DataLayerType = Object.freeze({
  POINT: "POINT",
  PINCODE: "PINCODE",
});
export const MapZoom = {
  zoomLevel9: 9,
  zoomLevel10: 10,
  zoomLevel11: 11,
  zoomLevel12: 12,
  zoomLevel13: 13,
  zoomLevel14: 14,
  zoomLevel15: 15,
};

// Spherical area dividing factor
export const BoundarySphericalAreaFactor = 0.0000677329;

// Media Colors
// Inventory ==> orange
// Campaign Media ==> Dark blue..
export const MediaColor = { inventory: "#f27602", campaign: "#03066d" };

// DispatchFrom {keys:values}
export const DispatchFrom = {
  campaignAnalyser: "camp-analyser",
  campaignPlanning: "camp-planning",
  campaignMonitoring: "camp-monitoring",
};

// this key is to check whether its is present it on the url pathName
export const UrlKeys = {
  campaignPlanning: "plan",
  cityAnalyser: "city-analyser",
  siteAnalyserTool: "site-analyser",
  map: "map",
  mantaraySpecific: "/m/",
  disableBudgetExceedWarning: "disable-budget-warning",
  toohlSpecific: "/p/",
};

// media inventory options
export const MediaInventoryOptions = {
  mavin: "mavin-inventory",
  org: "org-inventory",
  campaign: "campaign-inventory",
};

// image upload file type
export const ImageFileTypes = [".png", ".jpg", ".jpeg"];

// ROLES Bean
export const UserRoles = {
  ROLE_SEL_MEDIA_ARCHIVE: "ROLE_SEL_MEDIA_ARCHIVE",
  ROLE_MON_LOG_READ: "ROLE_MON_LOG_READ",
  ROLE_DL_CREATE: "ROLE_DL_CREATE",
  ROLE_CAMP_MEDIA_UPDATE: "ROLE_CAMP_MEDIA_UPDATE",
  ROLE_TOOL_COMPARE_LOCATIONS: "ROLE_TOOL_COMPARE_LOCATIONS",
  ROLE_TG_READ: "ROLE_TG_READ",
  ROLE_CAMP_UPDATE: "ROLE_CAMP_UPDATE",
  ROLE_MON_USER: "ROLE_MON_USER",
  ROLE_CAMP_MEDIA_ARCHIVE: "ROLE_CAMP_MEDIA_ARCHIVE",
  ROLE_USR_UPDATE: "ROLE_USR_UPDATE",
  ROLE_CAMP_ARCHIVE: "ROLE_CAMP_ARCHIVE",
  ROLE_TG_UPDATE: "ROLE_TG_UPDATE",
  ROLE_RDST_READ: "ROLE_RDST_READ",
  ROLE_MON_SETTINGS_CANCEL: "ROLE_MON_SETTINGS_CANCEL",
  ROLE_CAMP_CREATE: "ROLE_CAMP_CREATE",
  ROLE_CAMP_MEDIA_READ: "ROLE_CAMP_MEDIA_READ",
  ROLE_MON_LOG_ARCHIVE: "ROLE_MON_LOG_ARCHIVE",
  ROLE_TG_CREATE: "ROLE_TG_CREATE",
  ROLE_USER: "ROLE_USER",
  ROLE_SEL_MEDIA_READ: "ROLE_SEL_MEDIA_READ",
  ROLE_MON_SETTINGS_CREATE: "ROLE_MON_SETTINGS_CREATE",
  ROLE_RDST_CREATE: "ROLE_RDST_CREATE",
  ROLE_TOOL_REACH_FREQUENCY: "ROLE_TOOL_REACH_FREQUENCY",
  ROLE_CAMP_READ: "ROLE_CAMP_READ",
  ROLE_CAMP_SHARE: "ROLE_CAMP_SHARE",
  ROLE_MON_LOG_REPORT: "ROLE_MON_LOG_REPORT",
  ROLE_OMP_UPDATE: "ROLE_OMP_UPDATE",
  ROLE_DL_ARCHIVE: "ROLE_DL_ARCHIVE",
  ROLE_DL_READ: "ROLE_DL_READ",
  ROLE_TOOL_OTS: "ROLE_TOOL_OTS",
  ROLE_USR_CREATE: "ROLE_USR_CREATE",
  ROLE_MQ_READ: "ROLE_MQ_READ",
  ROLE_OMP_READ: "ROLE_OMP_READ",
  ROLE_TOOL_DEMOGRAPHICS: "ROLE_TOOL_DEMOGRAPHICS",
  ROLE_CAMP_REPORT: "ROLE_CAMP_REPORT",
  ROLE_RDST_UPDATE: "ROLE_RDST_UPDATE",
  ROLE_CAMP_MEDIA_CREATE: "ROLE_CAMP_MEDIA_CREATE",
  ROLE_DL_UPDATE: "ROLE_DL_UPDATE",
  ROLE_MON_SETTINGS_UPDATE: "ROLE_MON_SETTINGS_UPDATE",
  ROLE_MON_SETTINGS_READ: "ROLE_MON_SETTINGS_READ",
  ROLE_TOOL_LTS: "ROLE_TOOL_LTS",
  ROLE_OMP_CREATE: "ROLE_OMP_CREATE",
  ROLE_RDST_ARCHIVE: "ROLE_RDST_ARCHIVE",
  ROLE_SEL_MEDIA_UPDATE: "ROLE_SEL_MEDIA_UPDATE",
  ROLE_USR_READ: "ROLE_USR_READ",
  ROLE_SELLER: "ROLE_SELLER",
};

// Days/Duration Constants
export const DurationConstants = {
  THIRTY_DAYS: 30,
};

// file extensions
export const FilenameExtensions = {
  pdf: ".pdf",
  xlsx: ".xlsx",
  zip: ".zip",
};

// Report File Names
export const ReportConstants = {
  CAMPAIGN_XLS_REPORT: `CampaignReport${FilenameExtensions.xlsx}`,
  CAMPAIGN_PDF_REPORT: `Campaign${FilenameExtensions.pdf}`,
  CITY_PDF_REPORT: `City${FilenameExtensions.pdf}`,
  MEDIA_PDF_REPORT: `Media${FilenameExtensions.pdf}`,
  MONITORING_MEDIA_LOG_REPORT: `MonitoringMediaLog${FilenameExtensions.pdf}`,
  CITY_MEDIAS_REPORTS: `CityMedias${FilenameExtensions.zip}`,
  CAMPAIGN_GRAPH_PDF_REPORT: `CampaignGraph${FilenameExtensions.pdf}`,
};

// Download image names
export const DownloadImagesNameConstants = {
  LOG_IMAGE: "LogImage.jpeg",
  MAP_IMAGE: "mapImage.jpeg",
};

// Default Filter Object
export const DefaultFilterObjects = {
  // UI Media Filter Object
  media: {
    mediaTypes: [],
    litStatus: [],
    orientation: [],
    impressions: "",
    maxHeight: "",
    minHeight: "",
    maxWidth: "",
    minWidth: "",
    minCost: "",
    maxCost: "",
    minArea: "",
    maxArea: "",
  },
};

// Report Status of Campaign Media
export const CampaignMediaReportStatus = {
  APPROXIMATED: "APPROXIMATED",
};

// Report Status of Campaign
export const CampaignReportStatus = {
  GENERATED: "GENERATED",
};

// Demographic Stats Status of Campaign
export const CampaignDemographicStatsStatus = {
  TODO: "TODO",
  PROCESSED: "PROCESSED",
  IN_PROGRESS: "IN_PROGRESS",
  FAILED: "FAILED",
};

// Excel Report Status
export const ExcelReportStatus = {
  GENERATED: "GENERATED",
  APPROXIMATION_FAILED: "APPROXIMATION_FAILED",
};

//used to check the type of ots methodology
export const OtsMethodologyType = {
  TRAFFIC: "TRAFFIC",
};

// used in Location Summary api to Get near by poi's of specific type
export const PoiTypes = [
  "college",
  "supermarket",
  "society",
  "school",
  "mall",
  "hospital",
];

// types of Regions  used in BoundariesListingSection
export const RegionTypes = {
  None: "None",
  Ward: "Ward",
  Pincode: "Pincode",
};

// Image types
export const ImageTypes = {
  JPEG: "jpeg",
};

// Percentage
export const Percentage = {
  HUNDRED: 100,
};

// media types with scores
export const MediaTypes = [
  {
    type: "UNIPOLES",
    score: 10,
  },
  {
    type: "BILLBOARDS",
    score: 10,
  },
  {
    type: "GANTRIES",
    score: 8,
  },
  {
    type: "MEDIANS",
    score: 10,
  },
  {
    type: "FACADES",
    score: 7,
  },
  {
    type: "TRANSIT_STATIONS",
    score: 4,
  },
  {
    type: "AMBIENT",
    score: 5,
  },
  {
    type: "DOOH",
    score: 9.5,
  },
  {
    type: "FOB",
    score: 8,
  },
  {
    type: "PILLARS",
    score: 4,
  },
  {
    type: "OTHERS",
    score: 8,
  },
];

// media Tag Types with scores
export const MediaTagTypes = [
  {
    type: "Impact",
    score: 10,
  },
  {
    type: "frequency",
    score: 9,
  },
  {
    type: "Ambient",
    score: 8,
  },
];

// Metric Scores
export const MetricScores = {
  dwellTimeScore: 7,
  impressionsScore: 8,
  reachScore: 8.5,
  tgImpressionsScore: 9,
  tgReachScore: 9.5,
};

// Media Sources
export const MediaSources = {
  ANONYMOUS_MEDIA: "ANONYMOUS_MEDIA",
  SELLER_MEDIA: "SELLER_MEDIA",
  ORG_MEDIA: "ORG_MEDIA",
};

// Media Source Type Strings
export const MediaSourceTypeStrings = {
  sellerMedia: "sellerMedia",
  orgMedia: "orgMedia",
};

// Duration Options
export const DurationOptions = [
  { id: 7, label: "7 Days" },
  { id: 14, label: "14 Days" },
  { id: 21, label: "21 Days" },
  { id: 30, label: "30 Days" },
];

// Organization Ids mapping ([staging,Production])
export const OrganizationIdsMapping = {
  patrika: ["66d5b0d171727856842eb70a", "66d5a57aafc16712ca8bb73e"],
};

// patrika seller Id
export const PatrikaSellerId = "rppl";

// marker Z index offset
export const MarkerZIndexOffsetOptions = {
  Default: 0,
  Max: 1000,
};

// Price Unit
export const PriceUnitConstants = {
  INR: "INR",
};

// Price Intervals
export const PriceIntervalConstants = {
  PER_MONTH: "PER_MONTH",
};

// Price Mode Constants
export const PriceModeConstants = {
  FIXED: "FIXED",
};
