import { DataLayer } from "../../constants/action-constants/DataLayerActionConstants";

export const getCustomDataLayers = (
  isArchived,
  keyWord = "",
  pageNumber = 1,
  pageSize = 10
) => ({
  type: DataLayer.GET_CUSTOM_DATA_LAYERS,
  payload: {
    isArchived,
    pageNumber,
    pageSize,
    keyWord,
  },
});

export const toggleCustomDataLayerStatus = (id, isArchived) => ({
  type: DataLayer.CHANGE_DATA_LAYER_STATUS,
  payload: {
    id,
    isArchived,
  },
});

export const getDataLayerDetails = (id) => ({
  type: DataLayer.GET_DATA_LAYER_DETAILS,
  payload: {
    id,
  },
});
