import React from "react";

// Utils & constants
import { isDivDisabled } from "./MapViewUtil";
import { useUrlKeyCheck } from "../../mavin/utils/hooks/HooksUtil";
import { UrlKeys } from "../../constants/GeneralConstants";

// Tabs
import MediaSitesTab from "./media-sites/MediaSitesTab";
import RoadStretchesTab from "./road-stretches/RoadStretchesTab";
import TargetGroupTab from "./target-group/TargetGroupTab";
import PointsOfInterestTab from "./points-of-interest/PointsOfInterestTab";
import BoundariesTab from "./boundaries/BoundariesTab";
import DataLayersTab from "./data-layers/DataLayersTab";
import SecTab from "./sec/SecTab";

// Constants
const contents = [
  <>
    <i className="fas fa-map-marker-alt my-2 text-primary"></i>
    <p className="pb-0">Media sites</p>
  </>,
  <>
    <i className="far fa-compass my-2 text-primary"></i>
    <p className="pb-0">Points of Interest</p>
  </>,
  <>
    <i className="fas fa-border-all my-2 text-primary"></i>
    <p className="pb-0">Boundaries</p>
  </>,
  <>
    <i className="fas fa-users my-2 text-primary"></i>
    <p className="pb-0">Target group</p>
  </>,
  <>
    <i className="fas fa-border-none my-2 text-primary"></i>
    <p className="pb-0">SEC</p>
  </>,
  <>
    <i className="fas fa-road my-2 text-primary"></i>
    <p className="pb-0">Road Stretches</p>
  </>,
  <>
    <i className="fas fa-layer-group my-2 text-primary"></i>
    <p className="pb-0">Data Layers</p>
  </>,
];

// Component
// ----------------------------------------------------------------------------

function SelectedContent({ selectedTab, campaignId, cityId, bbox }) {
  switch (selectedTab) {
    case 1:
      return <MediaSitesTab campaignId={campaignId} cityId={cityId} />;
    case 2:
      return <PointsOfInterestTab cityId={cityId} />;
    case 3:
      return <BoundariesTab cityId={cityId} bbox={bbox} />;
    case 4:
      return <TargetGroupTab bbox={bbox} />;
    case 5:
      return <SecTab cityId={cityId} bbox={bbox} />;
    case 6:
      return <RoadStretchesTab cityId={cityId} />;
    case 7:
      return <DataLayersTab cityId={cityId} />;
    default:
      return null;
  }
}

function MapTabsSection({ selectedTab, tabOnClick, cityId, campaignId, bbox }) {
  const { campaignPlanning } = UrlKeys;

  // if the "plan" key is present in url then its is from campaign planning page
  const isFromCampaignPlanner = useUrlKeyCheck(campaignPlanning);
  return (
    <div className="map-tabs-cont">
      <div className="tab-items">
        {contents.map((content, index) => (
          <div
            key={index}
            className={`tab-item text-primary ${
              selectedTab === index + 1 ? "active" : ""
            }`}
            onClick={() => tabOnClick(index + 1)}
            disabled={isDivDisabled(index + 1, isFromCampaignPlanner)}
          >
            {content}
          </div>
        ))}
      </div>

      {!!selectedTab && (
        <div className="tab-cont bg-white">
          <SelectedContent
            selectedTab={selectedTab}
            campaignId={campaignId}
            cityId={cityId}
            bbox={bbox}
          />
        </div>
      )}
    </div>
  );
}

export default MapTabsSection;
