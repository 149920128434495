import { useDispatch, useSelector } from "react-redux";

// Actions
import { getLocationSummary } from "../../../actions/location-summary/LocationSummaryActions";

//Utils
import { getPoiDistribution } from "../../../mavin/components/location-summary/LocationSummaryUtil";

// Components
import Landmarks from "../../../mavin/components/location-summary/Landmarks";
import LocationSummaryPieChart from "../../../mavin/components/location-summary/LocationSummaryPieChart";
import Population from "../../../mavin/components/location-summary/Population";
import RadiusSelector from "../../../mavin/components/location-summary/RadiusSelector";
import SocioEconomicIndicators from "../../../mavin/components/location-summary/SocioEconomicIndicators";

/**
 * Demographic summary section
 */
function SiteLocationSummarySection({ setRadius, coordinate }) {
  const dispatch = useDispatch();
  //Selector
  const locationSummaryInfo = useSelector(
    (state) => state.locationSummary.locationSummaryInfo
  );
  const isLoading = useSelector(
    (state) => state.locationSummary.locationSummaryInfoLoading
  );

  // Function
  function changeRadius(radius) {
    setRadius(radius);
    dispatch(
      getLocationSummary({
        latitude: coordinate[0],
        longitude: coordinate[1],
        distance: radius,
      })
    );
  }

  if (!locationSummaryInfo) {
    return null;
  }

  const {
    poiCounts = {},
    population,
    sustenance,
    realtySale,
    nearByPois = {},
  } = locationSummaryInfo;

  const poiDistribution = getPoiDistribution(poiCounts);

  return (
    <>
      {/* Title And Radius Selector */}
      <RadiusSelector
        title={"Location Summary Within"}
        onItemSelect={changeRadius}
        isLoading={isLoading}
        className="py-2"
      />

      <div className="row">
        {/* Location Summary Section */}
        <LocationSummaryPieChart
          poiDistribution={poiDistribution}
          className="col-6"
        />

        <div className="col-6">
          {/* Population details */}
          <Population population={population} />
          <hr className="divider"></hr>

          {/* Socia Economic details */}
          <SocioEconomicIndicators
            sustenance={sustenance}
            realtySale={realtySale}
          />
          <hr className="divider"></hr>

          {/* Land Mark details */}
          <Landmarks nearByPois={nearByPois} />
        </div>
      </div>
    </>
  );
}

export default SiteLocationSummarySection;
