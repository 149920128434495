import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

// Actions
import {
  disableSellerMedia,
  enableSellerMedia,
  getSellerMediaByCity,
  updateMetricsForSellerMedia,
} from "../../actions/seller/SellerMediaActions";

// Utils & Constants
import { filterMedia } from "../../utils/MediaUtils";
import { constructSingleDateString } from "../../common-utils/date-utils/DateUtils";
import {
  DATE_FORMATS,
  DefaultFilterObjects,
} from "../../constants/GeneralConstants";
import { getSellerId } from "../../utils/SellerUtils";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

// Urls & Components
import { RedirectTo } from "../../urls/PageLinksURL";

// Components
import {
  ImpressionsCell,
  LtsCell,
  MediaImage,
  MediaMetaData,
} from "../../components/campaign-media-table-row/MediaRow";
import MediaFilterLocal from "../../components/media-filter/MediaFilter";
import Spinner from "../../components/spinner/Spinner";
import TableHeaders from "../../components/table/TableHeaders";
import PageHeader from "../../mavin/components/page-header/PageHeader";
import SearchInput from "../../components/search-input/SearchInput";
import {
  ButtonWithLoader,
  CustomButton,
} from "../../mavin/components/button/Button";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Site Name",
      className: "col-3",
    },
    subTitle: {
      displayName: "Media type, Dimensions, Lighting, Region",
      className: "sub-text",
    },
  },

  {
    title: {
      displayName: "Impressions",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "LTS",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Actions",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "",
      className: "col-2",
    },
  },
];

// Page Components
function UpdateMetricsCell({ media }) {
  // Dispatch
  const dispatch = useDispatch();

  const { sellerId, mediaId, updatedOn, cityId } = media;

  // Selector
  const updateMetricsLoading = useSelector(
    (state) => state.sellerMedia.updateSellerMediaMetricsLoading[mediaId]
  );

  // Constructing the Date String
  const dateString = constructSingleDateString(
    updatedOn,
    DATE_FORMATS.full_month_with_date_year
  );
  return (
    <td className="align-middle text-center">
      <CustomButton
        buttonClassName={"btn btn-link shadow-none px-0"}
        spinnerClassName={"text-primary"}
        displayContent={"Update Metrics"}
        loader={updateMetricsLoading}
        isDisabled={updateMetricsLoading}
        onClickFunction={() =>
          dispatch(updateMetricsForSellerMedia(sellerId, mediaId, cityId))
        }
      />
      <p className="mb-0 text-muted">Last updated on {dateString}</p>
    </td>
  );
}

function ViewMediaCell({ media }) {
  const { id: mediaId, sellerId } = media;
  const sellerMediaDetailPageUrl = RedirectTo.sellerMediaDetailPageUrl
    .replace(":mediaId", mediaId)
    .replace(":sellerId", sellerId);

  return (
    <td className="align-middle">
      <Link
        to={constructRedirectPath(sellerMediaDetailPageUrl)}
        target="_blank"
      >
        {"View Media"}
      </Link>
    </td>
  );
}

function EditMediaCell({ media }) {
  const { id: mediaId, sellerId } = media;
  const sellerEditMediaPageUrl = RedirectTo.sellerEditMediaPageUrl
    .replace(":mediaId", mediaId)
    .replace(":sellerId", sellerId);

  return (
    <td className="align-middle">
      <Link to={constructRedirectPath(sellerEditMediaPageUrl)} target="_blank">
        {"Edit Media"}
      </Link>
    </td>
  );
}

function MediaInfoCell({ media }) {
  const { id: mediaId, sellerId } = media;
  const sellerMediaDetailPageUrl = RedirectTo.sellerMediaDetailPageUrl
    .replace(":mediaId", mediaId)
    .replace(":sellerId", sellerId);

  return (
    <td>
      <div className="d-flex align-items-center">
        <MediaImage media={media} />
        <div className="pl-2">
          <Link
            to={constructRedirectPath(sellerMediaDetailPageUrl)}
            target="_blank"
          >
            {media.title}
          </Link>
          <MediaMetaData media={media} />
        </div>
      </div>
    </td>
  );
}

function MediaStatusCell({ media }) {
  // Dispatch
  const dispatch = useDispatch();

  const { mediaId, isEnabled } = media;

  // Selector
  const enableSellerMediaLoading = useSelector(
    (state) => state.sellerMedia.enableSellerMediaLoading[mediaId]
  );
  const disableSellerMediaLoading = useSelector(
    (state) => state.sellerMedia.disableSellerMediaLoading[mediaId]
  );

  const buttonLabel = isEnabled ? "Unpublish Media" : "Publish Media";
  const dispatchFn = isEnabled ? disableSellerMedia : enableSellerMedia;

  return (
    <td className="align-middle ">
      <CustomButton
        buttonClassName={"btn btn-link shadow-none px-0"}
        displayContent={buttonLabel}
        onClickFunction={() => dispatch(dispatchFn(mediaId))}
        loader={enableSellerMediaLoading || disableSellerMediaLoading}
        isDisabled={enableSellerMediaLoading || disableSellerMediaLoading}
        spinnerClassName={"text-primary"}
      />
    </td>
  );
}

function SellerInventoryListRow({ media }) {
  return (
    <tr>
      <MediaInfoCell media={media} />
      <ImpressionsCell impressionsElementStyle={"align-middle"} media={media} />
      <LtsCell ltsElementClass={"align-middle"} media={media} />
      <ViewMediaCell media={media} />
      <EditMediaCell media={media} />
      <MediaStatusCell media={media} />
      <UpdateMetricsCell media={media} />
    </tr>
  );
}

function SellerInventoryMediaTable({ mediaList }) {
  if (mediaList.length < 1) {
    return null;
  }

  return (
    <div className="table-responsive mt-4">
      <table className="table border">
        <TableHeaders
          scope={"col"}
          tableHeaders={tableHeaders}
          headerClass={"thead bg-alt"}
        />
        <tbody>
          {mediaList.map((media) => (
            <SellerInventoryListRow key={media.id} media={media} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function SearchAndFilterSection({ mediaList, setSellerMediaList }) {
  // State
  // filter and search
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState(DefaultFilterObjects.media);

  // TODO:: Remove this useEffect,for now temporarily fixed. find the alternative way to refresh the list
  useEffect(() => {
    doSearchAndFilter(searchQuery, filters);
  }, [JSON.stringify(mediaList)]);

  function doSearchAndFilter(searchedText, appliedFilters) {
    // updating searched text
    setSearchQuery(searchedText);

    // updating filter state
    setFilters(appliedFilters);

    const filtersObj = { ...appliedFilters };

    // search will be executed when searchedText length is greater than 1
    if (searchedText.length > 1) {
      filtersObj.title = searchedText;
    }

    const filteredMedia = filterMedia(mediaList, filtersObj);
    setSellerMediaList(filteredMedia);
  }

  return (
    <div className="d-flex justify-content-between">
      {/* Search input */}
      <div className="col-3 px-0">
        <SearchInput
          placeholder={"Search media sites inventory"}
          className={"shadow-none rounded-lg"}
          onTextChange={({ target }) =>
            doSearchAndFilter(target.value, filters)
          }
          searchButton={false}
        />
      </div>

      <MediaFilterLocal
        onFiltersApplied={(appliedFilters) =>
          doSearchAndFilter(searchQuery, appliedFilters)
        }
        className={"col rounded-lg shadow-none"}
        mediaFilterObj={filters}
      />
    </div>
  );
}

function NoMediaFoundMessage() {
  return (
    <>
      <hr className="row" />
      <p className="text-center mt-5 font-italic">No media found</p>
    </>
  );
}

function MediaListSection({ mediaList }) {
  // State
  const [sellerMediaList, setSellerMediaList] = useState([...mediaList]);

  return (
    <>
      {/* Search and filter */}
      <SearchAndFilterSection
        mediaList={[...mediaList]}
        setSellerMediaList={setSellerMediaList}
      />

      {/* No media found message */}
      {sellerMediaList.length < 1 && <NoMediaFoundMessage />}

      {/* Seller Media Table */}
      <SellerInventoryMediaTable mediaList={sellerMediaList} />
    </>
  );
}

/**
 *  Main Page
 */
function SellerInventoryCityViewPage() {
  // Dispatch
  const dispatch = useDispatch();
  const { cityId } = useParams();

  // Selector
  const mediaList = useSelector(
    (state) => state.sellerMedia.sellerMediaByCityList
  );

  const loading = useSelector(
    (state) => state.sellerMedia.sellerMediaByCityLoading
  );

  // city name
  const cityName = mediaList[0]?.cityName;

  // Seller Id
  const sellerId = getSellerId();

  useEffect(() => {
    dispatch(getSellerMediaByCity(cityId, sellerId));
  }, [dispatch, cityId]);

  // Redirect page url
  const sellerInventoryMapViewPageUrl =
    RedirectTo.sellerInventoryMapViewPageUrl.replace(":cityId", cityId);

  const pageActions = (
    <Link to={constructRedirectPath(sellerInventoryMapViewPageUrl)}>
      <ButtonWithLoader displayContent={"View Media on Map"} />
    </Link>
  );

  // Checks for page loading
  if (loading) {
    return <Spinner className="spinner-center mt-2" />;
  }

  const pageTitle = `Inventory :: ${cityName}`;

  return (
    <div className="content-wrapper h-100">
      {/* Page Header */}
      <PageHeader title={pageTitle} shadow={true} actions={pageActions} />

      {/* Media List Section */}
      <div className="page-content">
        {mediaList.length > 0 && <MediaListSection mediaList={mediaList} />}
      </div>
    </div>
  );
}

export default SellerInventoryCityViewPage;
