// URLS
import { getV2SCSSUrl } from "../../urls/map-view/SecURL";

// Constants and utils
import { sendGetRequest } from "../../utils/api/apiUtils";

// sdk
import AtlasClient from "../../sdk/AtlasClient";

export async function getSecInfo(cityBBox) {
  const bbox = cityBBox.split(",");

  const nw = `${bbox[3]},${bbox[2]}`;
  const se = `${bbox[1]},${bbox[0]}`;

  // const params = {
  //   nw: bbox[1].concat(",", bbox[0]),
  //   se: bbox[3].concat(",", bbox[2]),
  // };
  // return sendGetRequest(getV2SCSSUrl, params);

  const response = await AtlasClient.heatmapGeoWithinBBox(nw, se, "restaurant");
  return response;
}
