import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Apis
import {
  getCampaignPlan,
  getCampaignPlanOverview,
  updateCampaignPlanStatus,
} from "../../apis/CampaignPlanAPI";

// Constants and Utils
import { ActionTypes } from "../../constants/ActionConstants";
import { getErrorMessage } from "../../utils/util";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

// campaignPlanDetails for CITY
export function* getCampaignPlanDetails(action) {
  const { campaignId, cityId } = action.payload;
  try {
    const campaignPlan = yield getCampaignPlan(campaignId, cityId);

    yield put({
      type: ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_SUCCESS,
      payload: { campaignPlan },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

// CampaignPlanDetails for CAMPAIGN
export function* getCampaignPlanOverviewDetails(action) {
  const { campaignId } = action.payload;
  try {
    const campaignPlanOverview = yield getCampaignPlanOverview(campaignId);

    yield put({
      type: ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_OVERVIEW_SUCCESS,
      payload: { campaignPlanOverview },
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_OVERVIEW_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

// Update CampaignPlan State
export function* updatePlanStatus(action) {
  const { campaignId, status, history, redirectUrl } = action.payload;
  try {
    yield updateCampaignPlanStatus(campaignId, status);

    yield put({
      type: ActionTypes.CampaignPlanDetails.UPDATE_CAMPAIGN_PLAN_STATUS_SUCCESS,
    });

    history.push(constructRedirectPath(redirectUrl));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({
      type: ActionTypes.CampaignPlanDetails.UPDATE_CAMPAIGN_PLAN_STATUS_FAILURE,
      payload: error,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(
      ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN,
      getCampaignPlanDetails
    ),
    takeLatest(
      ActionTypes.CampaignPlanDetails.GET_CAMPAIGN_PLAN_OVERVIEW,
      getCampaignPlanOverviewDetails
    ),
    takeLatest(
      ActionTypes.CampaignPlanDetails.UPDATE_CAMPAIGN_PLAN_STATUS,
      updatePlanStatus
    ),
  ]);
}
