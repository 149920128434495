import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import {
  getCustomDataLayers,
  toggleCustomDataLayerStatus,
} from "../../../actions/org/OrgDataLayerActions";

// Constants and Utils
import { LoadingData } from "../OrgUtils";
import { DataLayerType } from "../../../constants/GeneralConstants";
import { formatTimestamp } from "../../../common-utils/date-utils/FormatDateUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Components
import Spinner from "../../../components/spinner/Spinner";
import Pagination from "../../../components/pagination/Pagination";
import TableHeaders from "../../../components/table/TableHeaders";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Pages and Sections
import OrgSettingsSidebar from "../OrgSettingsSidebar";
import SearchInput from "../../../components/search-input/SearchInput";

// CSS
import "../OrgSettings.scss";

// Page-constants
const tableHeaderLabels = [
  {
    title: {
      displayName: "Type",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Data points",
    },
  },
  {
    title: {
      displayName: "Upload date",
    },
  },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Functions
// ----------------------------------------------------------------------------
function formatDataLayer(toggleStatusFn, isDataLayerProcessing) {
  return function format(dataLayer) {
    function onActionClick() {
      toggleStatusFn(dataLayer.id, dataLayer.isArchived);
    }

    const orgDataLayerViewPageUrl = RedirectTo.orgDataLayerViewPageUrl.replace(
      ":dataLayerId",
      dataLayer.id
    );

    return {
      id: dataLayer.id,
      components: [
        dataLayer.type === DataLayerType.POINT ? "Points" : "Pincodes",
        <Link
          key={dataLayer.id}
          to={{ pathname: constructRedirectPath(orgDataLayerViewPageUrl) }}
        >
          {dataLayer.name}
        </Link>,
        `${dataLayer.dataPointCount} points`,
        formatTimestamp(dataLayer.createdOn),
        dataLayer.isArchived
          ? `Archived on ${formatTimestamp(dataLayer.updatedOn)}`
          : "Active",
        isDataLayerProcessing[dataLayer.id] ? (
          <Spinner className="spinner-border-sm" />
        ) : (
          <button
            key={dataLayer.id}
            className="btn btn-link shadow-none p-0"
            onClick={onActionClick}
          >
            {dataLayer.isArchived ? "Unarchive" : "Archive"}
          </button>
        ),
      ],
    };
  };
}

// Page Components
// ----------------------------------------------------------------------------
function TableActionsSection({ searchText, setSearchText }) {
  // labels
  const TXT_PLACEHOLDER_SEARCH = "Search Data Layers";

  // Functions
  const dispatch = useDispatch();
  function onClickSearch() {
    dispatch(getCustomDataLayers("", searchText));
  }

  function onSearchTextChange(e) {
    setSearchText(e.target.value);
  }

  return (
    <div className="table-header">
      <SearchInput
        placeholder={TXT_PLACEHOLDER_SEARCH}
        onClickSearch={onClickSearch}
        onTextChange={onSearchTextChange}
      />
    </div>
  );
}

function DataLayersTable({ dataLayerList, pagination, onClickPagination }) {
  const { totalCount, pageNo, pageSize } = pagination;

  // Loading till the "data-layers" response comes
  const dataLayerLoading = useSelector(
    (state) => state.orgDataLayers.dataLayersLoading
  );

  if (dataLayerLoading) {
    return <LoadingData className="p-1" dataType="Data Layers" />;
  }

  return (
    <div className="table-responsive">
      <table className="table">
        {/* Table-header */}
        <TableHeaders tableHeaders={tableHeaderLabels} headerClass={"thead"} />

        {/* Table row */}
        <tbody>
          {dataLayerList.map((dataLayer) => (
            <tr key={dataLayer.id}>
              {dataLayer.components.map((component, index) => (
                <td className="p-3" key={index}>
                  {component}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Table-pagination */}
      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={onClickPagination}
      />
    </div>
  );
}

/**
 * Page
 */
function DataLayerListPage() {
  // State
  const [searchText, setSearchText] = useState("");

  // State : Data
  const dataLayers = useSelector((state) => state.orgDataLayers.dataLayers);
  const pagination =
    useSelector((state) => state.orgDataLayers.dataLayerPagination) || {};

  const isDataLayerProcessing = useSelector(
    (state) => state.orgDataLayers.isDataLayerProcessing
  );

  const pageTitle = "Org :: Data Layers";
  const pageActions = (
    <Link
      className="btn btn-primary"
      to={constructRedirectPath(RedirectTo.orgDataLayerCreateUrl)}
    >
      Upload new Data Layer
    </Link>
  );

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomDataLayers(""));
  }, [dispatch]);

  // Toggle data-layer status :: archive/unArchive
  function toggleStatus(id, isArchived) {
    dispatch(toggleCustomDataLayerStatus(id, isArchived));
  }
  const dataLayerList = dataLayers.map(
    formatDataLayer(toggleStatus, isDataLayerProcessing)
  );

  function onClickPagination(pageNumber, pageSize) {
    dispatch(getCustomDataLayers("", searchText, pageNumber, pageSize));
  }

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={6} />

      <div className="content-wrapper">
        {/** Page Header */}
        <PageHeader title={pageTitle} actions={pageActions} shadow={true} />

        {/** Page Content */}
        <div className="page-content">
          {/** Table Header actions */}
          <TableActionsSection
            searchText={searchText}
            setSearchText={setSearchText}
          />

          {/* data-layer table component */}
          <DataLayersTable
            dataLayerList={dataLayerList}
            pagination={pagination}
            onClickPagination={onClickPagination}
          />
        </div>
      </div>

      {/** Modals */}
    </>
  );
}

export default DataLayerListPage;
