// Utils
import { formatTime } from "../common-utils/time-utils/TimeUtils";

/**
 *
 * @param {*} mediaInfo => {aspectRatioIndex,areaIndex,clutterIndex,elevationIndex,tiltIndex}
 * @param {*} regionInfo => {aspectRatioIndexAvg,areaIndexAvg,clutterIndexAvg,elevationIndexAvg,tiltIndexAvg}
 * @returns => chart data
 */
export function constructRadarChartData(mediaInfo = {}, regionInfo) {
  // assigning default value as {} to "regionInfo" will not work in case of "null",
  // so storing into "regionData".
  const regionData = regionInfo ? regionInfo : {};

  // default value
  const defaultValue = 4;

  const chartDataGeneratorConstant = [
    {
      label: "Aspect ratio",
      mediaKey: "aspectRatioIndex",
      regionKey: "aspectRatioIndexAvg",
    },
    {
      label: "Area",
      mediaKey: "areaIndex",
      regionKey: "areaIndexAvg",
    },
    {
      label: "Clutter",
      mediaKey: "clutterIndex",
      regionKey: "clutterIndexAvg",
    },
    {
      label: "Elevation",
      mediaKey: "elevationIndex",
      regionKey: "elevationIndexAvg",
    },
    {
      label: "Tilt",
      mediaKey: "tiltIndex",
      regionKey: "tiltIndexAvg",
    },
  ];

  const data = chartDataGeneratorConstant.map((info) => {
    const { label, mediaKey, regionKey } = info;

    const labelValue = mediaInfo[mediaKey] * 5 || defaultValue;

    return {
      label: `${label} (${labelValue.toFixed(1)})`,
      media: Math.max(0, mediaInfo[mediaKey] * 5) || defaultValue,
      region: Math.max(0, regionData[regionKey] * 5) || defaultValue,
    };
  });

  return data;
}

export function constructLineChartData(data = {}) {
  const hourAvg = data["hour_avg"] || {};
  const hourMax = data["hour_max"] || {};
  const hourMin = data["hour_min"] || {};

  // Showing data  from 6am to 11pm
  const hoursToInclude = Object.keys(hourAvg).filter((hour) => {
    const hourInt = parseInt(hour.replace("_", ""));
    return hourInt >= 6 && hourInt <= 23;
  });

  const lineChartData1 = hoursToInclude.map((key) => {
    return {
      name: formatTime(key.replace("_", "")),
      ["Hour Average"]: hourAvg[key],
      ["Hour Maximum"]: hourMax[key],
      ["Hour Minimum"]: hourMin[key],
    };
  });

  return lineChartData1;
}

export function constructPieChartData(data = {}) {
  const pieChartData = Object.keys(data).map((key) => ({
    name: key,
    value: data[key],
  }));

  return pieChartData;
}
