import React from "react";
import { Link } from "react-router-dom";

// Urls
import { RedirectTo } from "../../urls/PageLinksURL";

// Constants and Utils
import { checkIsSellerUser } from "../../utils/SellerUtils";
import { constructRedirectPath } from "../../utils/redirect-utils/RedirectUtils";

// Page Constants
const {
  orgProfileUrl,
  orgUserListUrl,
  orgSubscriptionUrl,
  orgTargetGroupListUrl,
  orgRoadStretchListUrl,
  orgDataLayerListUrl,
  orgMediaPricePageUrl,
} = RedirectTo;

// org data
const organizationDataConstants = [
  {
    label: "Profile",
    link: orgProfileUrl,
    index: 1,
    icon: "fa-address-card",
  },
  {
    label: "Users & Roles",
    link: orgUserListUrl,
    index: 2,
    icon: "fa-users",
  },
  {
    label: "Subscription",
    link: orgSubscriptionUrl,
    index: 3,
    icon: "fa-crown",
  },
];

// defs data
const definitionsDataConstants = [
  {
    label: "Target Groups",
    link: orgTargetGroupListUrl,
    index: 4,
    icon: "fa-user-tie",
  },
  {
    label: "Road Stretches",
    link: orgRoadStretchListUrl,
    index: 5,
    icon: "fa-road",
  },
  {
    label: "Data Layers",
    link: orgDataLayerListUrl,
    index: 6,
    icon: "fa-layer-group",
  },
];

// inventory data
const inventoryDataConstants = [
  {
    label: "Media Price",
    link: orgMediaPricePageUrl,
    index: 7,
    icon: "fa-map-marker-alt",
  },
];

// Page Components
function SidebarItemList({ data = [], activeIdx }) {
  // no items
  if (data.length === 0) {
    return null;
  }

  return (
    <>
      {data.map((eachData, i) => {
        const { label, link, index, icon } = eachData;
        const activeClass = activeIdx === index ? "active" : "";
        return (
          <li key={i} className={`nav-item ${activeClass}`}>
            <Link className="nav-link" to={constructRedirectPath(link)}>
              <i className={`fas ${icon}`}></i> {label}
            </Link>
          </li>
        );
      })}
    </>
  );
}

/**
 * Common Sidebar for Organization Settings pages - URL Pattern : "/org/**"
 */
export default function OrgSettingsSidebar({ activeIdx }) {
  // Checking the USER is Seller or not
  const isSellerUser = checkIsSellerUser();

  return (
    <aside className="sidebar bg-alt">
      <ul className="nav flex-column mt-3">
        {/** Basic Settings */}
        <li className="nav-item group-header">Organization</li>
        <SidebarItemList
          data={organizationDataConstants}
          activeIdx={activeIdx}
        />

        {!isSellerUser && (
          <>
            {/** Definitions */}
            <li className="nav-item group-header">Definitions</li>
            <SidebarItemList
              data={definitionsDataConstants}
              activeIdx={activeIdx}
            />

            {/** Inventory */}
            {/* TODO:: uncomment when it is functional */}
            {/* <li className="nav-item group-header">Inventory</li>
            <SidebarItemList
              data={inventoryDataConstants}
              activeIdx={activeIdx}
            /> */}
          </>
        )}
      </ul>
    </aside>
  );
}
