import { Boundaries } from "../../constants/action-constants/map-view/BoundariesActionConstants";

export const getBoundaries = (cityId) => ({
  type: Boundaries.GET_BOUNDARIES,
  payload: { cityId },
});

export const getWardData = (cityId) => ({
  type: Boundaries.GET_WARD_INFO,
  payload: {
    cityId,
  },
});

export const getPincodeData = (cityId) => ({
  type: Boundaries.GET_PINCODE_INFO,
  payload: {
    cityId,
  },
});

export const getRegionInfo = (bbox, type) => ({
  type: Boundaries.GET_REGION_INFO,
  payload: { bbox, type },
});

export const setRadioSelected = (label) => ({
  type: Boundaries.SET_RADIOLIST_SELECTED,
  payload: {
    label,
  },
});

export const setRadioBoxStatus = (id, status) => ({
  type: Boundaries.SET_RADIO_STATUS,
  payload: {
    id,
    status,
  },
});

export const setMaxColor = (color) => ({
  type: Boundaries.SET_MAX_COLOR,
  payload: color,
});

export const setBoundarySteps = (steps) => ({
  type: Boundaries.SET_BOUNDARY_STEPS,
  payload: steps,
});

export const unSelectBoundaries = (id) => ({
  type: Boundaries.UN_SELECT,
  payload: { id },
});

export const resetBoundariesInfo = () => ({
  type: Boundaries.RESET,
});
