import {
  archiveDataLayerUrl,
  dataLayerDownloadTemplateUrl,
  dataLayerUploadTemplateUrl,
  getDataLayerUrl,
  orgDataLayersSearchUrl,
  restoreDataLayerUrl,
} from "../urls/OrgDataLayerURL";

import {
  downloadFile,
  sendCreateOrUpdateRequest,
  sendGetRequest,
} from "../utils/api/apiUtils";

export function getDataLayerDetailsById(id) {
  return sendGetRequest(getDataLayerUrl.replace(":dataLayerId", id), {});
}

export async function getDataLayers(isArchived, keyWord, pageNumber, pageSize) {
  // Here, If isArchived == true ==> Meaning we will only get archived DataLayers
  // If isArchived == false ==> Meaning we will only get  unarchived DataLayers
  // If isArchived == "" ==> Meaning we will get all DataLayers
  // (Here,isArchived == "" param is skipped by serializeQueryParams in sendGetRequest,so we will get all DataLayers )
  const params = {
    pn: pageNumber,
    ps: pageSize,
    _ar: isArchived,
  };

  if (keyWord) {
    params._kw = keyWord;
  }

  return sendGetRequest(orgDataLayersSearchUrl, params);
}

export async function toggleDataLayerStatus(id, isArchived) {
  const url = isArchived ? restoreDataLayerUrl : archiveDataLayerUrl;
  return sendCreateOrUpdateRequest(
    url.replace(":dataLayerId", id),
    {},
    true,
    "PUT"
  );
}

export function downloadDataLayerTemplate(layerType) {
  const downloadUrl = dataLayerDownloadTemplateUrl.replace(
    ":layerType",
    layerType
  );
  return downloadFile(downloadUrl);
}

export async function uploadDataLayerTemplate(layerType, layerName, file) {
  const formData = new FormData();

  formData.append("name", layerName);
  formData.append("type", layerType);
  formData.append("desc", "description"); // TODO: take description input from user
  formData.append("file", file);
  return sendCreateOrUpdateRequest(dataLayerUploadTemplateUrl, formData);
}
