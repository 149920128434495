import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// Actions
import {
  archiveTg,
  getTargetGroups,
  restoreTg,
} from "../../../actions/org/OrgTargetGroupActions";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Constants and Utils
import { formatTimestamp } from "../../../common-utils/date-utils/FormatDateUtils";
import { LoadingData } from "../OrgUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Components
import Spinner from "../../../components/spinner/Spinner";
import TableHeaders from "../../../components/table/TableHeaders";
import Pagination from "../../../components/pagination/Pagination";
import SearchInput from "../../../components/search-input/SearchInput";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Pages and Sections
import OrgSettingsSidebar from "../OrgSettingsSidebar";

// CSS
import "../OrgSettings.scss";

// Page Constants
const tableHeaderLabels = [
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Created On",
    },
  },
  {
    title: {
      displayName: "Last Updated On",
    },
  },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Actions",
    },
  },
];

// Page Components
function TableSettingsSection({ searchText, setSearchText }) {
  // Label
  const TXT_PLACEHOLDER_SEARCH = "Search Target Groups";

  // Functions
  const dispatch = useDispatch();
  function onClickSearch() {
    dispatch(getTargetGroups("", searchText));
  }

  function onSearchTextChange(e) {
    setSearchText(e.target.value);
  }

  return (
    <div className="table-header">
      <SearchInput
        placeholder={TXT_PLACEHOLDER_SEARCH}
        onClickSearch={onClickSearch}
        onTextChange={onSearchTextChange}
      />
    </div>
  );
}

function TgName({ tgInfo }) {
  const { name, id } = tgInfo;
  const link = RedirectTo.orgTargetGroupViewPageUrl.replace(
    ":targetGroupId",
    id
  );
  return (
    <td>
      <Link key={id} to={constructRedirectPath(link)}>
        {name}
      </Link>
    </td>
  );
}

function TgCreationDate({ tgInfo }) {
  const { createdOn } = tgInfo;

  return (
    <td>
      <div>{new Date(createdOn).toDateString()}</div>
    </td>
  );
}

function TgLastUpdatedOn({ tgInfo }) {
  const { updatedOn } = tgInfo;
  return (
    <td>
      <div>{new Date(updatedOn).toDateString()}</div>
    </td>
  );
}

function TgStatus({ tgInfo }) {
  const { updatedOn, isArchived } = tgInfo;
  const status = isArchived
    ? `Archived on ${formatTimestamp(updatedOn)}`
    : "Active";
  return (
    <td>
      <div>{status}</div>
    </td>
  );
}

function ArchiveUnarchiveButton({ id, actionProcessing, isArchived }) {
  const dispatch = useDispatch();

  // Functions
  function onActionClick() {
    const action = isArchived ? restoreTg : archiveTg;
    dispatch(action(id));
  }

  if (actionProcessing) {
    return <Spinner className="spinner-border-sm" />;
  }
  return (
    <button
      key={id}
      className="btn btn-link shadow-none p-0"
      onClick={onActionClick}
    >
      {isArchived ? "Unarchive" : "Archive"}
    </button>
  );
}

function TgAction({ tgInfo }) {
  const { id, isArchived } = tgInfo;
  const actionProcessing = useSelector(
    (state) => state.orgTargetGroup.isTgArchiveRestore[id]
  );

  return (
    <td>
      <div className="navbar px-0">
        {/* Archive/Unarchive btn */}
        <ArchiveUnarchiveButton
          id={id}
          actionProcessing={actionProcessing}
          isArchived={isArchived}
        />

        {/* Edit TG button */}
        <Link
          to={constructRedirectPath(
            RedirectTo.orgTargetGroupUpdateUrl.replace(":targetGroupId", id)
          )}
        >
          <button className="btn btn-link shadow-none p-0 mr-5">
            <i className="fa fa-pen text-primary px-2"></i>
            {"Edit"}
          </button>
        </Link>
      </div>
    </td>
  );
}

function TargetGroupRow({ tgInfo }) {
  return (
    <tr>
      <TgName tgInfo={tgInfo} />
      <TgCreationDate tgInfo={tgInfo} />
      <TgLastUpdatedOn tgInfo={tgInfo} />
      <TgStatus tgInfo={tgInfo} />
      <TgAction tgInfo={tgInfo} />
    </tr>
  );
}

function TargetGroupTable({ targetGrpList, pagination, onClickPagination }) {
  const { totalCount, pageNo, pageSize } = pagination;

  // Loading till target-groups are fetching
  const tgLoading = useSelector(
    (state) => state.orgTargetGroup.targetGroupLoading
  );

  if (tgLoading) {
    return <LoadingData className="p-1" dataType="Target Groups" />;
  }

  return (
    <div className="table-responsive">
      <table className="table table-media">
        {/* Table-header */}
        <TableHeaders tableHeaders={tableHeaderLabels} headerClass={"thead"} />

        {/* Table Row */}
        <tbody>
          {targetGrpList.map((eachTg) => (
            <TargetGroupRow key={eachTg.id} tgInfo={eachTg} />
          ))}
        </tbody>
      </table>

      {/* Table-pagination */}
      <Pagination
        totalCount={totalCount}
        pageNo={pageNo}
        pageSize={pageSize}
        onClick={onClickPagination}
      />
    </div>
  );
}

/**
 * Page
 */
function TargetGroupListPage() {
  // State
  const [searchText, setSearchText] = useState("");

  // State : Data
  const targetGrpList = useSelector((state) => state.orgTargetGroup.tgList);
  const pagination = useSelector((state) => state.orgTargetGroup.tgPagination);

  const pageTitle = "Org :: Target Groups";
  const pageActions = (
    <Link
      className="btn btn-primary"
      to={constructRedirectPath(RedirectTo.orgTargetGroupCreateUrl)}
    >
      {"Create new Target Group"}
    </Link>
  );

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTargetGroups(""));
  }, [dispatch]);

  // Function
  function onClickPagination(pageNumber, pageSize) {
    dispatch(getTargetGroups("", searchText, pageNumber, pageSize));
  }

  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={4} />

      <div className="content-wrapper">
        {/** Page Header */}
        <PageHeader title={pageTitle} actions={pageActions} shadow={true} />

        {/** Page Content */}
        <div className="page-content">
          {/** Table Header */}
          <TableSettingsSection
            searchText={searchText}
            setSearchText={setSearchText}
          />

          {/** TG Table */}
          <TargetGroupTable
            targetGrpList={targetGrpList}
            pagination={pagination}
            onClickPagination={onClickPagination}
          />
        </div>
      </div>
    </>
  );
}

export default TargetGroupListPage;
