// components
import { ButtonWithLoader } from "../../mavin/components/button/Button";

// css
import "./Carousel.scss";

function Indicators({ size }) {
  const indicatorItems = [];
  for (let i = 0; i < size; i++) {
    indicatorItems.push(
      <li
        key={i}
        data-target="#carousel-container"
        data-slide-to={i}
        className={`${i === 0 ? "active" : ""}`}
      ></li>
    );
  }
  return <ol className="carousel-indicators text-primary">{indicatorItems}</ol>;
}

function Content({
  urls,
  imageClassName,
  canDownloadImage,
  onClickFunction,
  isImageDownloading,
}) {
  return (
    <div className="carousel-inner carousel-content-container">
      {urls.map((url, index) => (
        <div
          key={index}
          className={`carousel-item carousel-image-container  ${
            index === 0 ? "active" : ""
          }`}
        >
          <img
            className={`carousel-img d-block w-100 ${imageClassName}`}
            src={url}
            alt={`slide ${index + 1}`}
          />
          {canDownloadImage && (
            <div className="carousel-caption">
              <ButtonWithLoader
                displayContent="Download"
                className="mr-2"
                loader={isImageDownloading}
                onClickFunction={() => {
                  onClickFunction(url);
                }}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function Controls() {
  return (
    <>
      <a
        className="carousel-control-prev"
        href="#carousel-container"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carousel-container"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </>
  );
}

function Carousel({
  urls,
  imageClassName = "",
  canDownloadImage = "false",
  onClickFunction,
  isImageDownloading,
}) {
  if (!urls.length) {
    return null;
  }
  return (
    <div
      id="carousel-container"
      className="carousel slide image-carousel"
      data-ride="carousel"
    >
      <Indicators size={urls.length} />
      <Content
        urls={urls}
        imageClassName={imageClassName}
        canDownloadImage={canDownloadImage}
        onClickFunction={onClickFunction}
        isImageDownloading={isImageDownloading}
      />
      <Controls />
    </div>
  );
}

Carousel.defaultProps = {
  urls: [],
};

export default Carousel;
