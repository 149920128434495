// Page Constants
const poiCategories = [
  {
    _name: "sustenance",
    _display_name: "Sustenance",
    type: [
      {
        _name: "food",
        type: [
          { _name: "bbq" },
          { _name: "food_court" },
          { _name: "restaurant" },
          { _name: "cafe" },
          { _name: "fast_food" },
          { _name: "bakery" },
          { _name: "confectionery" },
          { _name: "ice_cream" },
        ],
      },
      {
        _name: "drinks",
        type: [{ _name: "bar" }, { _name: "pub" }, { _name: "biergarten" }],
      },
      { _name: "other", type: [{ _name: "drinking_water" }] },
    ],
  },
  {
    _name: "education",
    _display_name: "Education",
    type: [
      { _name: "primary", type: [{ _name: "kindergarten" }] },
      { _name: "school", type: [{ _name: "school" }] },
      {
        _name: "college",
        type: [{ _name: "college" }, { _name: "university" }],
      },
      {
        _name: "other",
        type: [
          { _name: "music_school" },
          { _name: "driving_school" },
          { _name: "language_school" },
          { _name: "educational_institution" },
          { _name: "library" },
          { _name: "public_bookcase" },
        ],
      },
    ],
  },
  {
    _name: "transportation",
    _display_name: "Transportation",
    type: [
      {
        _name: "transport",
        type: [
          { _name: "bicycle" },
          { _name: "bicycle_rental" },
          { _name: "boat_sharing" },
          { _name: "bus_station" },
          { _name: "bus_stop" },
          { _name: "train_station" },
          { _name: "car" },
          { _name: "car_rental" },
          { _name: "carport" },
          { _name: "car_sharing" },
          { _name: "ferry_terminal" },
          { _name: "taxi" },
          { _name: "motorcycle" },
          { _name: "transportation" },
        ],
      },
      {
        _name: "parking",
        type: [
          { _name: "bicycle_parking" },
          { _name: "motorcycle_parking" },
          { _name: "parking" },
          { _name: "parking_entrance" },
          { _name: "parking_space" },
        ],
      },
      {
        _name: "services",
        type: [
          { _name: "bicycle_repair_station" },
          { _name: "fuel" },
          { _name: "grit_bin" },
          { _name: "charging_station" },
          { _name: "ev_charging" },
          { _name: "garage" },
          { _name: "garages" },
          { _name: "car_parts" },
          { _name: "car_repair" },
          { _name: "car_wash" },
        ],
      },
      //   {
      //     _name: "other",
      //   },
    ],
  },
  {
    _name: "entertainment",
    _display_name: "Entertainment",
    type: [
      {
        _name: "activities",
        type: [
          { _name: "casino" },
          { _name: "gambling" },
          { _name: "brothel" },
          { _name: "nightclub" },
          { _name: "swingerclub" },
          { _name: "studio" },
        ],
      },
      {
        _name: "performance",
        type: [
          { _name: "arts_centre" },
          { _name: "cinema" },
          { _name: "theatre" },
          { _name: "stripclub" },
          { _name: "community_centre" },
          { _name: "planetarium" },
          { _name: "social_centre" },
        ],
      },
      //   {
      //     _name: "other",
      //   },
    ],
  },
  {
    _name: "residential",
    _display_name: "Residential",
    type: [
      {
        _name: "homes",
        type: [
          { _name: "apartment" },
          { _name: "house" },
          { _name: "society" },
          { _name: "residential" },
          { _name: "dormitory" },
          { _name: "hostel" },
          { _name: "terrace" },
          { _name: "houseboat" },
          { _name: "bungalow" },
          { _name: "static_caravan" },
        ],
      },
      {
        _name: "shelter",
        type: [
          { _name: "animal_boarding" },
          { _name: "animal_shelter" },
          { _name: "shelter" },
          { _name: "rescue_station" },
          { _name: "watering_place" },
        ],
      },
      {
        _name: "amenities",
        type: [
          { _name: "bakehouse" },
          { _name: "supermarket" },
          { _name: "department_store" },
          { _name: "provision_store" },
          { _name: "kiosk" },
          { _name: "dairy" },
          { _name: "deli" },
          { _name: "butcher" },
          { _name: "spices" },
          { _name: "houseware" },
          { _name: "garden_furniture" },
          { _name: "jewelry" },
          { _name: "baby_goods" },
          { _name: "spices" },
          { _name: "laundry" },
          { _name: "Party hall" },
          { _name: "electrical" },
          { _name: "convenience" },
        ],
      },
      {
        _name: "activities",
        type: [
          { _name: "playground" },
          { _name: "swimming_area" },
          { _name: "water_park" },
          { _name: "sports_centre" },
          { _name: "pitch" },
          { _name: "picnic_table" },
          { _name: "park" },
          { _name: "fountain" },
          { _name: "garden" },
          { _name: "amusement_arcade" },
          { _name: "adult_gaming_centre" },
          { _name: "dog_park" },
          { _name: "bench" },
          { _name: "sauna" },
          { _name: "shower" },
          { _name: "firepit" },
          { _name: "dojo" },
          { _name: "dive_centre" },
          { _name: "gym" },
          { _name: "game_feeding" },
          { _name: "hunting_stand" },
          { _name: "stands" },
          { _name: "public" },
          { _name: "civic" },
          { _name: "attraction" },
          { _name: "stadium" },
          { _name: "swimming_pool" },
          { _name: "sports" },
          { _name: "recreation_ground" },
          { _name: "basketball" },
        ],
      },
      {
        _name: "religious",
        type: [
          { _name: "crypt" },
          { _name: "place_of_worship" },
          { _name: "cathedral" },
          { _name: "chapel" },
          { _name: "church" },
          { _name: "mosque" },
          { _name: "synagogue" },
          { _name: "shrine" },
          { _name: "temple" },
        ],
      },
      {
        _name: "other",
        type: [
          { _name: "clock" },
          { _name: "kneipp_water_cure" },
          { _name: "photo_booth" },
          { _name: "toilets" },
          { _name: "telephone" },
          { _name: "vending_machine" },
          { _name: "water_point" },
          { _name: "waste_basket" },
          { _name: "farm" },
          { _name: "detached" },
          { _name: "crematorium" },
          { _name: "grave_yard" },
          { _name: "post_box" },
        ],
      },
    ],
  },
  {
    _name: "commercial",
    _display_name: "Commercial",
    type: [
      {
        _name: "healthcare",
        type: [
          { _name: "baby_hatch" },
          { _name: "clinic" },
          { _name: "dentist" },
          { _name: "doctors" },
          { _name: "nursing_home" },
          { _name: "pharmacy" },
          { _name: "social_facility" },
          { _name: "veterinary" },
          { _name: "blood_donation" },
          { _name: "hospital" },
        ],
      },
      {
        _name: "work",
        type: [
          { _name: "courthouse" },
          { _name: "embassy" },
          { _name: "fire_station" },
          { _name: "coworking_space" },
          { _name: "internet_cafe" },
          { _name: "marketplace" },
          { _name: "police" },
          { _name: "post_office" },
          { _name: "recycling" },
          { _name: "townhall" },
          { _name: "ranger_station" },
          { _name: "waste_transfer_station" },
          { _name: "public_building" },
          { _name: "company" },
          { _name: "association" },
          { _name: "advertising_agency" },
          { _name: "government" },
          { _name: "foundation" },
          { _name: "administrative" },
          { _name: "architect" },
          { _name: "travel_agency" },
          { _name: "estate_agent" },
          { _name: "money_lender" },
          { _name: "office" },
        ],
      },
      {
        _name: "financial",
        type: [{ _name: "atm" }, { _name: "bank" }, { _name: "financial" }, { _name: "bureau_de_change" }],
      },
      {
        _name: "shopping",
        type: [
          { _name: "commercial" },
          { _name: "retail" },
          { _name: "kiosk" },
          { _name: "bag" },
          { _name: "beauty" },
          { _name: "beauty_salon" },
          { _name: "hairdresser" },
          { _name: "boutique" },
          { _name: "clothes" },
          { _name: "electronics" },
          { _name: "shoes" },
          { _name: "furniture" },
          { _name: "hardware" },
          { _name: "gift" },
          { _name: "mobile_phone" },
          { _name: "mall" },
        ],
      },
      {
        _name: "accommodation",
        type: [{ _name: "hotel" }, { _name: "motel" }],
      },
      //   {
      //     _name: "other",
      //   },
    ],
  },
  {
    _name: "industrial",
    _display_name: "Industrial",
    type: [
      {
        _name: "crafts",
        type: [
          { _name: "basket_maker" },
          { _name: "blacksmith" },
          { _name: "carpenter" },
          { _name: "bookbinder" },
          { _name: "clockmaker" },
          { _name: "dressmaker" },
          { _name: "electrician" },
          { _name: "engraver" },
          { _name: "handicraft" },
          { _name: "locksmith" },
          { _name: "piano_tuner" },
          { _name: "jeweller" },
          { _name: "optician" },
          { _name: "photographer" },
          { _name: "pottery" },
          { _name: "sculptor" },
          { _name: "shoemaker" },
          { _name: "tailor" },
          { _name: "watchmaker" },
          { _name: "stonemason" },
          { _name: "toolmaker" },
        ],
      },
      {
        _name: "manufacturing",
        type: [
          { _name: "agricultural_engines" },
          { _name: "boatbuilder" },
          { _name: "brewery" },
          { _name: "distillery" },
          { _name: "winery" },
          { _name: "window_construction" },
        ],
      },
      {
        _name: "other",
        type: [{ _name: "industrial" }, { _name: "warehouse" }],
      },
    ],
  },
];

/** function to get the poi distribution  percentage
 *
 * @param {*} poiCounts => object containing count of pois
 *
 * @returns
 */
export function getPoiDistribution(poiCounts, categories = poiCategories) {
  if (Object.keys(poiCounts).length > 0) {
    // Create a mapping object for categories and their types
    const mappingObject = {};
    categories.forEach((category) => {
      category.type.forEach((subType) => {
        subType.type.forEach((type) => {
          mappingObject[type._name] = {
            category: category._name,
          };
        });
      });
    });

    // Categorize the data
    const poiDistribution = {
      commercial: 0,
      industrial: 0,
      education: 0,
      residential: 0,
      entertainment: 0,
      sustenance: 0,
      transportation: 0,
    };
    //grouping  the poi count according to their category
    Object.keys(poiCounts).forEach((key) => {
      const type = mappingObject[key];

      if (type) {
        poiDistribution[type.category] += poiCounts[key];
      }
    });

    // storing the  values of poiCounts in an array
    const poiCountsValuesArray = Object.values(poiCounts);
    // calculating total no of pois
    const totalPoiCount = poiCountsValuesArray.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    // calculating the percentage
    for (const key of Object.keys(poiDistribution)) {
      poiDistribution[key] = Math.round((poiDistribution[key] * 100) / totalPoiCount);
    }

    return poiDistribution;
  }

  return {};
}
