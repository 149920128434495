// URLS
import {
  getV2PinCodeUrl,
  getV2WardUrl,
} from "../../urls/map-view/BoundariesURL";

// Constants and Utils
import { sendGetRequest } from "../../utils/api/apiUtils";
import boundaries from "../../utils/boundaries.json";
import { DefaultPagination } from "../../constants/GeneralConstants";

// Sdk
import AtlasClient from "../../sdk/AtlasClient";

/**
 * Used to convert the array of objects into array of arrays
 * @param {*} boundaries =>array of objects containing latitude,longitude info
 * @returns array of arrays containing longitude,latitude
 */
function getCoordinates(boundaries) {
  const coordinates = boundaries.reduce((acc, latlongData) => {
    acc.push([latlongData.longitude, latlongData.latitude]);
    return acc;
  }, []);
  return coordinates;
}

function extractPolygonData(geoData) {
  const polygonData = geoData.reduce((acc, data) => {
    acc.push({
      type: "Feature",
      properties: data.properties,
      geometry: {
        coordinates: [getCoordinates(data.boundary)],
        type: "Polygon",
      },
    });
    return acc;
  }, []);
  return { type: "FeatureCollection", features: polygonData };
}

export async function getWardInfo(cityId) {
  const url = getV2WardUrl.replace(":cityId", cityId);
  const response = await sendGetRequest(url);
  const polygonData = extractPolygonData(response.result);
  return { polygonData };
}

export async function getPinCodeInfo(cityId) {
  const url = getV2PinCodeUrl.replace(":cityId", cityId);
  const response = await sendGetRequest(url);
  const polygonData = extractPolygonData(response.result);
  return { polygonData };
}

export async function regionsWithinBBox(bbox, type) {
  const bboxArray = bbox.split(",");
  const nw = `${bboxArray[3]},${bboxArray[0]}`;
  const se = `${bboxArray[1]},${bboxArray[2]}`;

  const pn = DefaultPagination.pageNumber;
  const ps = DefaultPagination.pageSize;

  const response = await AtlasClient.regionsWithinBBox(nw, se, type, pn, ps);
  const polygonData = extractPolygonData(response.items);

  return { polygonData };
}

export function getBoundaries(cityId) {
  return boundaries;
}
