import React from "react";

// Utils
import { constructPieChartData } from "../../../utils/ChartUtils";

// Components
import { PieChart } from "../../../components/charts/Charts";

/**
 * Location Summary PIE-CHART
 * @param {*} poiDistribution =>object containing information about percentage of  poi distribution
 *
 */
export default function LocationSummaryPieChart({
  title = "",
  className = "",
  poiDistribution,
}) {
  if (!poiDistribution || Object.keys(poiDistribution).length === 0) {
    return null;
  }

  // this return True/False ===> if no Poi data then False
  const isPoiDistDataPresent = Object.keys(poiDistribution).some(
    (key) => poiDistribution[key] !== 0
  );

  if (!isPoiDistDataPresent) {
    return null;
  }

  const chartData = constructPieChartData(poiDistribution);

  // Body which to be rendered
  const componentBody = (
    <>
      {title && <h4>{title}</h4>}
      <PieChart data={chartData} />
    </>
  );

  //  Pie chart Rendering
  if (!className) {
    return componentBody;
  }
  return <div className={className}>{componentBody}</div>;
}
