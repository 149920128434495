import { all, put, takeLatest, select, delay } from "redux-saga/effects";
import { toast } from "react-toastify";
import DomToImage from "dom-to-image";

// Constants and Utils
import {
  DefaultPagination,
  RegionTypes,
} from "../../constants/GeneralConstants";
import { getErrorMessage } from "../../utils/util";
import { setErrorStatusCode } from "../../utils/ErrorUtils";
import { MapView, Status } from "../../constants/GeneralConstants";
import { TargetGroup } from "../../constants/action-constants/TargetGroupActionConstants";
import { DataLayer } from "../../constants/action-constants/DataLayerActionConstants";
import { GeoData } from "../../constants/action-constants/GeoDataActionConstants";
import {
  getMergedCityBBox,
  getMergedCityId,
} from "../../pages/map-view/CityInfoTempFixUtil";
import { Boundaries } from "../../constants/action-constants/map-view/BoundariesActionConstants";
import { SaveMapState } from "../../constants/action-constants/map-view/SaveMapStateActionConstants";
import { MetaData } from "../../constants/action-constants/map-view/MetaDataActionConstants";
import { MediaSites } from "../../constants/action-constants/map-view/media-sites/MediaSitesActionConstants";
import { ExploreSites } from "../../constants/action-constants/map-view/media-sites/ExploreSitesActionConstants";
import { Poi } from "../../constants/action-constants/map-view/poi/PoiActionConstants";
import { RoadStretch } from "../../constants/action-constants/RoadStretchActionConstants";
import { Sec } from "../../constants/action-constants/map-view/SecActionConstants";
import {
  LegendMediaSitesActions,
  MediaInfluenceCircleMarkers,
} from "../../constants/action-constants/map-view/media-sites/LegendAndInfluenceActionConstants";

// API
import { getRegionDataByCityId } from "../../apis/RegionAPI";
import {
  getSavedMapState,
  saveMapState as saveMapStateFunc,
} from "../../apis/map-view/SaveMapStateAPI";
import { LocationOfIntrest } from "../../mantaray/action-constants/LocationOfIntrestActionConstants";

// Functions
function* getSelectedBoundaries() {
  const selectedLabel = yield select((state) => state.boundaries.selectedLabel);

  const { maxColor, noOfSteps } = yield select(
    (state) => state.boundaries.boundaryMapSettings
  );

  const selection = selectedLabel.split("_");

  const _type = selection[0];

  // TODO: As of now we are not storing the SEC information in backend
  // Once SEC moves to Target-Group Tab, we will be storing the SEC information
  // also  as heatMap/points, etc..
  if (_type === "None" || _type === "SEC") {
    return {};
  }
  const _subType = selection.length > 1 ? selection[1] : "";
  return {
    boundaryType: _type,
    boundaryProp: _subType,
    boundaryColorStart: maxColor,
    boundarySteps: noOfSteps,
  };
}

function* getSelectedPoi() {
  const brandIds = yield select((state) => state.poiSelection.brandIds);
  const brandToSectorMap = yield select((state) => state.poi.brandToSectorMap);
  const poiState = {};
  Object.keys(brandIds).forEach((brandId) => {
    const sectorId = brandToSectorMap[brandId];
    if (!poiState[sectorId]) {
      poiState[sectorId] = [brandId];
      return;
    }
    poiState[sectorId].push(brandId);
  });
  return poiState;
}

function getSelectedPoiLabels(brandIdToLabel, sectorIdToLabel, poiBrandIds) {
  const sectorLabels = [];
  const sectorIds = [];
  const brandLabels = [];
  const brandIds = [];
  for (const eachSectorId in poiBrandIds) {
    if (poiBrandIds[eachSectorId].length < 1) {
      sectorLabels.push(sectorIdToLabel[eachSectorId]);
      sectorIds.push(eachSectorId);
    } else {
      poiBrandIds[eachSectorId].forEach((eachBrandId) => {
        brandLabels.push(brandIdToLabel[eachBrandId]);
        brandIds.push(eachBrandId);
      });
    }
  }
  return { sectorLabels, sectorIds, brandLabels, brandIds };
}

function* getSelectedDataLayers() {
  const selectedDataLayers = yield select((state) =>
    Object.keys(state.dataLayersSelection.isLayerSelected).reduce(
      (acc, eachDataLayerId) => {
        if (!state.dataLayersSelection.isLayerSelected[eachDataLayerId]) {
          return acc;
        }
        acc.push(eachDataLayerId);
        return acc;
      },
      []
    )
  );
  return selectedDataLayers;
}

function* getSelectedMetaData() {
  // Basically in backend we are not saving the metaDataDisplay names
  // rather we are storing respective keys --> so in future even if the display
  // changes we dont need to be worried..
  const selectedMetaData = yield select((state) =>
    Object.keys(state.mapMetaData.metaData).reduce((acc, eachMetadataKey) => {
      if (!state.mapMetaData.metaData[eachMetadataKey]) {
        return acc;
      }
      acc.push(MapView.MetaDataToKeys[eachMetadataKey]);
      return acc;
    }, [])
  );
  return selectedMetaData;
}

function* constructMapImageBase64() {
  const mapHeight = yield select((state) => state.saveMapState.mapHeight);
  const mapWidth = yield select((state) => state.saveMapState.mapWidth);
  const mapContainer = yield select(
    (state) => state.saveMapState.mapContainer._container
  );
  // Generate base64 data from mapContainer
  const mapBase64 = yield DomToImage.toJpeg(mapContainer, {
    quality: 0.85,
    width: mapWidth,
    height: mapHeight,
  });

  return mapBase64;
}

export function* saveMapState(action) {
  try {
    const { campaignId, cityId, saveImage } = action.payload;
    const mapState = { cityId: cityId, campaignId: campaignId };

    // Map Image Base64
    if (saveImage) {
      mapState.imageBodyAsBase64 = yield constructMapImageBase64();
    }

    // Map Center
    const mapCenter = yield select((state) => state.saveMapState.center);
    mapState.centerLatLng = mapCenter.join();

    // Map Zoom
    const mapZoom = yield select((state) => state.saveMapState.zoom);
    mapState.zoomLevel = mapZoom;

    // Media details --------------------------------------------
    const mediaTypeToShow = yield select(
      (state) => state.legendAndInfluence.mediaTypeToShow
    );
    const metaDataKeys = yield getSelectedMetaData();
    mapState.showCampaignMedia = mediaTypeToShow.campaignMedia;
    mapState.showInventoryMedia = mediaTypeToShow.inventoryMedia;
    mapState.mediaDisplayProps = metaDataKeys;
    // MediaRadar and MediaRadarRadius
    const showMediaRadar = yield select(
      (state) => state.legendAndInfluence.showMediaInfluenceCircle
    );
    mapState.showMediaRadar = showMediaRadar;
    if (showMediaRadar) {
      const mediaRadarRadiusMtrs = yield select(
        (state) => state.legendAndInfluence.mediaInfluenceCircleRadius
      );
      mapState.mediaRadarRadiusMtrs = mediaRadarRadiusMtrs;
    }

    // MediaSites Fill Color
    const campaignMediaFillColor = yield select(
      (state) => state.mapViewMediaSites.campaignMediaFillColor
    );
    const inventoryMediaFillColor = yield select(
      (state) => state.mapViewExploreSites.inventoryMediaFillColor
    );
    mapState.campaignMediaFillColor = campaignMediaFillColor;
    mapState.inventoryMediaFillColor = inventoryMediaFillColor;

    // LocationOfIntrestRadar LocationOfIntrestRadarRadius

    const IsLocationOfIntrestRadorVisible = yield select(
      (state) =>
        state.locationOfIntrest.IsLocationOfIntrestInfluenceCircleVisible
    );

    const productId = process.env.REACT_APP_PRODUCT_ID;
    if (productId) {
      mapState.IsLocationOfIntrestRadorVisible =
        IsLocationOfIntrestRadorVisible;
      if (IsLocationOfIntrestRadorVisible) {
        const locationOfIntrestRadorRadiusMtrs = yield select(
          (state) =>
            state.locationOfIntrest.locationOfIntrestInfluenceCircleRadius
        );
        mapState.locationOfIntrestRadorRadiusMtrs =
          locationOfIntrestRadorRadiusMtrs;
      }
    }

    // CampaignMedia Filter Object
    const campaignMediaFilterObj = yield select(
      (state) => state.mapViewMediaSites.campaignMediaFilterObj
    );
    mapState.campaignMediaFilters = campaignMediaFilterObj;

    // InventoryMedia Filter Object
    const inventoryMediaFilterObj = yield select(
      (state) => state.mapViewExploreSites.inventoryMediaFilterObj
    );
    mapState.inventoryMediaFilters = inventoryMediaFilterObj;

    // DataLayers ------------------------------------------------
    const selectedDataLayers = yield getSelectedDataLayers();
    mapState.dataLayerIds = selectedDataLayers;
    // TODO: Add dataLayerDisplayTypeStr, dataLayerColorStart, dataLayerColorEnd, dataLayerSteps

    // Map Type
    mapState.mapType = "REPORT";

    const poiState = yield getSelectedPoi();
    const showPoiRadar = yield select(
      (state) => state.poiSelection.showInfluenceCircle
    );
    mapState.poiBrandIds = poiState;
    mapState.showPoiRadar = showPoiRadar;
    if (showPoiRadar) {
      const poiRadarRadiusMtrs = yield select(
        (state) => state.poiSelection.influenceCircleRadius
      );
      mapState.poiRadarRadiusMtrs = poiRadarRadiusMtrs;
    }
    // TODO:poiDisplayTypeStr

    // Boundaries
    const { boundaryType, boundaryProp, boundaryColorStart, boundarySteps } =
      yield getSelectedBoundaries();
    if (boundaryType && boundaryProp) {
      mapState.boundaryType = boundaryType;
      mapState.boundaryProp = boundaryProp;
      mapState.boundaryColorStart = boundaryColorStart;
      mapState.boundarySteps = boundarySteps;
    }

    // TargetGroup :: SEC --------------------------------------
    // state.sec.selectedSec = {SEC_Sec B: true, SEC_Sec A+: true}
    const selectedSecTypesArray = yield select((state) =>
      Object.keys(state.sec.selectedSec)
    );
    mapState.secTargetGroups = selectedSecTypesArray;

    // TG :: TargetGroup Id ------------------------------------------------
    mapState.targetGroupId = yield select(
      (state) => state.orgTargetGroup.selectedTgId
    );

    // RoadStretches ----------------------------------------------
    // Selected Stretches Ids
    const selectedStretchIds = yield select((state) =>
      Object.keys(state.roadStretchesSelection.selectedStretchesMapView)
    );
    mapState.roadStretchIds = selectedStretchIds;

    //making ajax call
    yield saveMapStateFunc(campaignId, cityId, mapState);

    //dispatching action
    yield put({
      type: SaveMapState.Save.SAVE_MAP_STATE_SUCCESS,
    });
    toast.success("Map State is saved");
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    yield put({
      type: SaveMapState.Save.SAVE_MAP_STATE_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

function restoreMediaSitesAction(campaignId, cityId) {
  // TODO: Need to handle PageSize once design's are finalized..
  return put({
    type: MediaSites.GET_MEDIA_SITES,
    payload: {
      id: campaignId,
      cityId,
      pn: DefaultPagination.pageNumber,
      ps: DefaultPagination.pageSize,
    },
  });
}

function restoreCustomDataLayersAction() {
  // TODO: Need to handle PageSize once design's are finalized..
  return put({
    type: DataLayer.GET_CUSTOM_DATA_LAYERS,
    payload: {
      isArchived: false,
      pageNumber: DefaultPagination.pageNumber,
      pageSize: DefaultPagination.pageSize,
      keyWord: "",
    },
  });
}

function restoreBoundariesAction(cityId) {
  return put({
    type: Boundaries.GET_BOUNDARIES,
    payload: { cityId },
  });
}

function restoreTargetGroupAction() {
  return put({
    type: TargetGroup.GET_TARGET_GROUPS,
    payload: {
      isArchived: false,
      keyWord: "",
      pageNumber: DefaultPagination.pageNumber,
      pageSize: DefaultPagination.pageSize,
    },
  });
}

function restorePoiAction(cityId) {
  return put({
    type: Poi.GET_POI,
    payload: { cityId },
  });
}

function restoreDataLayerDetails(customDataLayerIds) {
  // TODO : Optimize this rather than dispatching each action of each dataLayerId
  //        Rather we ca have a separate function all together to handle the case
  //        of fetching dataPoints for multiple dataLayerIds

  return customDataLayerIds.map((eachDataLayerId) =>
    put({
      type: DataLayer.GET_DATA_LAYER_DETAILS,
      payload: {
        id: eachDataLayerId,
      },
    })
  );
}

function restoreCityRoadStretchesAction(savedState) {
  const { roadStretchIds, cityId } = savedState;
  // if NO road-stretches are saved then dont call any API
  if (roadStretchIds.length === 0) {
    return;
  }

  // Api to get all unarchived road-stretches of city
  return put({
    type: RoadStretch.GET_ROAD_STRETCHES_OF_ORG,
    payload: { isArchived: false, cityId },
  });
}

// we will Restore the Selection of Road-Stretches
function restoreRoadStretchSelectionAction(roadStretchIds) {
  const selectActions = [];
  selectActions.push(
    put({
      type: RoadStretch.ADD_ALL_SELECTED_ROAD_STRETCHES,
      payload: { roadStretchIds },
    })
  );
  return selectActions;
}

function restoreSelectedSecAction(savedState, regionData) {
  const { secTargetGroups } = savedState;

  const selectActions = [];
  // No API call if there is no SEC is saved
  if (!secTargetGroups || secTargetGroups.length === 0) {
    return selectActions;
  }

  const { id: cityId, bbox } = regionData;
  const mergedCityBBox = getMergedCityBBox(cityId, bbox);

  secTargetGroups.forEach((sec) => {
    selectActions.push(
      // to get all Saved-SEC Info
      put({
        type: Sec.GET_SEC_INFO,
        payload: { cityBBox: mergedCityBBox, sec },
      }),
      // to Select All Saved-SEC
      put({
        type: Sec.SELECT_SEC,
        payload: { sec },
      })
    );
  });

  return selectActions;
}

function restoreMediaLegendActions(savedMapState) {
  const { showCampaignMedia, showInventoryMedia } = savedMapState;
  if (showCampaignMedia && showInventoryMedia) {
    return [];
  }

  const actions = [];
  if (showCampaignMedia === false) {
    actions.push(
      put({
        type: LegendMediaSitesActions.REMOVE_MEDIA_MARKERS_FROM_LEGEND,
        payload: { id: "campaignMedia" },
      })
    );
  }

  if (showInventoryMedia === false) {
    actions.push(
      put({
        type: LegendMediaSitesActions.REMOVE_MEDIA_MARKERS_FROM_LEGEND,
        payload: { id: "inventoryMedia" },
      })
    );
  }

  return actions;
}

function restoreMediaInfluenceDetails(savedMapState) {
  const { mediaRadarRadiusMtrs, showMediaRadar } = savedMapState;
  if (!mediaRadarRadiusMtrs && !showMediaRadar) {
    return [];
  }

  const actions = [];
  actions.push(
    put({
      type: MediaInfluenceCircleMarkers.SET_MEDIA_INFLUENCE_CIRCLE_RADIUS,
      payload: { radius: mediaRadarRadiusMtrs },
    })
  );
  actions.push(
    put({
      type: showMediaRadar
        ? MediaInfluenceCircleMarkers.SHOW_MEDIA_INFLUENCE_CIRCLE
        : MediaInfluenceCircleMarkers.REMOVE_MEDIA_INFLUENCE_CIRCLE,
    })
  );

  return actions;
}

function restoreMediaMarkersFillColors(savedState) {
  const { campaignMediaFillColor, inventoryMediaFillColor } = savedState;
  if (!campaignMediaFillColor && !inventoryMediaFillColor) {
    return [];
  }

  const actions = [];
  actions.push(
    put({
      type: MediaSites.SET_CAMPAIGN_MEDIA_FILL_COLOR,
      payload: { color: campaignMediaFillColor },
    })
  );
  actions.push(
    put({
      type: ExploreSites.SET_INVENTORY_MEDIA_FILL_COLOR,
      payload: { color: inventoryMediaFillColor },
    })
  );

  return actions;
}

function restoreCampaignMediaFilters(savedState) {
  const { campaignMediaFilters } = savedState;

  const actions = [];
  actions.push(
    put({
      type: MediaSites.SET_CAMPAIGN_MEDIA_FILTERS,
      payload: { appliedFiltersObj: campaignMediaFilters },
    })
  );

  return actions;
}

function restoreInventoryMediaFilters(savedState) {
  const { inventoryMediaFilters } = savedState;

  const actions = [];
  actions.push(
    put({
      type: ExploreSites.SET_INVENTORY_MEDIA_FILTERS,
      payload: { appliedFiltersObj: inventoryMediaFilters },
    })
  );

  return actions;
}

function restorePoiInfluenceDetails(savedMapState) {
  const { poiRadarRadiusMtrs, showPoiRadar } = savedMapState;
  if (!poiRadarRadiusMtrs && !showPoiRadar) {
    return [];
  }

  const actions = [];
  actions.push(
    put({
      type: Poi.SET_INFLUENCE_CIRCLE_RADIUS,
      payload: { radius: poiRadarRadiusMtrs },
    })
  );
  actions.push(
    put({
      type: showPoiRadar
        ? Poi.SHOW_POI_INFLUENCE_CIRCLE_MARKERS
        : Poi.REMOVE_POI_INFLUENCE_CIRCLE_MARKERS,
    })
  );

  return actions;
}

function restoreLocationOfIntrestInfluenceDetails(savedMapState) {
  const { locationOfIntrestRadorRadiusMtrs, IsLocationOfIntrestRadorVisible } =
    savedMapState;
  if (!locationOfIntrestRadorRadiusMtrs && !IsLocationOfIntrestRadorVisible) {
    return [];
  }

  const actions = [];
  actions.push(
    put({
      type: LocationOfIntrest.SET_INFLUENCE_CIRCLE_RADIUS,
      payload: { radius: locationOfIntrestRadorRadiusMtrs },
    })
  );
  actions.push(
    put({
      type: IsLocationOfIntrestRadorVisible
        ? LocationOfIntrest.SHOW_LOCATION_OF_INTREST_CIRCLE_MARKERS
        : LocationOfIntrest.REMOVE_LOCATION_OF_INTREST_CIRCLE_MARKERS,
    })
  );

  return actions;
}

function restoreDataLayersSelectionAction(customDataLayerIds) {
  return put({
    type: DataLayer.DATA_LAYER_SELECT_MULTIPLE,
    payload: {
      ids: customDataLayerIds,
    },
  });
}

function* restoreMetaDataSelectionAction(mediaDisplayProperties) {
  // Basically in backend we are not saving the metaDataDisplay names
  // rather we are storing respective keys --> so in future even if the display
  // changes we dont need to be worried..
  const metaData = yield select((state) => state.mapMetaData.metaData);
  const metaDataKeys = mediaDisplayProperties.reduce((acc, eachKey) => {
    acc.push(MapView.MetaData[eachKey]);
    return acc;
  }, []);
  Object.keys(metaData).map((metadataKey) => {
    metaData[metadataKey] = metaDataKeys.includes(metadataKey) ? true : false;
  });

  return put({
    type: MetaData.ASSIGN,
    payload: { metaData: metaData },
  });
}

function restoreBoundariesSelectionAction(savedState, bbox) {
  const { boundaryType, boundaryProp, boundaryColorStart, boundarySteps } =
    savedState;

  if (boundaryType === "None") {
    return [];
  }
  const selectActions = [];
  const selectedLabel = `${boundaryType}_${boundaryProp}`;

  selectActions.push(
    put({
      type: Boundaries.SET_RADIO_STATUS,
      payload: {
        id: selectedLabel,
        status: Status.CHECKED,
      },
    })
  );

  // restoring boundary max color
  selectActions.push(
    put({
      type: Boundaries.SET_MAX_COLOR,
      payload: boundaryColorStart,
    })
  );

  // restoring boundary steps
  selectActions.push(
    put({
      type: Boundaries.SET_BOUNDARY_STEPS,
      payload: boundarySteps,
    })
  );

  // TODO: For temporary fix of cityIds for Delhi and mumbai we are hardcoading the
  // their Ids and BBOX
  if (boundaryType === Boundaries.MAPPING.Ward) {
    // selectActions.push(
    //   put({
    //     type: Boundaries.GET_WARD_INFO,
    //     payload: {
    //       cityId: getMergedCityId(cityId),
    //     },
    //   })
    // );
    selectActions.push(
      put({
        type: Boundaries.GET_REGION_INFO,
        payload: { bbox, type: RegionTypes.Ward },
      })
    );

    return selectActions;
  }

  // TODO: For temporary fix of cityIds for Delhi and mumbai we are hardcoading the
  // their Ids and BBOX
  else if (boundaryType === Boundaries.MAPPING.Pincode) {
    // selectActions.push(
    //   put({
    //     type: Boundaries.GET_PINCODE_INFO,
    //     payload: {
    //       cityId: getMergedCityId(cityId),
    //     },
    //   })
    // );
    selectActions.push(
      put({
        type: Boundaries.GET_REGION_INFO,
        payload: { bbox, type: RegionTypes.Pincode },
      })
    );
    return selectActions;
  }
}

function restorePoiSelectionAction(
  savedState,
  cityId,
  brandIdToLabel,
  sectorIdToLabel
) {
  const selectActions = [];
  const { brandLabels, brandIds } = getSelectedPoiLabels(
    brandIdToLabel,
    sectorIdToLabel,
    savedState.poiBrandIds
  );

  brandLabels.forEach((eachBrandLabel) =>
    selectActions.push(
      put({
        type: Poi.SET_CHECKBOX_STATUS,
        payload: { label: eachBrandLabel, status: Status.CHECKED },
      })
    )
  );

  // // Add Poi markers
  brandIds.forEach((eachBrandId) => {
    selectActions.push(
      put({
        type: Poi.GET_BRAND_INFO,
        payload: { cityId, brandId: eachBrandId },
      })
    );
  });

  return selectActions;
}

function* restoreTgSelectionAction(tgId, bbox) {
  const selectActions = [];
  selectActions.push(
    put({
      type: TargetGroup.SET_SELECTED_TG_ID,
      payload: { tgId },
    })
  );

  const targetGroupsList = yield select((state) => state.orgTargetGroup.tgList);
  const tgInfo = targetGroupsList.find((eachTgInfo) => eachTgInfo.id === tgId);
  const { resultLayers = [] } = tgInfo;

  const resPoiLayers = resultLayers.map((eachLayer) => eachLayer.poiTypeId);
  selectActions.push(
    put({
      type: GeoData.GET_POI_TYPE_LAYER_DATA,
      payload: {
        resPoiLayers,
        bbox,
      },
    })
  );

  return selectActions;
}

export function* restoreMapState(action) {
  try {
    const { campaignId, cityId } = action.payload;
    const savedState = yield getSavedMapState(campaignId, cityId);

    const regionData = yield getRegionDataByCityId(cityId);

    if (!savedState) {
      yield put({
        type: SaveMapState.Restore.RESTORE_SAVE_MAP_STATE_SUCCESS,
        payload: {
          centerLatLng: `${regionData.center.latitude},${regionData.center.longitude}`,
          cityBBox: regionData.bbox,
          cityName: regionData.name,
        },
      });
      return;
    }

    // Store all the actions to be executed in the array.
    let getDetailsActions = [];
    getDetailsActions.push(restoreMediaSitesAction(campaignId, cityId));
    getDetailsActions.push(restoreCustomDataLayersAction());
    getDetailsActions.push(restorePoiAction(getMergedCityId(cityId)));
    getDetailsActions.push(restoreBoundariesAction(cityId));
    getDetailsActions.push(restoreTargetGroupAction());
    getDetailsActions = getDetailsActions.concat(
      restoreDataLayerDetails(savedState.dataLayerIds)
    );
    getDetailsActions = getDetailsActions.concat(
      restoreMediaLegendActions(savedState)
    );
    getDetailsActions = getDetailsActions.concat(
      restorePoiInfluenceDetails(savedState)
    );
    getDetailsActions = getDetailsActions.concat(
      restoreMediaInfluenceDetails(savedState)
    );
    getDetailsActions = getDetailsActions.concat(
      restoreMediaMarkersFillColors(savedState)
    );
    getDetailsActions = getDetailsActions.concat(
      restoreCampaignMediaFilters(savedState)
    );
    getDetailsActions = getDetailsActions.concat(
      restoreInventoryMediaFilters(savedState)
    );

    getDetailsActions = getDetailsActions.concat(
      restoreLocationOfIntrestInfluenceDetails(savedState)
    );
    // to get all Road-Stretches of City
    getDetailsActions.push(restoreCityRoadStretchesAction(savedState));

    // Dispatch all the actions asynchronously
    yield all(getDetailsActions);

    // Select all the Media Sites, custom DataLayers, MetaData, POI, Boundaries, TargetGroup
    let selectActions = [];
    selectActions.push(
      restoreDataLayersSelectionAction(savedState.dataLayerIds)
    );
    selectActions.push(
      restoreMetaDataSelectionAction(savedState.mediaDisplayProps)
    );
    if (savedState.boundaryType) {
      const boundariesSelections = restoreBoundariesSelectionAction(
        savedState,
        regionData.bbox
      );
      if (boundariesSelections.length > 0) {
        selectActions = selectActions.concat(boundariesSelections);
      }
    }

    // We pool till the POI info is generated..
    let getPoiLoading = yield select((state) => state.poi.getPoiLoading);
    while (getPoiLoading) {
      getPoiLoading = yield select((state) => state.poi.getPoiLoading);
      yield delay(1000);
    }
    const brandIdToLabel = yield select((state) => state.poi.brandIdToLabel);
    const sectorIdToLabel = yield select((state) => state.poi.sectorIdToLabel);
    selectActions = selectActions.concat(
      restorePoiSelectionAction(
        savedState,
        getMergedCityId(cityId),
        brandIdToLabel,
        sectorIdToLabel
      )
    );

    // we pool till the targetGroups is generated
    if (savedState.targetGroupId) {
      let targetGroupsList = yield select(
        (state) => state.orgTargetGroup.tgList
      );
      while (targetGroupsList.length < 1) {
        yield delay(1000);
        targetGroupsList = yield select((state) => state.orgTargetGroup.tgList);
      }
      selectActions = selectActions.concat(
        yield restoreTgSelectionAction(
          savedState.targetGroupId,
          regionData.bbox
        )
      );
    }

    // Road-Stretches Restore
    // only Triggered when "saved-map-state" has any "Road-Stretches"
    // We will check whether CITY has any Road-Stretches or not
    const { roadStretchIds = [] } = savedState;
    if (roadStretchIds.length > 0) {
      let orgRoadStretches = yield select(
        (state) => state.orgRoadStretch.orgRoadStretches
      );
      while (orgRoadStretches.length === 0) {
        yield delay(1000);
        orgRoadStretches = yield select(
          (state) => state.orgRoadStretch.orgRoadStretches
        );
      }
      // restoring the selection of stretches
      selectActions = selectActions.concat(
        yield restoreRoadStretchSelectionAction(roadStretchIds)
      );
    }

    // to get Data and Restore Selected SEC
    selectActions = selectActions.concat(
      yield restoreSelectedSecAction(savedState, regionData)
    );

    // Final Yield for All-Select-Actions ----------------------------------
    yield all(selectActions);

    // Restore Map State Success..
    yield put({
      type: SaveMapState.Restore.RESTORE_SAVE_MAP_STATE_SUCCESS,
      payload: {
        zoomLevel: savedState.zoomLevel,
        centerLatLng: `${regionData.center.latitude},${regionData.center.longitude}`,
        cityBBox: regionData.bbox,
        cityName: regionData.name,
      },
    });
  } catch (err) {
    const errorMessage = getErrorMessage(err);

    // stores the error and render the error image when the api fails
    setErrorStatusCode(err);

    yield put({
      type: SaveMapState.Restore.RESTORE_SAVE_MAP_STATE_FAILURE,
      payload: err,
    });
    toast.error(errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(SaveMapState.Save.SAVE_MAP_STATE, saveMapState),
    takeLatest(SaveMapState.Restore.RESTORE_SAVE_MAP_STATE, restoreMapState),
  ]);
}
