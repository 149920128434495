import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

// Action
import { createOrgUser } from "../../../actions/org/OrgUserManageActions";

// Urls
import { RedirectTo } from "../../../urls/PageLinksURL";

// Utils
import { ErrorMessage, PermissionSection } from "./OrgFormUtils";
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";

// Components
import OrgSettingsSidebar from "../OrgSettingsSidebar";
import Spinner from "../../../components/spinner/Spinner";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// validation schema
const formValidationSchema = yup.object({
  firstName: yup.string().required("please fill the First Name"),
  lastName: yup.string().required("please fill the Last Name"),
  emailId: yup
    .string()
    .email("please provide valid email")
    .required("please fill the Email"),
  phoneNo: yup
    .number()
    .typeError("Phone Number is not valid")
    .required("please fill the Phone Number"),
  roles: yup.array().min(1, "please select permissions"),
});

// Page Components
function FooterSection() {
  const isLoading = useSelector((state) => state.orgUserManage.loading);

  return (
    <div className="mt-4 float-right">
      <Link to={constructRedirectPath(RedirectTo.orgUserListUrl)}>
        <button className="btn btn-outline-primary mr-2 px-4">Cancel</button>
      </Link>
      <button
        disabled={isLoading}
        type="submit"
        className="btn btn-primary px-4 shadow-none"
      >
        Create{" "}
        {isLoading && <Spinner className="spinner-border-sm text-light" />}
      </button>
    </div>
  );
}

function FirstNameSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">First Name</label>
      <div className="col-sm-10">
        <input
          className={`${inputClassName} ${
            errors.firstName && touched.firstName && "is-invalid"
          }`}
          placeholder="Enter First Name"
          id="firstName"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorMessage error={errors.firstName} />
      </div>
    </div>
  );
}

function LastNameSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Last Name</label>
      <div className="col-sm-10">
        <input
          className={`${inputClassName} ${
            errors.lastName && touched.lastName && "is-invalid"
          }`}
          placeholder="Enter Last Name"
          id="lastName"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorMessage error={errors.lastName} />
      </div>
    </div>
  );
}

function EmailSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Email id</label>
      <div className="col-sm-10">
        <input
          className={`${inputClassName} ${
            errors.emailId && touched.emailId && "is-invalid"
          }`}
          placeholder="E.g joe@gmail.com"
          id="emailId"
          value={values.emailId}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
        />
        <ErrorMessage error={errors.emailId} />
      </div>
    </div>
  );
}

function PhoneNoSection({ formikValuesAndFn, inputClassName }) {
  const { values, handleChange, handleBlur, errors, touched } =
    formikValuesAndFn;

  return (
    <div className="form-group row">
      <label className="col-sm-2 col-form-label">Phone no.</label>
      <div className="col-sm-10">
        <input
          className={`${inputClassName} ${
            errors.phoneNo && touched.phoneNo && "is-invalid"
          }`}
          placeholder="(+91) (xxxxxxxxxx)"
          id="phoneNo"
          value={values.phoneNo}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ErrorMessage error={errors.phoneNo} />
      </div>
    </div>
  );
}

function CreateUserForm() {
  //History
  const history = useHistory();

  // Dispatch
  const dispatch = useDispatch();

  //Form validation
  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        emailId: "",
        phoneNo: "",
        roles: [],
      },
      validationSchema: formValidationSchema,
      onSubmit: (newUser) => {
        dispatch(createOrgUser(newUser, history));
      },
    });

  const formikValuesAndFn = {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
  };

  //Input class name
  const inputClassName = "form-control shadow-none";
  return (
    <form className="col-10" onSubmit={handleSubmit}>
      {/* First Name */}
      <FirstNameSection
        formikValuesAndFn={formikValuesAndFn}
        inputClassName={inputClassName}
      />

      {/* Last Name */}
      <LastNameSection
        formikValuesAndFn={formikValuesAndFn}
        inputClassName={inputClassName}
      />

      {/* Email */}
      <EmailSection
        formikValuesAndFn={formikValuesAndFn}
        inputClassName={inputClassName}
      />

      {/* Phone No */}

      <PhoneNoSection
        formikValuesAndFn={formikValuesAndFn}
        inputClassName={inputClassName}
      />

      {/* Permission */}
      <PermissionSection formikValuesAndFn={formikValuesAndFn} />
      <hr className="mt-3 col-12" />

      {/* Create and cancel button */}
      <FooterSection />
    </form>
  );
}

function OrgCreateUserPage() {
  const pageTitle = "Create New User";
  // Render Page
  return (
    <>
      <OrgSettingsSidebar activeIdx={2} />
      <div className="content-wrapper">
        {/** Page Header */}
        <PageHeader title={pageTitle} shadow={true} />

        {/** Page Content */}
        <div className="page-content">
          {/* Form */}
          <CreateUserForm />
        </div>
      </div>
    </>
  );
}

export default OrgCreateUserPage;
